var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showErrorMsg)?_c('div',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.$t('template.audits.final_score.save_error_msg')))]):_vm._e(),_vm._l((_vm.finalScores),function(finalScore){return _c('div',{key:finalScore.id,staticClass:"scores"},[_c('div',{staticClass:"color-name-block"},[_c('ColorPicker',{key:finalScore.uuid,attrs:{"value":finalScore.color},on:{"actualColor":function setColor(color) {
                        finalScore.color = color
                    },"change":_vm.sendFinalScores}}),_c('Input',{attrs:{"gType":"text","inputIcon":true,"value":finalScore.name,"customClass":{ 'error-field': _vm.showInputError },"gPlaceholder":_vm.$t('template.audits.final_score.name_placeholder'),"gOptions":{ maxLength: '18' }},on:{"input":_vm.sendFinalScores},model:{value:(finalScore.name),callback:function ($$v) {_vm.$set(finalScore, "name", $$v)},expression:"finalScore.name"}}),(finalScore.delete)?_c('div',{staticClass:"delete-score",on:{"click":function($event){return _vm.deleteScore(finalScore.id)}}}):_vm._e()],1),_c('div',{staticClass:"score-block"},[_c('div',{staticClass:"condition"},[_c('div',{staticClass:"sentence",class:{ 'in-between-score-type': finalScore.score.operator === '<>' }},[_c('span',[_vm._v(_vm._s(_vm.$t('template.general.condition_text_start')))]),_c('select',{staticClass:"integrated-input",domProps:{"value":finalScore.score.operator},on:{"change":function (e) {
                                finalScore.score.operator = e.target.value
                                finalScore.score.value = ['', '']
                                _vm.sendFinalScores()
                            }}},_vm._l((_vm.scoreConditionOptions),function(condition){return _c('option',{domProps:{"value":condition.value}},[_vm._v("\n                            "+_vm._s(condition.text)+"\n                        ")])}),0),(finalScore.score.operator === '<>')?[_c('input',{staticClass:"integrated-input number",class:{ 'error-field': _vm.showInputError },attrs:{"type":"number","min":"0","max":"100"},domProps:{"value":finalScore.score.value && finalScore.score.value[0] ? finalScore.score.value[0] : ''},on:{"input":function (e) {
                                    finalScore.score.value[0] = e.target.value
                                    _vm.sendFinalScores()
                                }}}),_c('span',[_vm._v(_vm._s(_vm.$t('template.general.condition_text_connector')))]),_c('input',{staticClass:"integrated-input number",class:{ 'error-field': _vm.showInputError },attrs:{"type":"number","min":"0","max":"100"},domProps:{"value":finalScore.score.value && finalScore.score.value[1] ? finalScore.score.value[1] : ''},on:{"input":function (e) {
                                    finalScore.score.value[1] = e.target.value
                                    _vm.sendFinalScores()
                                }}})]:[_c('input',{staticClass:"integrated-input number",class:{ 'error-field': _vm.showInputError },attrs:{"type":"number","min":"0","max":"100"},domProps:{"value":finalScore.score.value ? finalScore.score.value : null},on:{"input":function (e) {
                                    finalScore.score.value = e.target.value
                                    _vm.sendFinalScores()
                                }}})],_c('button',{staticClass:"delete-condition",on:{"click":function () {
                                finalScore.score.operator = '<>'
                                finalScore.score.value = ['', '']
                                _vm.sendFinalScores()
                            }}})],2)])])])}),_c('Button',{attrs:{"bType":"create","bLabel":_vm.$t('template.audits.final_score.new_label'),"customClass":"white","bCallback":_vm.addScore}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }