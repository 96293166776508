<template>
    <div class="config translate-disable">
        <div class="name">{{ $t('template.general.types.title') }}</div>
        <div class="checkbox">
            <div class="checkbox-label">{{ $t('template.general.types.number.label') }}</div>
            <input class="switch-input" type="checkbox" v-model="changedConfig.decimal" />
        </div>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'

export default {
    components: {},
    props: {
        item: { type: Object },
        config: { default: { decimal: false } }
    },
    data() {
        return {
            default: { decimal: false },
            FORMAT: FORMAT,
            changedType: false,
            changedConfig: false
        }
    },
    computed: {
        configRender() {
            if (this.changedConfig) {
                return this.changedConfig.limit
            } else return true
        }
    },
    methods: {
        load() {
            this.changedType = parseInt(this.item.type)

            if (this.config && this.config.decimal) {
                this.changedConfig = this.config
            } else {
                this.changedConfig = this.default
                this.sendValue()
            }
        },

        sendValue() {
            this.limit = this.changedConfig.limit
            this.$emit('sendItemType', { type: this.changedType, config: this.changedConfig })
        }
    },
    created() {
        this.load()
    },
    watch: {
        item: {
            handler: function (val, oldVal) {
                if (oldVal.id != val.id) {
                    this.load()
                }
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.checkbox {
    @include display-flex();
    @include justify-content(flex-start);
    @include align-items();
    width: 100%;
    background-color: $color-neutral-200;
    padding: 6px;
    border-radius: 3px;

    .checkbox-label {
        @include font-size(sm);
        display: inline-block;
        width: calc(100% - 35px);
        font-family: $text-medium;
        color: $color-black;
    }

    .switch-input {
        display: inline-block;
    }
}
</style>
