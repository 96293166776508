<template>
    <div v-if="item" class="config main translate-hide">
        <div class="name main">
            <div class="collapse" :class="{ collapsed: collapse.indexOf('score') == -1 }"></div>
            {{ $t('template.general.score') }}
        </div>
        <div class="config-content" :class="{ collapsed: collapse.indexOf('score') > -1 }">
            <div class="description" v-html="$t('template.general.score_description')"></div>
            <div class="total">
                <span>{{ $t('template.general.max_points') }}:</span>
                <input class="integrated-input" @input="update()" v-model.number="item.points.total" type="number" />
            </div>
            <div class="score" v-for="(condition, condition_index) in item.points.conditions" :key="condition_index">
                <div class="condition">
                    <div class="delete" @click="deleteCondition(condition_index)"></div>
                    <div class="sentence">
                        <span>{{ $t('template.general.condition_text_start') }}</span>
                        <select :key="item.type" :value="condition.operator" class="integrated-input" :class="{ error: classError || (item.errors && item.errors.codes && item.errors.codes.includes('empty_operator')) }" v-model="condition.operator" @change="update()">
                            <option v-for="condition in options(item.type)" :value="condition.value" :key="condition.value">{{ condition.text }}</option>
                        </select>

                        <template v-if="condition.operator == '<>' || condition.operator == '!<>'">
                            <input class="integrated-input text" @input="formatValue($event.target.value, condition_index, 0)" :value="condition.value.split(',')[0]" type="number" />

                            <span>{{ $t('template.general.condition_text_connector') }}</span>

                            <input class="integrated-input text" @input="formatValue($event.target.value, condition_index, 1)" :value="condition.value.split(',')[1]" type="number" />
                        </template>

                        <template v-else-if="condition.operator != '!empty'">
                            <select v-if="item.type == FORMAT.MULTIPLE" :value="condition.value" class="integrated-input" v-model.lazy="condition.value">
                                <option v-for="option in item.config.values" :value="option.v" :key="option.v">
                                    <span>{{ option.v }}</span>
                                </option>
                            </select>

                            <select v-else-if="item.type == FORMAT.YESNO || item.type == FORMAT.YESNONC" :value="condition.value" class="integrated-input" v-model.lazy="condition.value">
                                <option
                                    v-for="option in [
                                        { text: $t('nvgt.report.answer_yes'), value: 1 },
                                        { text: $t('nvgt.report.answer_no'), value: 0 }
                                    ]"
                                    :value="option.value"
                                    :key="option.value"
                                >
                                    {{ option.text }}
                                </option>
                                <option v-if="item.type == FORMAT.YESNONC" :value="2">{{ $t('nvgt.report.answer_nsnc') }}</option>
                            </select>
                            <input v-else-if="condition.operator" class="integrated-input text" v-model="condition.value" :type="[FORMAT.NUMBER, FORMAT.TEMPERATURE].includes(parseInt(item.type)) ? 'number' : 'string'" />
                        </template>
                    </div>

                    <div class="sentence big">
                        <span>{{ $t('template.general.assign') }}</span>

                        <input class="integrated-input" v-model.number="condition.p" type="number" :class="{ error: classError || (item.errors && item.errors.codes && item.errors.codes.includes('empty_points')) }" />

                        <span>{{ $tc('template.general.point', condition.p) }}</span>
                    </div>
                </div>
            </div>
            <div class="add" @click="addCondition()">
                <div class="icon"></div>
                <span>{{ $t('template.general.add_condition') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'
import i18n from '@/i18n'

export default {
    components: {},
    props: {
        // context: { type: Object, default: {} },
        score: {}
    },
    data() {
        return {
            FORMAT: FORMAT,
            dataLoaded: false,
            collapse: [],
            classError: false
        }
    },
    computed: {
        context() {
            return { ...this.$store.getters['template/getSelectedContext'] }
        },

        item: {
            get() {
                var item = { ...this.$store.getters['template/getItem'](this.context) }
                if (item) {
                    item.points = item.points ? { ...item.points } : { total: 0, conditions: [] }
                }
                return item
            },
            set(value) {
                this.update()
                return value
            }
        }
    },
    methods: {
        load() {
            // OBJECT SAMPLE
            // this.item.score = {"total":5,"conditions":[{"value":75,"operator":">","p":5},{"value":"70,75","operator":"<>","p":4},{"value":"50,69","operator":"<>","p":3}]}
            // this.score = typeof this.item.score != 'undefined' ? { ...this.item.score } : { total: 0, conditions: [] }
        },

        options(type) {
            var options = []

            options.push({ text: i18n.t('template.general.condition_options.has_value'), value: '!empty' })

            if ([FORMAT.NUMBER, FORMAT.TEMPERATURE, FORMAT.YESNO, FORMAT.YESNONC, FORMAT.MULTIPLE].includes(parseInt(type))) {
                options.push({ text: i18n.t('template.general.condition_options.same'), value: '=' }, { text: i18n.t('template.general.condition_options.different'), value: '!=' })
            }

            if ([FORMAT.NUMBER, FORMAT.TEMPERATURE].includes(parseInt(type))) {
                options.push({ text: i18n.t('template.general.condition_options.more_than'), value: '>' }, { text: i18n.t('template.general.condition_options.more_equal_than'), value: '>=' }, { text: i18n.t('template.general.condition_options.less_than'), value: '<' }, { text: i18n.t('template.general.condition_options.less_equal_than'), value: '<=' }, { text: i18n.t('template.general.condition_options.between'), value: '<>' }, { text: i18n.t('template.general.condition_options.not_between'), value: '!<>' })
            }

            return options
        },

        formatValue(value, condition, position) {
            var item = { ...this.item }
            var currentValue = item.points.conditions[condition].valueº

            value = value.replace(/,/g, '.')
            if (position == 0) {
                currentValue = value + ',' + (currentValue !== '' ? currentValue.split(',')[1] : '')
            } else {
                currentValue = currentValue.split(',')[0] + ',' + value
            }

            item.points.conditions[condition].value = currentValue
            this.item = item
        },

        update() {
            this.$store.commit('template/setScore', { context: this.context, score: { ...this.item.points } })
        },

        deleteCondition(index) {
            this.item.points.conditions.splice(index, 1)
            this.update()
        },

        addCondition() {
            if (!this.isEmptyPoints() || !this.isEmptyOperator()) {
                this.item.points.conditions.push({ value: '', operator: '', p: '' })
            }

            this.update()
        },

        isEmptyPoints() {
            if (this.item.points.conditions.find((condition) => condition.p === '')) {
                this.classError = true
                return true
            }

            return false
        },

        isEmptyOperator() {
            if (this.item.points.conditions.find((condition) => condition.operator === '')) {
                this.classError = true
                return true
            }

            return false
        },

        collapseSection(section) {
            var aCollapse = this.collapse
            if (aCollapse.indexOf(section) > -1) {
                aCollapse.splice(aCollapse.indexOf(section), 1)
            } else {
                aCollapse.push(section)
            }
        }
    },
    created() {
        this.load()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.config {
    .integrated-input {
        @include font-size(xs);
        display: inline-block;
        width: auto !important;
        background-color: #fff !important;
        min-width: unset !important;
        height: 20px;
        line-height: 18px;
        font-family: $text-medium !important;
        margin: 0px 4px;
        padding: 0 5px !important;
        border-radius: 3px;
        color: $color-neutral-600;
        max-width: 100px;

        &[type='number'] {
            width: 60px !important;
            padding: 0 !important;
            padding-left: 5px !important;
        }
    }

    span {
        @include font-size(xs);
        font-family: $text-medium;
        color: $color-neutral-600;
        margin: 0 4px;
    }

    select {
        min-width: unset;
        cursor: pointer;
    }

    .score,
    .total {
        background-color: $color-neutral-200;
        position: relative;
        border-radius: 3px;
        padding: 9px;
        padding-right: 30px;
        margin: 5px 0;

        &.total {
            border-bottom: 2px solid $color-neutral-200;

            span,
            .integrated-input {
                @include font-size(sm);
                &.error {
                    border: 1px solid $color-error-500;
                }
            }
        }

        .delete {
            position: absolute;
            @include background($size: 10px, $image: img('close_main_s10.svg'), $position: left 10px center);
            height: 20px;
            width: 20px;
            top: 3px;
            right: 10px;
        }

        .sentence {
            display: block;

            &.big {
                margin-top: 9px;

                span,
                .integrated-input {
                    @include font-size(md);
                }
            }
        }
    }

    .add {
        width: fit-content;
        cursor: pointer;
        display: inline-flex;
        margin-top: 9px;

        .icon {
            @include background($size: 20px, $image: img('condition_inactive_s70.svg'), $position: center center);
            width: 30px;
            height: 15px;
            display: inline-block;
        }
        span {
            @include font-size(sm);
            display: inline-block;
            font-family: $text-bold;
            color: $color-neutral-600;
        }
    }
}
</style>
