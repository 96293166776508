<template>
    <div class="container-picker" @mouseleave="closeColorSelector">
        <div class="color-picker" @click="toggleColorSelector" :class="{ open: isColorSelectorOpen }">
            <div class="color" :style="{ background: color ? color : '#fff' }" :class="[!color ? 'delete-color' : '']"></div>
        </div>
        <div class="color-selector" :class="{ open: isColorSelectorOpen }">
            <div
                class="color"
                v-for="colorHex in extraPColors"
                :style="{ 'background-color': colorHex }"
                :class="[!colorHex ? 'delete-color' : '']"
                @click="changeColor"
                :data-color="colorHex"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ColorPicker',

    props: {
        value: {
            type: String
        }
    },

    data() {
        return {
            extraPColors: [
                null,
                '#E7515A',
                '#E751B4',
                '#F4A261',
                '#E9C46A',
                '#A2E96A',
                '#6AE1E9',
                '#0A89FF',
                '#925FFF',
                '#C1A4FF',
                '#FF48ED',
                '#E500A5',
                '#F4B274',
                '#BE7E43',
                '#E76F51',
                '#8CA107',
                '#2ACFA8',
                '#939393',
                '#E698E8',
                '#F1ADAD',
                '#000000'
            ],
            isColorSelectorOpen: false,
            color: null
        }
    },

    methods: {
        toggleColorSelector() {
            this.isColorSelectorOpen = !this.isColorSelectorOpen
        },
        changeColor(event) {
            this.color = event.target.dataset.color ? event.target.dataset.color : null
            this.$emit('actualColor', this.color)
            this.$emit('change', event)
            this.toggleColorSelector()
        },
        closeColorSelector() {
            setTimeout(() => {
                this.isColorSelectorOpen = false
            }, 400)
        }
    },

    mounted() {
        if (!this.value) {
            this.color = null
        } else {
            this.color = this.value
        }

        this.$emit('actualColor', this.color)
    },
    watch: {
        value() {
            this.color = this.value
        }
    }
}
</script>

<style lang="scss" scoped>
.container-picker {
    position: relative;
    display: flex;
    .color-picker {
        padding: 6px;
        border: 1px solid $color-neutral-300;
        @include border-radius(4px);
        display: inline-block;
        cursor: pointer;

        &.open {
            border-color: $color-primary-500;
        }
        .color {
            width: 18px;
            height: 18px;
            @include border-radius(50%);
            background-color: #000;
            border: 1px solid $color-neutral-300;

            &.delete-color {
                border: 1px solid $color-neutral-600;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 12px;
                    height: 1px;
                    background: $color-neutral-600;
                }
            }
        }
    }

    .color-selector {
        background: $color-white;
        position: absolute;
        display: none;
        padding: 0.3rem;
        top: 100%;
        flex-wrap: wrap;
        gap: 5px;
        z-index: 100;
        border: 1px solid $color-neutral-300;
        @include border-radius(4px);
        width: 168px;
        @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-200, 1));

        &.open {
            display: flex;
        }

        .color {
            width: 18px;
            height: 18px;
            @include border-radius(50%);
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &.delete-color {
                border: 1px solid $color-neutral-600;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 12px;
                    height: 1px;
                    background: $color-neutral-600;
                }
            }

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}
</style>
