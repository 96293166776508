<template>
    <div class="config main active translate-disable">
        <div class="name main">
            <div class="collapse" :class="{ collapsed: collapse.indexOf('periodicity') == -1 }"></div>
            {{ $t('template.general.periodicity') }}
        </div>
        <div class="config-content" :class="{ collapsed: collapse.indexOf('periodicity') > -1 }">
            <div class="description" v-html="$t('template.general.periodicity_description')"></div>
            <div class="periodicity">
                <div class="sub-name" v-html="$t('template.general.periodicity_interval_question')"></div>
                <v-select
                    hide-details
                    class="select"
                    v-model="type"
                    :items="[
                        { text: 'daily', value: 1 },
                        { text: 'weekly', value: 2 },
                        { text: 'biweekly', value: 3 },
                        { text: 'monthly', value: 4 },
                        { text: 'quarterly', value: 5 },
                        { text: 'biannually', value: 6 },
                        { text: 'annually', value: 7 },
                        { text: 'custom', value: 9 }
                    ]"
                    :item-text="(item) => $t('template.general.periodicity_times.' + item.text)"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    @change="changeType($event)"
                ></v-select>

                <template v-if="type == 2 || type == 3 || type == 4">
                    <div class="daytime" :class="{ error: item.errors && item.errors.codes && item.errors.codes.includes('empty_config_periodicity_days') }">
                        <div class="item" v-for="(day, index) in weekDays" :class="{ selected: daytimes.includes(parseInt(index)), error: item.errors && item.errors.codes && item.errors.codes.includes('empty_config_periodicity_days') }" @click="weekdaySelect(index, daytimes.includes(parseInt(index)))">{{ day.substring(0, 3) }}.</div>
                    </div>
                </template>
                <template v-else-if="type == 5 || type == 6 || type == 7 || type == 9">
                    <div class="daytime">
                        <div class="item month" v-for="(month, index) in monthDays" v-if="index > 0" :class="{ selected: daytimes.includes(parseInt(index)), error: item.errors && item.errors.codes && item.errors.codes.includes('empty_config_periodicity_days') }" @click="monthSelect(index - 1, type)">{{ month }}</div>
                    </div>
                </template>

                <template v-if="type == 3">
                    <div class="sub-name" v-html="$t('template.general.periodicity_literals.which_weeks')"></div>
                    <v-radio-group class="opt-group" v-model="position" @change="sendValue()">
                        <div class="option">
                            <v-radio class="radio" color="#4D206B" :value="1"></v-radio>
                            <div
                                @click="
                                    position = 1
                                    sendValue()
                                "
                                class="label"
                                :class="{ selected: position == 1 }"
                            >
                                {{ $t('template.general.periodicity_literals.first_third_week') }}
                            </div>
                        </div>
                        <div class="option">
                            <v-radio class="radio" color="#4D206B" :value="2"></v-radio>
                            <div
                                @click="
                                    position = 2
                                    sendValue()
                                "
                                class="label"
                                :class="{ selected: position == 2 }"
                            >
                                {{ $t('template.general.periodicity_literals.second_fourth_week') }}
                            </div>
                        </div>
                    </v-radio-group>
                </template>

                <template v-else-if="type == 4">
                    <div class="sub-name" v-html="$t('template.general.periodicity_literals.which_week')"></div>
                    <v-radio-group class="opt-group" v-model="position" @change="sendValue()">
                        <div class="option">
                            <v-radio class="radio" color="#4D206B" :value="1"></v-radio>
                            <div
                                @click="
                                    position = 1
                                    sendValue()
                                "
                                class="label"
                                :class="{ selected: position == 1 }"
                            >
                                {{ $t('template.general.periodicity_literals.first') }}
                            </div>
                        </div>
                        <div class="option">
                            <v-radio class="radio" color="#4D206B" :value="2"></v-radio>
                            <div
                                @click="
                                    position = 2
                                    sendValue()
                                "
                                class="label"
                                :class="{ selected: position == 2 }"
                            >
                                {{ $t('template.general.periodicity_literals.second') }}
                            </div>
                        </div>
                        <div class="option">
                            <v-radio class="radio" color="#4D206B" :value="3"></v-radio>
                            <div
                                @click="
                                    position = 3
                                    sendValue()
                                "
                                class="label"
                                :class="{ selected: position == 3 }"
                            >
                                {{ $t('template.general.periodicity_literals.third') }}
                            </div>
                        </div>
                        <div class="option">
                            <v-radio class="radio" color="#4D206B" :value="4"></v-radio>
                            <div
                                @click="
                                    position = 4
                                    sendValue()
                                "
                                class="label"
                                :class="{ selected: position == 4 }"
                            >
                                {{ $t('template.general.periodicity_literals.fourth') }}
                            </div>
                        </div>
                    </v-radio-group>
                </template>

                <!-- MARGIN -->
                <template v-if="type != 1">
                    <div class="sub-name check inline" v-html="$t('template.general.postpone_label')"></div>
                    <input class="switch-input inline" type="checkbox" v-model="switchMargin" @change="marginInput()" />
                    <div class="sub-description" v-html="$t('template.general.postpone_description')"></div>
                    <template v-if="(item.margin == '' && switchMargin) || (item.margin !== null && item.margin !== false && item.margin != 0)">
                        <div class="sub-name" v-html="$t('template.general.margin_days_label')"></div>
                        <div class="sub-description" v-html="$t('template.general.margin_max_days', { s: maxMarginDays() })"></div>
                        <input class="days" min="1" :max="maxMarginDays()" type="number" v-model="item.margin" @input="validate('input')" :class="{ error: error.margin }" />
                        {{ $tc('assets.timing.day', item.margin) }}
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'
import i18n from '@/i18n'

export default {
    components: {},
    props: { item: undefined },
    data() {
        return {
            FORMAT: FORMAT,
            weekDays: i18n.t('assets.calendar.day_fullname'),
            monthDays: ['ignore postion 0'].concat(i18n.t('assets.calendar.months')),
            type: '',
            switchMargin: false,
            daytimes: [],
            position: false,
            error: {
                margin: false
            },
            collapse: []
        }
    },
    computed: {
        configRender() {
            return this.changedConfig.limit
        }

        // switchMargin: {
        //   get(){
        //     return !!parseInt(this.item.margin) !== false || parseInt(this.item.margin) > 0;
        //   },
        //   set(newName){

        //   }
        // }
    },
    methods: {
        load() {
            this.switchMargin = !!parseInt(this.item.margin) !== false || parseInt(this.item.margin) > 0

            if (this.item.periodicity != null && typeof this.item.periodicity != 'undefined') {
                this.type = JSON.parse(JSON.stringify(parseInt(this.item.periodicity)))
                if (typeof this.item.planification != 'undefined' && (typeof this.item.planification.days != 'undefined' || typeof this.item.planification.months != 'undefined')) {
                    this.daytimes = typeof this.item.planification.days != 'undefined' ? JSON.parse(JSON.stringify(this.item.planification.days)) : JSON.parse(JSON.stringify(this.item.planification.months))
                    if (typeof this.item.planification.week != 'undefined') {
                        this.position = JSON.parse(JSON.stringify(this.item.planification.week))
                    }
                }
            } else {
                this.changeType('daily')
            }
        },

        collapseSection(section) {
            var aCollapse = this.collapse
            if (aCollapse.indexOf(section) > -1) {
                aCollapse.splice(aCollapse.indexOf(section), 1)
            } else {
                aCollapse.push(section)
            }
        },

        sendValue() {
            if (this.type == 1) {
                this.$emit('sendItemPeriodicity', { periodicity: this.type })
            } else if (this.type >= 2 && this.type <= 4) {
                this.$emit('sendItemPeriodicity', { periodicity: this.type, planification: { days: this.daytimes, week: this.position } })
            } else {
                this.$emit('sendItemPeriodicity', { periodicity: this.type, planification: { months: this.daytimes } })
            }
        },

        maxMarginDays(type = this.type) {
            // INFO TO SEE THE TYPES:
            // daily => 1
            // weekly => 2
            // biweekly => 3
            // monthly => 4
            // quarterly => 5
            // biannually => 6
            // annually => 7
            // custom => 9

            switch (type) {
                case 2:
                    return 7
                case 3:
                    return 15
                case 4:
                    return 30
                case 5:
                    return 90
                case 6:
                case 7:
                case 9:
                    return 120
            }
        },

        changeType(type) {
            this.daytimes = []
            if (type == 1) {
                this.daytimes = []
            } else if (type == 2 || type == 3 || type == 4) {
                this.daytimes = [0]
            } else this.monthSelect(0, type)

            if (type == 3 || type == 4) {
                this.position = 1
            } else this.position = false
            this.item.margin = false
            this.sendValue()
        },

        weekdaySelect(daytime, isSelected) {
            if (isSelected) {
                this.daytimes = this.daytimes.filter((c) => {
                    return c !== daytime
                })
            } else {
                this.daytimes.push(daytime)
            }
            this.sendValue()
        },

        monthSelect(month, type) {
            // Se le suma "1" para que los meses a diferencia de las semanas empiezan en "1"
            if (type == 5) {
                var firstMonth = month % 3
                this.daytimes = [firstMonth + 1, firstMonth + 4, firstMonth + 7, firstMonth + 10]
            } else if (type == 6) {
                var firstMonth = month % 6
                this.daytimes = [firstMonth + 1, firstMonth + 7]
            } else if (type == 7) {
                this.daytimes = [month + 1]
            } else if (type == 9) {
                this.daytimes.indexOf(month + 1) === -1 ? this.daytimes.push(month + 1) : (this.daytimes = this.daytimes.filter((item) => item !== month + 1))
            }

            this.sendValue()
        },

        validate() {
            this.error.margin = false

            if (this.item.margin != '') {
                if (this.item.margin < 1) {
                    this.item.margin = 1
                } else if (this.item.margin > this.maxMarginDays()) {
                    this.item.margin = this.maxMarginDays()
                } else this.item.margin = parseInt(this.item.margin)
            } else this.error.margin = true

            if (this.item.margin == '') {
                this.error.margin = true
            }

            this.sendValue()
        },

        marginInput() {
            !this.switchMargin
            this.item.margin = this.switchMargin == true ? 1 : false
            this.error.margin = false
            this.sendValue()
        }
    },
    created() {
        this.load()
    },
    watch: {
        item: {
            handler: function (val, oldVal) {
                if (oldVal.id != val.id) {
                    this.load()
                }
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.daytime {
    width: 100%;
    // margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    padding-top: 8px;
    padding-bottom: 12px;
    gap: 8px;
    flex-wrap: wrap;
    .item {
        @extend .interaction;
        // @include font-size(ml);
        @include font-size(sm);
        background-color: $color-neutral-200;
        padding: 1px 9px;
        width: fit-content;
        display: inline-block;
        // margin-top: 9px;
        // margin-right: 6px;
        border-radius: 3px;
        color: $color-black;
        font-family: $text;
        // height: 30px;

        &.month {
            @include text-ellipsis();
            @include font-size(md);
            line-height: 30px;
            text-align: center;
            width: calc(25% - 9px);
            overflow: hidden;
            margin-right: 9px;
        }

        &.selected {
            color: #fff;
            background-color: $color-primary-500;
        }
    }
    &.error {
        @include border-radius(6px);
        @include background($image: img('warning_error.svg'), $size: 15px, $position: right 12px center);
        padding: 0 30px 9px 9px;
        border: 1px solid $color-error-500;
    }
}

.sub-name {
    &.check {
        width: calc(100% - 35px);
    }
}

.inline {
    display: inline-block;
    &.switch-input {
        top: 5px;
        left: 10px;
    }
}

.select {
    margin-bottom: 5px;
}

.days {
    width: 80px;
    margin-bottom: 10px;
    text-align: center;

    &.error {
        border: 1px solid red;
    }
}
</style>
