<template>
    <div class="format">
        <div class="name main">{{ $t('template.general.answer_format') }}</div>
        <div class="types">
            <!-- {{this.context}} -->
            <div class="type" :data-type="'more'" v-on="{ click: () => (viewMoreTypes = !viewMoreTypes) }">
                <div class="label">{{ $t('template.general.view_' + (viewMoreTypes ? 'less' : 'more')) }}</div>
            </div>
            <div class="type" v-for="type in FORMAT_CAT" :class="{ selected: type.children.includes(parseInt(item.type)) }" :data-type="type.id" v-on="{ click: () => clearConditions(type.children[0]) }" @click="viewMoreTypes = false" v-show="viewMoreTypes || type.children.includes(parseInt(item.type))">
                <div class="label">{{ $t('template.general.item_category.' + getToolTypeName(type.id, 'FORMAT_CAT')) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'
import { FORMAT_CAT } from '@/constants'

export default {
    components: {},
    name: 'Format',
    props: {
        context: { type: Object, default: {} },
        templateStatus: {}
    },
    data() {
        return {
            // VIEW VARIABLES OF CONTROL
            viewMoreTypes: false,

            // GENERAL VARIABLES
            FORMAT: FORMAT,
            FORMAT_CAT: FORMAT_CAT
        }
    },
    computed: {
        item: {
            get() {
                return this.$store.getters['template/getItem'](this.context)
            },
            set(value) {
                return value
            }
        }
    },
    methods: {
        getToolTypeName(type, constant) {
            if (constant == 'FORMAT') {
                return Object.keys(FORMAT).find((key) => FORMAT[key] === parseInt(type))
            } else if (constant == 'FORMAT_CAT') {
                return Object.keys(FORMAT_CAT).find((key) => FORMAT_CAT[key].id === parseInt(type))
            }
        },

        clearConditions(type) {
            // CLEAR FUTURE CONDITIONS
            var item = { ...this.item }

            var review_roles = item.config && item.config.review_roles ? item.config.review_roles : false

            if (item.type != type) {
                item.type = type
                item.resource_id = false

                switch (type) {
                    case FORMAT_CAT.CHECK.id:
                    case FORMAT_CAT.OPTIONS.id:
                    case FORMAT_CAT.TEXT.id:
                    case FORMAT_CAT.DATE.id:
                        item.config = false
                        break
                    case FORMAT_CAT.SELECTOR.id:
                        item.config = { values: [] }
                        break
                    case FORMAT_CAT.PHOTO.id:
                        item.config = { limit: 1 }
                        break
                    case FORMAT_CAT.NUMBER.id:
                        item.config = { decimal: false }
                        break
                    case FORMAT_CAT.TEMPERATURE.id:
                        item.config = { units: 'ºC' }
                        break
                    case FORMAT_CAT.SIGNATURE.id:
                        item.config = { require_emp: false }
                        break
                    case FORMAT_CAT.EMAIL.id:
                        item.config = { to: [], subject: '', message: '' }
                        break
                    case FORMAT_CAT.LINK.id:
                        item.config = { url: '' }
                        break

                    default:
                        item.config = false
                        break
                }

                if (review_roles) {
                    if (!item.config) item.config = {}
                    item.config.review_roles = review_roles
                }

                if (item.points && item.points.conditions) {
                    item.points.conditions = []
                }
            }

            this.$store.commit('template/updateItem', { context: this.context, item: { ...item }, changeType: true })

            // if (this.item.type != type) {
            //   for (var index in this.item.renderconfig) {
            //     var renderconfig = this.item.renderconfig[index];
            //     renderconfig.operator = this.conditionsOptions(type)[0].value;
            //     renderconfig.value = '';
            //   }
            // }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
