<template>
    <div class="config translate-exception">
        <div class="name">{{ $t('template.general.types.title') }}</div>
        <div class="description" v-html="$t('template.general.types.selector.description')"></div>
        <template v-if="!isTranslating">
            <div class="select-color-text" v-for="data in selectorData">
                <ColorPicker
                    @actualColor="
                        function setColor(color) {
                            data.color = color
                        }
                    "
                    @change="sendValue()"
                    :value="data.color"
                    :key="data.uuid"
                >
                </ColorPicker>
                <Input
                    gType="text"
                    :customClass="['selector', { 'error-field': isErrorInput }]"
                    :inputIcon="false"
                    :gPlaceholder="$t('template.general.types.selector.placeholder')"
                    :value="data.input"
                    v-model="data.input"
                    @change="sendValue()"
                ></Input>
                <div @click="deleteSelector(data.uuid)" class="delete-selector" v-if="data.delete"></div>
            </div>
            <div class="selector-actions">
                <Button bType="create" bLabel="template.general.types.selector.add_option" customClass="white" :bCallback="addSelector"></Button>
                <div class="history" @click="openHistoryPopUp" v-if="history.length > 0 && isSuperAdmin"></div>
                <div class="save-selector" @click="saveHistory" v-if="selectorData.length > 0 && isSuperAdmin" :class="{'can-save': isDiferentToSave()}"></div>
            </div>
        </template>
        <template v-else>
            <template v-for="(option, index) in changedConfig.values">
                <TranslateInput
                    :translation_keyname="'tpl.' + context.template_id + '.item.' + item.id + '.select.' + index + '.value'"
                    :value="option"
                    :_class="false"
                    :_placeholder="false"
                    :key="index"
                ></TranslateInput>
            </template>
        </template>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'
import ColorPicker from '@/components/input/ColorPicker'

export default {
    components: {
        ColorPicker
    },
    props: {
        item: { type: Object },
        config: { default: { values: [] } },
        context: { type: Object, default: {} }
    },
    data() {
        return {
            FORMAT: FORMAT,
            changedType: false,
            changedConfig: [],
            selectorData: [],
            isErrorInput: false, 
            history: [],
            isSuperAdmin: false
        }
    },
    computed: {
        isTranslating() {
            return this.$store.getters['multilanguage/getTranslationLang']
        },
        templates(){
            return this.$store.getters['template/getList']()
        }
    },
    methods: {
        load() {
            this.changedType = parseInt(this.item.type)
            //this.changedConfig = this.config ? this.config : { values: [] }
            const initialTemplate = { input: '', color: null, uuid: 0, delete: false }

            if (this.config.values) {
                const formatedConfigValues = this.config.values.map((item, index) => {
                    const loadedItems = {
                        input: item.v,
                        color: item.c,
                        delete: true,
                        uuid: self.crypto.randomUUID()
                    }

                    if (index === 0) {
                        loadedItems.delete = false
                    }
                    return loadedItems
                })
                this.selectorData = formatedConfigValues
            } else {
                this.selectorData = [initialTemplate]
            }
            this.history = JSON.parse(localStorage.getItem('selector-hist')) || []
        },
        openHistoryPopUp(){
            const history = JSON.parse(localStorage.getItem('selector-hist'))
            const self = this
            this.$popup.selectorsHistory({
                selectorHistory: history,
                callSave: function (e) {
                    const historySelected = e.$children[0].selected
                    if(!historySelected){
                        //Mirar para poner un error o algo
                        return
                    }

                    //Cargar el historial seleccionado
                    const selected = history.find(({ id }) => id === historySelected)
                    self.changedConfig = selected.config
                    self.selectorData = selected.config.values.map(({ v, c }) => {
                        const uuid = crypto.randomUUID()
                        return { input: v, color: c, delete: true, uuid: uuid}
                    })

                    self.sendValue()

                    self.$popup.close()
                }
            })
        },
        sendValue() {
            this.formatToSave()
            this.$emit('sendItemType', { type: this.changedType, config: this.changedConfig })
        },
        formatToSave() {
            const result = this.selectorData.map(({ input, color }) => {
                return { v: input, c: color }
            })
            this.changedConfig = { values: result }
        },
        addSelector() {
            const areAllInputsFilled = this.selectorData.every(({ input }) => input !== '')
            if (areAllInputsFilled) {
                const newSelector = {
                    input: '',
                    color: '',
                    delete: true,
                    uuid: self.crypto.randomUUID()
                }
                this.selectorData.push(newSelector)

                if (this.selectorData.length === 1) {
                    this.selectorData[0]['delete'] = false
                }

                this.isErrorInput = false
            } else {
                this.isErrorInput = true
            }
        },
        deleteSelector(itemIndex) {
            const result = this.selectorData.filter(({ uuid }) => uuid !== itemIndex)
            this.selectorData = result

            this.sendValue()
        },
        saveHistory(){
            if(this.isDiferentToSave()){
                return
            }
            if(this.selectorData.length === 0){
                return
            }
            this.formatToSave()
            const toSave = {
                id: this.item.id,
                template_name: this.templates[this.context.template_id].name,
                item_name: this.item.title,
                config: this.changedConfig
            }

            const index = this.history.findIndex(({ id, template_name, item_name}) => (id === this.item.id) || (template_name === this.templates[this.context.template_id].name && item_name === this.item.title))
            if (index !== -1) {
                this.history[index] = toSave
            } else {
                this.history.push(toSave)
            }

            localStorage.setItem('selector-hist', JSON.stringify(this.history))
        },
        isDiferentToSave(){
            if(!this.itemHasTitle()){
                return true
            }
            const itemHistory = this.history.find(({ id, template_name, item_name}) => (id === this.item.id) || (template_name === this.templates[this.context.template_id].name && item_name === this.item.title))
            if(!itemHistory){
                return false
            }
            
            this.formatToSave()
            return JSON.stringify(itemHistory.config.values) === JSON.stringify(this.item.config.values)
        },

        itemHasTitle(){
            return this.item.title !== ''
        }

    },
    created() {
        this.load()
        this.isSuperAdmin = localStorage.getItem('superAdmin') === 'true'
    },
    watch: {
        item: {
            handler: function (val, oldVal) {
                if (oldVal.id != val.id) {
                    this.load()
                }
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" scoped>
.select-color-text {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 8px;
    .delete-selector {
        @include background($size: 15px, $image: img('delete_alert_selector.svg'), $position: center);
        @include display-flex();
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.config {
    .selector-actions{
        @include display-flex();
        @include align-items(center);
        gap: 10px;
        .history{
            @include background($color: $color-white);
            @include box-shadow($h: 0px, $v: 0px, $b: 4px, $s: 0px, $c: rgba($color-neutral-600, 0.05));
            font-family: $text-bold;
            padding: 16px;
            color: $color-white;
            border-radius: 4px;
            line-height: initial;
            border: solid 1px $color-neutral-300 !important;
            height: 20px;
            width: 20px;
            @include background($image: img('history.svg'), $size: 16px, $position: center);
            cursor: pointer;
        }
        .save-selector{
            @include background($color: $color-white);
            @include box-shadow($h: 0px, $v: 0px, $b: 4px, $s: 0px, $c: rgba($color-neutral-600, 0.05));
            font-family: $text-bold;
            padding: 16px;
            color: $color-white;
            border-radius: 4px;
            line-height: initial;
            border: solid 1px $color-neutral-300 !important;
            height: 20px;
            width: 20px;
            @include background($image: img('save_black.svg'), $size: 20px, $position: center);
            cursor: pointer;

            &.can-save{
                @include background($color: $color-neutral-500);
                @include box-shadow($h: 0px, $v: 0px, $b: 4px, $s: 0px, $c: rgba($color-neutral-600, 0.05));
                font-family: $text-bold;
                padding: 16px;
                color: $color-white;
                border-radius: 4px;
                line-height: initial;
                border: solid 1px $color-neutral-300 !important;
                height: 20px;
                width: 20px;
                @include background($image: img('save_ffffff.svg'), $size: 20px, $position: center);
                cursor: not-allowed;
            }
        }
    }
    .combobox {
        overflow: hidden;
        height: auto;
        margin-bottom: 10px;

        .v-input__slot {
            padding: 0 !important;
        }

        .v-select__selections {
            padding-top: 50px !important;

            .v-chip {
                padding: 0 0px 0 9px;

                button,
                button:hover {
                    background-color: transparent;
                }
            }

            input {
                height: 30px;
                left: 0;
                right: 0;
            }

            .v-chip--removable {
                height: fit-content;
            }
        }

        input {
            // @include background($size: 12px, $image: img('add_neutro_s60.svg'), $position: right 10px center);
            position: absolute;
            top: 10px;
            width: 100%;
            padding-right: 30px;
            padding-left: 5px;
        }
    }
}
</style>
