<template>
    <div
        :ref="Object.values(context)"
        v-if="item"
        class="template-item"
        @click="select($event, context)"
        :class="{ selected: isSelected, subitem: context.item_parent_id }"
    >
        <div class="handle-item translate-hide" :class="{ visible: item.id == selectedContext.item_id }"></div>

        <div class="collapse" v-if="!context.item_parent_id" @click.stop="collapse = !collapse">
            <div class="arrow" :class="{ active: collapse }"></div>
            <template v-if="collapse">{{ $t('template.general.display_content') }}</template>
            <template v-else>{{ $t('template.general.collapse_content') }}</template>
        </div>

        <Summary
            v-if="!context.item_parent_id"
            :email="summary.num_emails"
            :alert="summary.num_alerts"
            :subitem="summary.num_subitems"
            :periodicity="summary.periodicity_type"
        ></Summary>

        <div class="item-body" :class="{ 'error-field': item.errors && item.errors.codes && Object.keys(item.errors.codes).length > 0 }">
            <div class="info">
                <TranslateInput
                    :translation_keyname="'tpl.' + context.template_id + '.item.' + item.id + '.title'"
                    :custom_config="['get_$event']"
                    _type="textarea"
                    :value="item.title"
                    v-on:input="
                        resizeTextarea($event)
                        changeItemValue('title', $event.target.value)
                    "
                    :_placeholder="$t('template.general.item_title_empty')"
                    @click="resizeTextarea($event)"
                    @change="item = item"
                    :_class="[
                        'name',
                        {
                            'error-field':
                                item.errors &&
                                item.errors.codes &&
                                (item.errors.codes.includes('empty_name') ||
                                    item.errors.codes.includes('empty_points') ||
                                    item.errors.codes.includes('empty_operator'))
                        }
                    ]"
                    rows="1"
                ></TranslateInput>
                <!-- <textarea
                    class="name"
                    :value="item.title"
                    @input="
                        (v) => {
                            item.title = v.target.value
                            item = item
                            resizeTextarea(v)
                        }
                    "
                    @change="item = item"
                    :placeholder="$t('template.general.item_title_empty')"
                    rows="1"
                    :class="{ 'error-field': item.errors && item.errors.codes && item.errors.codes.includes('empty_name') }"
                >
                </textarea> -->

                <!-- DESCRIPTION -->
                <TranslateInput
                    :translation_keyname="'tpl.' + context.template_id + '.item.' + item.id + '.description'"
                    :custom_config="['get_$event']"
                    _type="textarea"
                    :value="parseBreakLines(item.description)"
                    @input="
                        resizeDescriptionTextarea($event)
                        changeItemValue('description', $event.target.value)
                    "
                    :_placeholder="$t('template.general.item_description_empty')"
                    @change="item = item"
                    @click="resizeDescriptionTextarea($event)"
                    :_class="['description', { 'error-field': item.errors && item.errors.codes && item.errors.codes.includes('empty_description') }]"
                    maxLength="1000"
                />

                <!-- <textarea
                    :ref="'description' + item.id"
                    class="description"
                    :value="parseBreakLines(item.description)"
                    @input="
                        (v) => {
                            item.description = v.target.value
                            item = item
                            resizeTextarea(v)
                        }
                    "
                    @click="
                        (v) => {
                            resizeTextarea(v)
                        }
                    "
                    @change="item = item"
                    :placeholder="$t('template.general.item_description_empty')"
                    rows="1"
                    :class="{ 'error-field': item.errors && item.errors.codes && item.errors.codes.includes('empty_description') }"
                >
                </textarea> -->
            </div>
            <div class="meta">
                <div v-if="templateType == TYPE.TASK" :class="['element', 'periodicity', periodicityTimes[item.periodicity]]">
                    {{ $t('template.general.periodicity_times_label.' + periodicityTimes[item.periodicity]) }}
                </div>
                <div
                    class="type"
                    :data-type="item.type"
                    :class="{ 'error-field': item.errors && item.errors.codes && item.errors.codes.includes('empty_config') }"
                >
                    <div class="label">{{ $t('template.general.item_type.' + getToolTypeName(item.type, 'FORMAT')) }}</div>
                </div>
            </div>
        </div>
        <!-- <div :class="{ visible: item.id == selectedContext.item_id || (selectedContext && context.item_parent_id == selectedContext.item_id) }" @click="deleteItem()" class="delete" data-type="delete"></div> -->
        <Button
            v-if="item.id == selectedContext.item_id || (selectedContext && context.item_parent_id == selectedContext.item_id)"
            bType="more"
            :class="{
                active: moreItemOptions == item.id,
                visible: item.id == selectedContext.item_id || (selectedContext && context.item_parent_id == selectedContext.item_id)
            }"
            :bCallback="showMoreItemOptions"
            class="more-button translate-hide"
        ></Button>
        <div
            class="more-options translate-hide"
            v-if="(item.id == selectedContext.item_id || (selectedContext && context.item_parent_id == selectedContext.item_id)) && moreItemOptions == item.id"
        >
            <Button bType="duplicate" :bCallback="clone" customClass="btn-duplicate" bLabel="template.general.button_clone"></Button>
            <Button bType="delete" :bCallback="deleteItem" customClass="btn-delete" bLabel="button.delete"></Button>
        </div>

        <div class="conditions" :class="{ collapsed: collapse }" v-if="!collapse">
            <template v-if="item.conditions && !emptyConditions()">
                <template v-for="(condition, condition_index) in orderConditions()">
                    <Condition
                        :key="item.id + '_c' + condition_index"
                        :condition="condition"
                        v-if="condition.status != -1"
                        :context="{ template_id: context.template_id, section_id: context.section_id, item_id: context.item_id, condition_id: condition.id }"
                        :config="item.config ? item.config : {}"
                        :type="item.type"
                        :canDelete="summary.num_alerts == 0 && summary.num_emails == 0 && summary.num_subitems == 0"
                    ></Condition>
                </template>
            </template>
            <template v-else-if="item.id == selectedContext.item_id && !context.item_parent_id">
                <addEntity
                    :context="{
                        template_id: context.template_id,
                        section_id: context.section_id,
                        item_id: context.item_id,
                        condition_id: context.condition_id
                    }"
                    @refresh="fnRefreshEntity()"
                ></addEntity>
            </template>
        </div>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'
import { FORMAT_CAT } from '@/constants'
import { TYPE } from '@/constants'
import addEntity from '@/components/domain/templates/template/addEntity.vue'
// ITEM COMPONENTS
import Summary from '@/components/domain/templates/item/summary.vue'
import Condition from '@/components/domain/templates/item/condition.vue'

export default {
    components: {
        addEntity,
        Condition,
        Summary
    },
    name: 'Item',
    props: {
        context: { type: Object, default: {} },
        item: { type: Object }
    },
    data() {
        return {
            collapse: true,
            FORMAT: FORMAT,
            FORMAT_CAT: FORMAT_CAT,
            TYPE: TYPE,
            periodicityTimes: { 1: 'daily', 2: 'weekly', 3: 'biweekly', 4: 'monthly', 5: 'quarterly', 6: 'biannually', 7: 'annually', 9: 'custom' },
            summary: { num_emails: 0, num_alerts: 0, num_subitems: 0, periodicity_type: 1 },
            isSelected: false
        }
    },
    computed: {
        templateType() {
            if (this.$route.params.id) {
                var template = {
                    ...this.$store.getters['template/getTemplate'](this.$route.params.id)
                }
            } else {
                var template = { ...this.$store.getters['template/getTemplate'](-1) }
            }
            return template.type
        },

        // item: {
        //     get() {
        //         var item = { ...this.$store.getters['template/getItem'](this.context) }
        //         return item
        //     },
        //     set(value) {
        //         value.description = value.description.replace(/\r\n|\n|\r/g, '<br>')
        //         this.$store.commit('template/updateItem', { context: this.context, item: value })
        //     }
        // },

        selectedContext() {
            // GET ACTIVE SELECTED ITEM
            var selectedContext = this.$store.getters['template/getSelectedContext']
            // AUTOMATIC COLLAPSE/DISPLAY ITEM AND HIS PARENT ITEM (IF IT HAS)
            if ((selectedContext && selectedContext.item_id == this.item.id) || selectedContext.item_parent_id == this.item.id) {
                this.collapse = false
                this.isSelected = true
                // this.moreItemOptions = false
                if (selectedContext && Object.keys(selectedContext).length > 0) {
                    // this.$nextTick(() => {
                    //     if (this.$refs[Object.values(selectedContext)]) {
                    //         this.$refs[Object.values(selectedContext)].scrollIntoView({ block: 'nearest', scrollBehavior: 'smooth' })
                    //     }
                    // })
                }
            } else {
                this.isSelected = false
                this.collapse = true
            }

            return selectedContext
        },

        moreItemOptions: {
            get() {
                var moreItem = this.$store.getters['template/getMoreItemOptions']
                return moreItem && moreItem.type == 'item' ? moreItem.id : false
            },
            set(value) {
                this.$store.commit('template/setMoreItemOptions', { type: 'item', id: value })
            }
        }
    },
    methods: {
        emptyConditions() {
            console.warn('emptyConditions')
            if (!this.item.conditions) return true
            else {
                var self = this
                for (var k in this.item.conditions.values) {
                    if (typeof self.item.conditions.values[k].status === 'undefined' || self.item.conditions.values[k].status == 1) return false
                }
            }
            return true
        },

        showMoreItemOptions() {
            if (this.moreItemOptions == this.item.id) {
                this.moreItemOptions = false
            } else {
                this.moreItemOptions = this.item.id
            }
        },

        // CLONE ITEM
        clone() {
            this.$store.commit('template/cloneItem', { context: this.context, item: { ...this.item } })
        },

        parseBreakLines(value) {
            return value ? value.replace(/<br>/g, '\n') : ''
        },

        orderConditions() {
            // ORDER CONDITIONS BY THE ORDER PROP
            if (this.item.conditions.values) {
                var conditions = Object.values({ ...this.item.conditions.values })
                conditions.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))
                return conditions
            }
            return []
        },

        select(e, context) {
            if (e) {
                e.preventDefault()
                e.stopPropagation()
            }
            this.$store.commit('template/setSelectedContext', context)
            this.collapse = false
            this.isSelected = true
            // RESIZE DESCRIPTION TEXTAREA ON SELECT
            // this.$refs['description' + this.item.id].style.height = 'auto'
            // this.$refs['description' + this.item.id].style.height = this.$refs['description' + this.item.id].scrollHeight + 5 + 'px'
        },

        calcSummary() {
            var email = 0
            var alert = 0
            var subitem = 0

            var self = this
            if (this.item && this.item.conditions) {
                for (var k in this.item.conditions.values) {
                    var condition = self.item.conditions.values[k]
                    if (typeof condition.status === 'undefined' || condition.status == 1) {
                        condition.actions.forEach((action) => {
                            switch (action.type) {
                                case 'info':
                                    alert++
                                    break
                                case 'email':
                                    email++
                                    break
                            }
                        })

                        Object.values(condition.items).forEach((item) => {
                            if (item.status == 1) subitem++
                        })
                    }
                }
            }

            this.summary = {
                num_emails: email,
                num_alerts: alert,
                num_subitems: subitem,
                periodicity_type: this.item.periodicity
            }
        },
        changeItemValue(type, value) {
            this.$store.commit('template/updateItem', { context: this.context, item: this.item })
            this.item[type] = value
            this.item = this.item
        },
        resizeTextarea(event) {
            event.target.style.height = 'auto'
            event.target.style.height = event.target.scrollHeight + 5 + 'px'
        },

        resizeDescriptionTextarea(event) {
            if (event) {
                event.target.style.height = 'auto'

                if (event.type === 'click') {
                    event.target.style.height = event.target.scrollHeight + 24 + 'px'
                } else {
                    event.target.style.height = event.target.scrollHeight + 8 + 'px'
                }
            } else {
                const textarea = this.$el.querySelector('.description')
                textarea.style.height = 'auto'
                textarea.style.height = textarea.scrollHeight + 8 + 'px'
            }
        },

        getToolTypeName(type, constant) {
            if (constant == 'FORMAT') {
                return Object.keys(FORMAT).find((key) => FORMAT[key] === parseInt(type))
            } else if (constant == 'FORMAT_CAT') {
                return Object.keys(FORMAT_CAT).find((key) => FORMAT_CAT[key].id === parseInt(type))
            }
        },

        deleteItem() {
            var self = this
            this.$popup.confirm({
                message: this.$t('template.general.popup_delete_item_msg'),
                textSave: this.$t('user.popup.delete_text_save'),
                callSave: function () {
                    self.$store.commit('template/deleteItem', { context: self.context })
                },
                callCancel: function () {
                    this.$popup.close()
                }
            })
        }
    },
    created() {
        this.calcSummary()
    },

    mounted() {
        this.resizeDescriptionTextarea()
    },
    watch: {
        item: {
            immediate: true,
            handler(val) {
                this.calcSummary()
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.v-text-field {
    width: unset;
}
</style>

<style lang="scss">
.template-item {
    @include border-radius(4px);
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 0 30px 9px 0px;
    margin: 4px 0;
    transition-duration: $transition-duration;

    &.subitem {
        padding-top: 9px;
    }

    .collapse {
        @include font-size(xs);
        @include display-flex();
        @include align-items();
        @include justify-content(flex-start);
        font-family: $text-bold;
        color: $color-neutral-600;
        padding: 2px;
        padding-left: 0;
        cursor: pointer;
        margin-top: 2px;
        position: absolute;

        .arrow {
            display: inline-flex;
            @include background($size: 9px, $image: img('up_dark.svg'), $position: center center);
            width: 15px;
            height: 10px;
            transition-duration: $transition-duration;

            &.active {
                transform: rotate(-180deg);
            }
        }
    }

    .handle-item {
        // @include background($size: 20px, $image: img('grab_neutro_s50.svg'), $position: left center);
        @include background($size: 20px, $image: img('grab_arrows.svg'), $position: left center);
        cursor: grab;
        width: 20px;
        height: 20px;
        position: absolute;
        // top: 40px;
        top: 32px;
        left: 0px;
        opacity: 0;
        // visibility: hidden;
        transition-duration: $transition-duration;

        &.visible {
            // visibility: visible;
            opacity: 1;
        }
    }

    .item-body {
        @include border-radius(6px);
        @include display-flex();
        @include justify-content(flex-start);
        background-color: $color-white;
        width: 100%;
        cursor: pointer;
        border: 1px solid $color-neutral-300;
        border-left: 5px solid $color-neutral-300;

        .info {
            @include display-flex();
            @include justify-content(flex-start);
            @include flex-direction(column);
            width: calc(100% - 90px);
            padding: 16px;

            input.integrated::placeholder {
                color: $color-neutral-300;
            }

            .translateInput {
                margin-bottom: 0px !important;
            }

            .v-textarea {
                @include align-items();
                background-color: inherit !important;

                textarea {
                    margin: 0;
                }
            }

            .v-input {
                height: auto;
            }

            textarea {
                @extend .hide-scrollbar;
                border: none;
                box-sizing: border-box;
                padding: 0px !important;

                &.name {
                    @include font-size($size: ml, $important: true);
                    font-family: $text-bold;
                    color: $color-black;
                    overflow: auto;
                    width: 100%;
                    height: 16px !important;
                }
                &.description {
                    box-sizing: border-box;
                    @include font-size(md);
                    font-family: $text;
                    color: $color-neutral-600;
                    // margin-left: 10px;
                    width: 100%;
                }

                &.error-field {
                    @include background($image: img('warning_error.svg'), $size: 15px, $position: right 12px center);
                    // background-color: rgba($color-error-500, 0.03);
                    padding-right: 30px;

                    @include placeholder {
                        color: $color-error-500;
                    }
                }
            }
        }

        .meta {
            width: 100px;

            .periodicity {
                @include display-flex();
                @include justify-content();
                @include align-content();
                @include border-radius(4px);
                @include font-size(xs);
                @include background($image: img('calendar_inactive_s70.svg'), $size: 12px, $position: left 5px center);
                font-family: $text;
                color: $color-neutral-600;
                min-height: 20px;
                text-align: center;
                margin-top: 10px;
                float: right;
                margin-right: 10px;
                width: 90px;
                background-color: $color-neutral-600;

                &.daily {
                    background-color: #b8ebcb;
                }

                &.weekly {
                    background-color: #ebcfb8;
                }

                &.biweekly {
                    background-color: #e7b8eb;
                }

                &.monthly {
                    background-color: #c8b8eb;
                }

                &.quarterly {
                    background-color: #b8cbeb;
                }

                &.biannually {
                    background-color: #e7ebb8;
                }

                &.annually {
                    background-color: #ebb8b8;
                }

                // &.custom {
                //     // DEFAULT
                // }
            }
        }

        .type {
            width: 90px !important;
            margin: 6px 6px 6px 0px;
        }

        &.error-field {
            border: 2px solid $color-error-500;
            // position: relative;

            // &:after {
            //     @include border-radius(100%);
            //     @include background($color: $color-error-500, $image: img('warning_error.svg'));
            //     content: '';
            //     position: absolute;
            //     top: -6px;
            //     right: -6px;
            //     display: block;
            //     width: 15px;
            //     height: 15px;
            // }
        }
        &:hover {
            border-left: 5px solid $color-secondary-500 !important;
        }
    }

    .conditions {
        transition-duration: $transition-duration;
        height: fit-content;
        overflow: visible;

        &.collapsed {
            max-height: 0;
            overflow: visible;
            overflow-y: hidden;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    // .delete {
    //     margin: 0;
    //     width: 20px;
    //     height: 20px;
    //     position: absolute;
    //     top: 40px;
    //     right: 0;
    //     opacity: 0;
    //     // visibility: hidden;
    //     transition-duration: $transition-duration;

    //     &.visible {
    //         opacity: 1;
    //     }
    // }

    .more-button {
        margin: 0;
        width: 20px;
        height: 20px;
        position: absolute;
        // top: 40px;
        top: 32px;
        right: 0;
        opacity: 0;
        transition-duration: $transition-duration;
        background-color: transparent;

        &.visible {
            opacity: 1;
        }

        &:hover {
            opacity: 1;
        }
    }

    .more-options {
        @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-600, 0.3));
        @include border-radius(4px);
        @include display-flex();
        @include flex-direction(column);
        @include align-items(flex-end);
        position: absolute;
        right: 20px;
        top: 30px;
        z-index: $z-barActionMenus;
        padding: 3px 3px 0 3px;
        background-color: $color-neutral-200;

        .btn {
            box-shadow: unset;
            width: 100%;
            margin: 0 0 3px 0;
        }
    }

    &:after {
        @include border-radius(6px);
        content: '';
        width: calc(100% + 24px);
        height: 100%;
        position: absolute;
        top: 0;
        left: -12px;
        z-index: -1;
        background-color: transparent;
        transition-duration: $transition-duration;
    }
    // &:hover:after {
    //     // background-color: rgba($color-neutral-300, 0.25);
    // }
    &.selected:after {
        // background-color: rgba($color-neutral-300, 0.6) !important;
        background-color: $color-secondary-100 !important;
    }

    // &.subitem {
    //     right: 30px;
    // }
    &.subitem {
        padding-right: 2em;

        .more-button {
            top: 12px !important;
        }
    }

    &.subitem:after {
        width: calc(100% + 12px);
        left: -6px;
    }
    &.subitem:hover:after {
        background-color: rgba($color-neutral-300, 1);
    }

    &.selected {
        // border-left: 5px solid red;
        width: 100%;
        padding: 9px 28px 9px 30px;

        .conditions {
            @include border-radius(0 0 0 4px);
            border-left: 5px solid $color-secondary-300;
            margin-top: 8px;
        }

        .collapse {
            color: $color-black;
        }

        .item-body {
            border-left: 5px solid $color-secondary-500 !important;
        }
    }

    // HOVER ITEM STYLES
    &:hover {
        .delete {
            opacity: 1;
        }
    }
}
</style>
