<template>
    <div class="config main" :key="item.id" v-if="item">
        <div class="name main check">
            {{ $t('template.general.issue_title_option') }}
            <input class="switch-input" type="checkbox" v-model="vTitle" @click.stop="updateItem()" />
        </div>
        <div class="issue-title-config">
            <div class="descriptionissue" v-if="vTitle" v-html="$t('template.general.issue_is_title') + templateName + `'.`" style="font-size: 0.875rem"></div>
            <div class="descriptionissue" v-else>
                <p style="font-size: 0.875rem">
                    {{ $t('template.general.issue_not_title') }}
                    <a class="moreinfooption" style="font-size: 0.875rem" :href="helpLink" target="_blank">{{ $t('sites.form.more_information') }}</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Title',
    props: {
        context: { type: Object, default: {} },
        templateName: { type: String, default: '' }
    },
    data() {
        return {
            helpLink: 'https://help.andyapp.io/portal/es/kb/articles/c%C3%B3mo-personalizar-el-t%C3%ADtulo-de-una-incidencia',
            isTitle: false,
            collapse: [],
            vTitle: this.item && this.item.config && this.item.config.custom_title ? this.item.config.custom_title : false
        }
    },
    computed: {
        item() {
            var item = this.$store.getters['template/getItem'](this.context)
            return item
        }
    },
    methods: {
        updateItem() {
            // if (!this.item || !this.item.config) {
            //     return console.log('error updating the custom issue option.');
            // }

            // var aCollapse = this.collapse
            this.isTitle = !this.isTitle
            // aCollapse.splice(aCollapse.indexOf('custom_title'), 1)
            var config = this.item.config ? this.item.config : { custom_title: false }
            config.custom_title = !config.custom_title
            this.item.config = config
            this.$store.commit('template/updateItem', { context: this.context, item: this.item })
        }
    },
    watch: {
        item: {
            handler(newItem) {
                if (newItem) {
                    this.vTitle = newItem.config.custom_title
                }
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.descriptionissue {
    .moreinfooption {
        color: $color-primary-500;
    }
}
</style>
