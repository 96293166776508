<template>
    <div class="summary">
        <div class="element email" :class="{ disabled: email == 0 }"></div>
        <div class="element alert" :class="{ disabled: alert == 0 }"></div>
        <div class="element subitem" :class="{ disabled: subitem == 0 }"></div>
        <!-- <div v-if="templateType == TYPE.TASK" class="element periodicity" :class="{ disabled: periodicity == 0 }">{{ $t('template.general.periodicity_times_label.' + periodicityTimes[periodicity]) }}</div> -->
    </div>
</template>

<script>
import { TYPE } from '@/constants'
export default {
    name: 'Summary',
    props: {
        email: { default: 0 },
        alert: { default: 0 },
        subitem: { default: 0 },
        periodicity: { default: false }
    },
    data() {
        return {
            periodicityTimes: { 1: 'daily', 2: 'weekly', 3: 'biweekly', 4: 'monthly', 5: 'quarterly', 6: 'biannually', 7: 'annually', 9: 'custom' },
            TYPE: TYPE
        }
    },
    computed: {
        templateType() {
            if (this.$route.params.id) {
                var template = {
                    ...this.$store.getters['template/getTemplate'](this.$route.params.id)
                }
            } else {
                var template = { ...this.$store.getters['template/getTemplate'](-1) }
            }
            return template.type
        }
    },
    methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.summary {
    @include display-flex();
    @include justify-content(flex-end);
    @include align-items();
    // display: inline-block;
    text-align: right;
    // margin-right: 68px;
    height: 20px;

    .element {
        @include font-size(xs);
        @include background($size: 12px, $position: left center);
        margin-left: 10px;
        font-family: $text;
        color: $color-neutral-600;
        padding-left: 15px;
        min-width: 15px;
        min-height: 15px;
        display: inline-block;

        &.disabled {
            display: none;
            color: $color-black;
        }

        &.email {
            @include background-image($image: img('format_mail_second.svg'));

            &.disabled {
                @include background-image($image: img('format_mail_inactive_s20.svg'));
            }
        }

        &.alert {
            @include background-image($image: img('alert_second.svg'));

            &.disabled {
                @include background-image($image: img('alert_inactive_s20.svg'));
            }
        }

        &.subitem {
            @include background-image($image: img('subitem_second.svg'));

            &.disabled {
                @include background-image($image: img('subitem_inactive_s20.svg'));
            }
        }

        &.periodicity {
            @include background-image($image: img('calendar_inactive_s70.svg'));

            &.disabled {
                @include background-image($image: img('calendar_inactive_s20.svg'));
            }
        }
    }
}
</style>
