<template>
    <div class="power-by-rol-box" :data-id-rol="`${role.roleId}-${index}`">
        <v-autocomplete
            hide-details
            class="select"
            v-model="role.roleId"
            :items="Object.values(roles)"
            item-text="name"
            item-value="id"
            attach
            dense
            :class="{
                error:
                    !validation() || !otherInputsValuesValidation || (template.errors && template.errors.codes && template.errors.codes.includes('empty_roles'))
            }"
            :no-data-text="$t('supervise.filter.no_results')"
        >
        </v-autocomplete>
        <div class="power-group" :class="{ audit: [2].includes(template.type) }">
            <div class="option">
                <v-checkbox disabled primary :input-value="true"></v-checkbox>
            </div>
            <div class="option">
                <v-checkbox primary v-model="editPermission" :ripple="false"></v-checkbox>
            </div>

            <div class="option" v-if="[3].includes(template.type)">
                <v-checkbox primary v-model="managePermission" :ripple="false"></v-checkbox>
            </div>
            <div class="option" v-if="[2].includes(template.type)">
                <v-checkbox primary v-model="supervisePermission" :ripple="false"></v-checkbox>
            </div>
        </div>
        <div @click="deleteRol" class="delete-rol"></div>
    </div>
</template>
<script>
export default {
    name: 'roleField',
    props: {
        template: {
            type: Object,
            required: true
        },
        roles: {
            type: Object,
            required: true
        },
        role: {
            type: undefined,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        otherInputsValuesValidation: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            rolSelected: false,
            editPermission: false,
            managePermission: false,
            supervisePermission: false,
            isLoaded: false
        }
    },
    methods: {
        validation() {
            if (!this.role.roleId) {
                return false
            }
            return true
        },
        deleteRol() {
            this.$emit('deleteRole', { index: this.index, role: this.role })
        },
        commitPermissions() {
            if (!this.isLoaded) return
            if (this.editPermission && this.managePermission && this.supervisePermission) {
                this.role.permissions = 'rwx'
            } else if (this.managePermission && this.supervisePermission) {
                this.role.permissions = 'rx'
            } else if (this.editPermission && this.supervisePermission) {
                this.role.permissions = 'rw'
            } else {
                this.role.permissions = 'r'
            }
        }
    },

    created() {
        if (this.role.permissions === 'rw') {
            this.editPermission = true
            this.managePermission = false
            this.supervisePermission = true
        } else if (this.role.permissions === 'rx') {
            this.editPermission = false
            this.managePermission = true
            this.supervisePermission = true
        } else if (this.role.permissions === 'rwx') {
            this.editPermission = true
            this.managePermission = true
            this.supervisePermission = true
        } else if (this.role.permissions === 'r') {
            this.editPermission = false
            this.managePermission = false
            this.supervisePermission = true
        } else {
            this.editPermission = false
            this.managePermission = false
            this.supervisePermission = false
        }
        this.isLoaded = true
    },

    watch: {
        editPermission: {
            handler: function (value) {
                this.commitPermissions()
            },
            immediate: true
        },
        managePermission: {
            handler: function (value) {
                this.commitPermissions()
            },
            immediate: true
        },
        supervisePermission: {
            handler: function (value) {
                this.commitPermissions()
            },
            immediate: true
        }
    }
}
</script>
<style lang="scss">
.power-by-rol-box {
    @include display-flex();
    @include align-items();

    .delete-rol {
        @include background($size: 15px, $image: img('delete_alert_selector.svg'), $position: center);
        @include display-flex();
        width: 10%;
        height: 20px;
        cursor: pointer;
    }
    .v-input.select {
        width: 40%;

        &.error {
            border-color: $color-error-500 !important;
        }
    }
    .power-group {
        width: 60%;
        @include display-flex();
        @include align-items();
        @include justify-content(flex-end);
        gap: 34px;

        &.audit {
            width: 40%;
            gap: 16px;
        }
        .option {
            display: inline-flex;
            margin-bottom: 0px;
            width: fit-content;
            flex-direction: row;

            .label {
                width: auto;
                margin: auto;
            }

            .v-input--is-disabled {
                .v-icon {
                    color: $color-neutral-600 !important;
                }
            }

            .v-icon {
                color: $color-primary-500 !important;
            }
        }
    }
}
</style>
