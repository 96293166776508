<template>
    <div v-if="template">
        <div class="config main inline" :class="{ active: oCreateNotify.active }" v-if="oCreateNotify">
            <div class="name main check">
                <div class="collapse" :class="{ collapsed: collapse.indexOf('createNotify') == -1 }"></div>
                {{ $t('template.general.create_notify.title') }}
                <input class="switch-input" type="checkbox" v-model="oCreateNotify.active" :value="true" @click.stop @change="expandSection('createNotify')" />
            </div>
            <div class="config-content" :class="{ collapsed: collapse.indexOf('createNotify') > -1 }">
                <template v-if="oCreateNotify.active">
                    <div class="description" v-html="$t('template.general.create_notify.description')"></div>
                    <v-combobox
                        v-model="oCreateNotify.to"
                        class="combobox"
                        :placeholder="$t('template.general.types.email.placeholder')"
                        hide-selected
                        multiple
                        hide-details
                        small-chips
                        solo
                        flat
                        deletable-chips
                        attach
                        append-icon=""
                        disable-lookup
                        @change="oCreateNotify = convertEmails(oCreateNotify)"
                        :class="{ error: template.errors && template.errors.codes && (template.errors.codes.includes('empty_to_create') || template.errors.codes.includes('error_to_create_custom_fields'))}"
                    >
                    </v-combobox>

                    <div class="description" v-html="$t('template.general.subject')"></div>
                    <input
                        class="standard"
                        v-model="oCreateNotify.subject"
                        :class="{ error: template.errors && template.errors.codes && (template.errors.codes.includes('empty_subject_create') || template.errors.codes.includes('error_subject_create_custom_fields') )}"
                    />

                    <div class="description" v-html="$t('template.general.message')"></div>
                    <textarea
                        class="standard long"
                        v-model="oCreateNotify.message"
                        :class="{ error: template.errors && template.errors.codes && (template.errors.codes.includes('empty_message_create') || template.errors.codes.includes('error_message_create_custom_fields')) }"
                    ></textarea>
                </template>
            </div>
        </div>

        <div class="config main inline" :class="{ active: oReopenNotify.active }" v-if="oReopenNotify">
            <div class="name main check">
                <div class="collapse" :class="{ collapsed: collapse.indexOf('reopenNotify') == -1 }"></div>
                {{ $t('template.general.reopen_notify.title') }}
                <input class="switch-input" type="checkbox" v-model="oReopenNotify.active" :value="true" @click.stop @change="expandSection('reopenNotify')" />
            </div>
            <div class="config-content" :class="{ collapsed: collapse.indexOf('reopenNotify') > -1 }">
                <template v-if="oReopenNotify.active">
                    <div class="description" v-html="$t('template.general.reopen_notify.description')"></div>
                    <v-combobox
                        v-model="oReopenNotify.to"
                        class="combobox"
                        :placeholder="$t('template.general.types.email.placeholder')"
                        hide-selected
                        multiple
                        hide-details
                        small-chips
                        solo
                        flat
                        deletable-chips
                        attach
                        append-icon=""
                        disable-lookup
                        @change="oReopenNotify = convertEmails(oReopenNotify)"
                        :class="{ error: template.errors && template.errors.codes && template.errors.codes.includes('empty_to_reopen') }"
                    >
                    </v-combobox>

                    <div class="description" v-html="$t('template.general.subject')"></div>
                    <input
                        class="standard"
                        v-model="oReopenNotify.subject"
                        :class="{ error: template.errors && template.errors.codes && (template.errors.codes.includes('empty_subject_reopen') || template.errors.codes.includes('error_subject_reopen_custom_fields'))}"
                    />

                    <div class="description" v-html="$t('template.general.message')"></div>
                    <textarea
                        class="standard long"
                        v-model="oReopenNotify.message"
                        :class="{ error: template.errors && template.errors.codes && (template.errors.codes.includes('empty_message_reopen') || template.errors.codes.includes('error_message_reopen_custom_fields'))}"
                    ></textarea>
                </template>
            </div>
        </div>

        <div class="config main inline" :class="{ active: oCloseNotify.active }" v-if="oCloseNotify">
            <div class="name main check">
                <div class="collapse" :class="{ collapsed: collapse.indexOf('closeNotify') == -1 }"></div>
                {{ $t('template.general.close_notify.title') }}
                <input class="switch-input" type="checkbox" v-model="oCloseNotify.active" :value="true" @click.stop @change="expandSection('closeNotify')" />
            </div>
            <div class="config-content" :class="{ collapsed: collapse.indexOf('closeNotify') > -1 }">
                <template v-if="oCloseNotify.active">
                    <div class="description" v-html="$t('template.general.close_notify.description')"></div>
                    <v-combobox
                        v-model="oCloseNotify.to"
                        class="combobox"
                        :placeholder="$t('template.general.types.email.placeholder')"
                        hide-selected
                        multiple
                        hide-details
                        small-chips
                        solo
                        flat
                        deletable-chips
                        attach
                        append-icon=""
                        disable-lookup
                        @change="oCloseNotify = convertEmails(oCloseNotify)"
                        :class="{ error: template.errors && template.errors.codes && (template.errors.codes.includes('empty_to_close') || template.errors.codes.includes('error_to_close_custom_fields'))}"
                    >
                    </v-combobox>

                    <div class="description" v-html="$t('template.general.subject')"></div>
                    <input
                        class="standard"
                        v-model="oCloseNotify.subject"
                        :class="{ error: template.errors && template.errors.codes && template.errors.codes.includes('error_subject_close_custom_fields') }"
                    />

                    <div class="description" v-html="$t('template.general.message')"></div>
                    <textarea
                        class="standard long"
                        v-model="oCloseNotify.message"
                        :class="{ error: template.errors && template.errors.codes && template.errors.codes.includes('error_message_close_custom_fields') }"
                    ></textarea>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'
import i18n from '@/i18n'

export default {
    components: {},
    props: {
        context: { type: Object, default: {} }
    },
    data() {
        return {
            defaultObject: {
                active: false,
                to: [],
                subject: '',
                message: ''
            },
            error: {
                margin: false
            },
            collapse: [],

            // GENERAL VARIABLES
            FORMAT: FORMAT
        }
    },
    computed: {
        template: {
            get() {
                var template = { ...this.$store.getters['template/getTemplate'](this.context.template_id) }
                return template
            },
            set(value) {
                this.$store.commit('template/updateTemplate', { context: this.context, template: value })
            }
        },

        oCreateNotify: {
            get() {
                if (this.template && this.template.config) {
                    if (this.template.config.createNotify) {
                        return this.template.config.createNotify
                    }
                }
                return { ...this.defaultObject }
            },
            set(value) {
                var oTemaplate = Object.assign({}, this.template)
                oTemaplate.config.createNotify = value
                this.$store.commit('template/updateTemplate', { context: this.context, template: oTemaplate })
            }
        },
        oReopenNotify: {
            get() {
                if (this.template && this.template.config) {
                    if (this.template.config.reopenNotify) {
                        return this.template.config.reopenNotify
                    }
                }
                return { ...this.defaultObject }
            },
            set(value) {
                var oTemaplate = Object.assign({}, this.template)
                oTemaplate.config.reopenNotify = value
                this.$store.commit('template/updateTemplate', { context: this.context, template: oTemaplate })
            }
        },
        oCloseNotify: {
            get() {
                if (this.template && this.template.config) {
                    if (this.template.config.closeNotify) {
                        return this.template.config.closeNotify
                    }
                }
                return { ...this.defaultObject }
            },
            set(value) {
                var oTemaplate = Object.assign({}, this.template)
                oTemaplate.config.closeNotify = value
                this.$store.commit('template/updateTemplate', { context: this.context, template: oTemaplate })
            }
        }
    },
    methods: {
        load() {},

        collapseSection(section) {
            var aCollapse = this.collapse
            if (aCollapse.indexOf(section) > -1) {
                aCollapse.splice(aCollapse.indexOf(section), 1)
            } else {
                aCollapse.push(section)
            }
        },

        expandSection(section) {
            var aCollapse = this.collapse
            aCollapse.splice(aCollapse.indexOf(section), 1)
        },

        sendValue() {},

        validate() {
            this.error.margin = false

            if (this.item.margin != '') {
                if (this.item.margin < 1) {
                    this.item.margin = 1
                } else if (this.item.margin > this.maxMarginDays()) {
                    this.item.margin = this.maxMarginDays()
                } else this.item.margin = parseInt(this.item.margin)
            } else this.error.margin = true

            if (this.item.margin == '') {
                this.error.margin = true
            }

            this.sendValue()
        },
        convertEmails(emails) {
            const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g
            if(emails && emails.to && emails.to.length > 0){
                const emailTemp = [];
                emails.to.map(email => {
                    if(email.match(emailRegex)){
                        email.split(emailRegex).map(e => {
                            if(e.match(emailRegex)){
                                emailTemp.push(e)
                            }
                        })
                    }
                })
                emails.to = emailTemp;
            }
            return emails
        }
    },
    created() {
        this.load()
    },
    watch: {
        // item: {
        //     handler: function (val, oldVal) {
        //         if (oldVal.id != val.id) {
        //             this.load()
        //         }
        //     },
        //     deep: true
        // }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.config {
    .combobox {
        overflow: hidden;
        height: auto;
        margin-bottom: 10px;

        .v-input__slot {
            padding: 0 !important;
        }

        .v-select__selections {
            padding-top: 50px !important;

            .v-chip {
                padding: 0 0px 0 9px;
            }

            input {
                height: 30px;
                left: 0;
                right: 0;
            }

            .v-chip--removable {
                height: fit-content;
            }
        }

        input {
            // @include background($size: 12px, $image: img('add_neutro_s60.svg'), $position: right 10px center);
            position: absolute;
            top: 10px;
            width: 100%;
            padding-right: 30px;
            padding-left: 5px;
        }

        &.error {
            @include background($image: img('warning_error.svg'), $size: 15px, $position: right 12px center);
            padding-right: 30px;
            border-color: $color-error-500;

            @include placeholder {
                color: $color-error-500;
            }
        }
    }

    .standard {
        @include font-size('sm');
        // background-color: $color-neutral-50 !important;
        height: 30px;
        border-radius: 3px;
        padding: 5px;
        margin-bottom: 10px;
        border: 1px solid $color-neutral-300;
        width: 100%;

        &.long {
            line-height: 1.4;
            height: calc(22px * 4);
        }

        &.error {
            @include background($image: img('warning_error.svg'), $size: 15px, $position: right 12px center);
            padding-right: 30px;
            border-color: $color-error-500;

            @include placeholder {
                color: $color-error-500;
            }
        }
    }
}
</style>
