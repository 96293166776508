<template>
    <div v-if="template">
        <!-- :class="{'hide': showItemProperties && (itemSelected.type != FORMAT.TITLE && itemSelected.type != FORMAT.SUBTITLE)}" -->
        <!-- @click="unselectItem()" -->
        <div class="sidebar-right template-config scrollbar" @click="unselect()" :class="{ hide: selectedContext }">
            <div class="name">{{ $t('template.checklists.properties') }}</div>

            <!-- CONFIG SECTIONS -->
            <div class="configs scrollbar">
                <!-- STATUS OF TEMPLATE -->
                <div class="config active translate-disable" v-if="template.status">
                    <div class="name" v-html="$t('template.general.status.label')"></div>
                    <!-- <div class="description" v-html="$t('template.general.status.description')"></div> -->
                    <Input
                        :key="template.status"
                        gType="select"
                        v-model="template.status"
                        :gOptions="{ multiple: false, returnObject: false, removable: false, mandatory: false, itemText: 'name', itemValue: 'value' }"
                        :gItems="optionsStatus"
                        @change="template = template"
                    ></Input>
                    <!-- <div class="description">{{ template.status == '1' ? $t('template.general.status.note_published') : $t('template.general.status.note_draft') }}</div> -->
                    <div class="description">{{ descriptionOfStatus() }}</div>

                    <div class="actual-status">
                        <strong>{{ $t('template.checklists.template_state.actual_value') }}</strong
                        >:
                        {{ titleOfActualStatus() }}
                    </div>

                    <!-- PROGRAMAR CAMBIOS / SCHEDULE CHANGES (only for NON-TEMPORARY TASKS)-->
                    <div v-if="template.status == 1 && template.type == 1 && !templateIsTemporal" class="temporize-changes">
                        <div class="temporize-changes-title">
                            <div class="title-temporize">{{ $t('template.checklists.template_state.changes_time') }}</div>
                            <div class="description-temporize" v-html="$t('template.checklists.template_state.description', { link: '#' })"></div>
                        </div>

                        <v-radio-group class="opt-group" v-model="position">
                            <div
                                class="option"
                                @click="
                                    position = 1
                                    template.scheduled_date = null
                                "
                            >
                                <v-radio class="radio" color="#157d82" :value="1" @click="resetValuesOfDate"></v-radio>
                                <div class="label" @click="resetValuesOfDate">
                                    {{ $t('template.checklists.template_state.immediately') }}
                                </div>
                            </div>
                            <div
                                class="option"
                                @click="
                                    position = 2
                                    template.scheduled_date = template.scheduled_date ? template.scheduled_date : dateMin
                                "
                            >
                                <v-radio class="radio" color="#157d82" :value="2" @click="startValuesOfDate"></v-radio>
                                <div class="label" @click="startValuesOfDate">{{ $t('template.checklists.template_state.programate') }}</div>
                            </div>
                        </v-radio-group>

                        <div class="date-selector">
                            <Input
                                gType="date"
                                v-if="position == 2"
                                v-model="template.scheduled_date"
                                :gOptions="{ max: dateMax, min: dateMin }"
                                @blur="checkLimitsDate"
                                :key="dateCount"
                            ></Input>
                        </div>

                        <div class="advise" v-if="position == 2 && oldDate">{{ $t('template.checklists.template_state.advise') }} {{ oldDate }}</div>
                    </div>

                    <!-- ONLY FOR TEMPORAL TEMPLATES (TASKS) -->
                    <div v-if="templateIsTemporal" class="temporary-templates temporize-changes">
                        <p v-show="showTemporalTemplateError" class="error-msg">{{ $t('template.checklists.temporary_templates_config.error_msg') }}</p>
                        <p v-show="!showTemporalTemplateError" class="warning-msg">{{ $t('template.checklists.temporary_templates_config.warning_msg') }}</p>
                        <h4 class="temporary-templates-title">{{ $t('template.checklists.temporary_templates_config.title') }}</h4>

                        <v-radio-group class="opt-group" v-model="temporaryTemplateType">
                            <!-- ONE DAY / un día específico-->
                            <div
                                class="option"
                                :class="{ disabled: temporaryTemplatesRadioButtonsAreDisabled }"
                                @click="
                                    if (temporaryTemplatesRadioButtonsAreDisabled) {
                                        return
                                    } else {
                                        temporaryTemplateType = 1
                                        template.config.temporary.end_date = null
                                    }
                                "
                            >
                                <v-radio class="radio" color="#157d82" :value="1" :disabled="temporaryTemplatesRadioButtonsAreDisabled"></v-radio>
                                <p class="label" :class="{ disabled: temporaryTemplatesRadioButtonsAreDisabled }">
                                    {{ $t('template.checklists.temporary_templates_config.radio_option_1') }}
                                </p>
                            </div>

                            <!-- input calendar (un día) START DATE -->
                            <div v-show="temporaryTemplateType == 1" class="date-input-container">
                                <input
                                    type="date"
                                    class="date-input"
                                    :class="[startDateIsDisabled ? 'disabled' : showTemporalTemplateError ? 'input-error' : 'enabled']"
                                    @input="(event) => handleStartDateInput(event.target.value)"
                                    :value="template.config.temporary && template.config.temporary.start_date ? template.config.temporary.start_date : ''"
                                    :min="currentDate"
                                    :disabled="startDateIsDisabled"
                                />
                            </div>

                            <!-- SEVERAL DAYS (desde - hasta) -->
                            <div
                                class="option"
                                :class="{ disabled: temporaryTemplatesRadioButtonsAreDisabled }"
                                @click="
                                    {
                                        if (temporaryTemplatesRadioButtonsAreDisabled) {
                                            return
                                        } else temporaryTemplateType = 2
                                    }
                                "
                            >
                                <v-radio class="radio" color="#157d82" :value="2" :disabled="temporaryTemplatesRadioButtonsAreDisabled"></v-radio>
                                <p class="label" :class="{ disabled: temporaryTemplatesRadioButtonsAreDisabled }">
                                    {{ $t('template.checklists.temporary_templates_config.radio_option_2') }}
                                </p>
                            </div>

                            <!-- two calendars -->
                            <div v-show="temporaryTemplateType == 2">
                                <!-- FROM / DESDE START DATE-->
                                <p class="calendar-label">{{ $t('assets.filter.from') }}:</p>

                                <div class="date-input-container">
                                    <input
                                        type="date"
                                        class="date-input"
                                        :class="[startDateIsDisabled ? 'disabled' : showTemporalTemplateError ? 'input-error' : 'enabled']"
                                        @input="(event) => handleStartDateInput(event.target.value)"
                                        :value="template.config.temporary && template.config.temporary.start_date ? template.config.temporary.start_date : ''"
                                        :max="template.config.temporary && template.config.temporary.end_date ? template.config.temporary.end_date : ''"
                                        :min="currentDate"
                                        :disabled="startDateIsDisabled"
                                    />
                                </div>

                                <!-- TO / HASTA END DATE -->
                                <p class="calendar-label">{{ $t('assets.filter.to') }}:</p>

                                <div class="date-input-container">
                                    <input
                                        type="date"
                                        class="date-input"
                                        :class="[endDateIsDisabled ? 'disabled' : showTemporalTemplateError ? 'input-error' : 'enabled']"
                                        @input="(event) => handleEndDateInput(event.target.value)"
                                        :value="template.config.temporary && template.config.temporary.end_date ? template.config.temporary.end_date : ''"
                                        :min="
                                            template.config.temporary && template.config.temporary.start_date
                                                ? template.config.temporary.start_date
                                                : currentDate
                                        "
                                        :max="endDateMaxDate"
                                        :disabled="endDateIsDisabled"
                                    />
                                </div>
                            </div>
                        </v-radio-group>
                    </div>
                </div>

                <!-- Screenlock CONFIG (ONLY SHOW IF IS CHECKLIST) -->
                <div v-if="template.type == 1 && template.config.temporary && template.config.temporary.start_date && screenlockFlag" class="config main active translate-disable screenlock">
                    <div class="name main check">
                        <div class="collapse" :class="{ collapsed: collapse.indexOf('screenlock.section') == -1 }"></div>
                        {{ $t('template.checklists.screenlock.title') }}
                        <input class="switch-input" type="checkbox" v-model="screenlock.section" :value="true" @click.stop @change="sectionScreenlockSelector()" />
                    </div>
                    <div v-if="screenlock.section" class="fullwidth">
                        <div class="description" v-html="$t('template.checklists.screenlock.description')"></div>
                        <TranslateInput gType="texteditor" v-model="screenlockMessage" :gState="screenlockMessage == '<p></p>' ? 'error' : ''" :gOptions="{ actions: ['text', 'italic','list'], limit: 1500 }" :inputIcon="false" customClass="communication"></TranslateInput>
                    </div>
                </div>

                <!-- TIMEFRAMES CONFIG (ONLY SHOW IF IS CHECKLIST) -->
                <div class="config main active translate-disable" v-if="template.type == 1">
                    <div class="name" v-html="$t('template.checklists.timeframe.title')"></div>
                    <div class="description" v-html="$t('template.checklists.timeframe.description')"></div>
                    <div class="timeframes">
                        <div
                            v-for="timeframe in timeframes"
                            class="timeframe"
                            :class="{ selected: template.timeframe_id == timeframe.id }"
                            @click="setTimeFrame(timeframe.id)"
                        >
                            <template v-if="timeframe.id == 0">
                                <div class="name allday">{{ timeframe.name }}</div>
                            </template>
                            <template v-else>
                                <div class="name">{{ timeframe.name }}</div>
                                <div class="hours">‎{{ formatRangeHour(timeframe) }}</div>
                            </template>
                        </div>
                    </div>
                </div>

                <!-- AVAILABILITY CONFIG (ONLY SHOW IF IS CHECKLIST) -->
                <div class="config main inline translate-hide" :class="{ active: availability }" v-if="template.type == 1">
                    <div class="name main check">
                        <div class="collapse" :class="{ collapsed: collapse.indexOf('availability') == -1 }"></div>
                        {{ $t('template.checklists.timeframe.title_availability') }}
                        <input class="switch-input" type="checkbox" v-model="availability" :value="true" @click.stop @change="availabilitySelector()" />
                    </div>
                    <div class="description" v-html="$t('template.checklists.timeframe.description_availability')"></div>
                    <div class="config-content" v-if="availability" :class="{ collapsed: collapse.indexOf('availability') > -1 }">
                        <div class="timeframes_hour">
                            <div
                                class="timeframe_hour"
                                :class="{ error: template.errors && template.errors.codes && template.errors.codes.includes('empty_start_hour') }"
                            >
                                <div class="label">{{ $t('supervise.checklists.table_headers.start_hour') }}</div>
                                <div class="hour-box-timeframe">
                                    <select class="mode" v-model="from_mode" @change="timeframeHoursFormat('start')">
                                        <template v-for="option in Object.values(optionsAvailabilityModes)">
                                            <option v-if="option.value !== false && option.value <= 0" :value="option.value" :key="option.value">
                                                {{ option.name }}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                                <div class="connector">{{ $t('template.checklists.timeframe.at_availability') }}</div>
                                <div class="hour-box custom-hours-selector">
                                    <!-- <input v-if="template.config" class="hour" type="time" step="3600" v-model="from_formatted" @input="timeframeHoursFormat('start', $event)" /> -->
                                    <div class="hours">
                                        <select class="mode" v-model="startHours" @blur="timeframeHoursFormat('start')">
                                            <option v-for="hour in availablesHours" :value="hour" :key="hour">{{ hour }}</option>
                                        </select>
                                    </div>
                                    <span>:</span>
                                    <div class="minutes">
                                        <select class="mode" v-model="startMinutes" @blur="timeframeHoursFormat('start')">
                                            <option v-for="minutes in availablesMinutes" :value="minutes">{{ minutes }}</option>
                                        </select>
                                    </div>
                                    <div class="clock-icon"></div>
                                </div>
                            </div>
                            <div
                                class="timeframe_hour"
                                :class="{ error: template.errors && template.errors.codes && template.errors.codes.includes('empty_end_hour') }"
                            >
                                <div class="label">{{ $t('supervise.checklists.table_headers.end_hour') }}</div>
                                <div class="hour-box-timeframe">
                                    <select class="mode" v-model="to_mode" @change="timeframeHoursFormat('end')">
                                        <template v-for="option in Object.values(optionsAvailabilityModes)">
                                            <option v-if="option.value !== false && option.value >= 0" :value="option.value" :key="option.value">
                                                {{ option.name }}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                                <div class="connector">{{ $t('template.checklists.timeframe.at_availability') }}</div>
                                <div class="hour-box custom-hours-selector">
                                    <!-- <input v-if="template.config" class="hour" type="time" step="900" v-model="to_formatted" @input="timeframeHoursFormat('end', $event)" /> -->
                                    <div class="hours">
                                        <select class="mode" v-model="endHours" @blur="timeframeHoursFormat('end')">
                                            <option v-for="hour in availablesHours" :value="hour" :key="hour">{{ hour }}</option>
                                        </select>
                                    </div>
                                    <span>:</span>
                                    <div class="minutes">
                                        <select class="mode" v-model="endMinutes" @blur="timeframeHoursFormat('end')">
                                            <option v-for="minutes in availablesMinutes" :value="minutes">{{ minutes }}</option>
                                        </select>
                                    </div>
                                    <div class="clock-icon"></div>
                                </div>
                            </div>
                            <div class="resume">
                                {{ $t('template.checklists.timeframe.resume_availability') }}
                                <b
                                    >{{
                                        $t(
                                            optionsAvailabilityModes[from_mode]
                                                ? optionsAvailabilityModes[from_mode].name
                                                : optionsAvailabilityModes['before'].name
                                        ).toLowerCase()
                                    }}
                                    {{ $t('template.checklists.timeframe.at_availability') }} {{ startHours }}:{{ startMinutes }} h</b
                                >
                                {{ $t('template.checklists.timeframe.until') }}
                                <b
                                    >{{
                                        $t(
                                            optionsAvailabilityModes[to_mode] ? optionsAvailabilityModes[to_mode].name : optionsAvailabilityModes['after'].name
                                        ).toLowerCase()
                                    }}
                                    {{ $t('template.checklists.timeframe.at_availability') }} {{ endHours }}:{{ endMinutes }} h</b
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <!-- AUDIT FINAL SCORES / NOTA FINAL (ONLY SHOW IF IS AUDIT) -->
                <div class="config main inline translate-hide" v-if="template.type == 2">
                    <div class="name main check">
                        {{ $t('template.audits.final_score.title') }}
                        <!-- <input class="switch-input" type="checkbox" v-model="showFinalScoresSection" @click.stop /> -->
                    </div>

                    <div class="description">{{ $t('template.audits.final_score.description') }}</div>

                    <FinalScores
                        :savedScores="template.config.eval ? template.config.eval : null"
                        :noSingleScoreError="noSingleScoreError"
                        @saveFinalScores="saveFinalScores"
                    />
                </div>

                <!-- COVER CONFIG (ONLY SHOW IF IS AUDIT) -->
                <div class="config main active translate-hide" v-if="template.type == 2">
                    <div class="name" v-html="$t('template.audits.cover.title')"></div>
                    <div class="description" v-html="$t('template.audits.cover.description')"></div>
                    <div class="config-cover">
                        <div class="cover">
                            <input type="file" id="cover" ref="cover_image" accept="image/*" @change="previewFiles($event, 'cover_image')" />
                            <div
                                v-if="template && (template.file || template.image)"
                                class="preview"
                                :style="{ backgroundImage: 'url(' + (template.file ? template.file : template.image) + ')' }"
                            ></div>

                            <div class="preview empty" v-else>
                                <div class="empty-image"></div>
                                <!-- <div class="empty-text">{{$t('template.audits.cover.upload')}}</div> -->
                            </div>
                        </div>
                        <!-- <div class="delete-cover" v-if="template && template.image" @click="template.image = false">{{$t('template.audits.cover.delete')}}</div> -->
                        <Button
                            v-if="template && template.image"
                            bType="edit"
                            bLabel="template.audits.cover.change"
                            :bCallback="
                                (callback = function () {
                                    $refs.cover_image.click()
                                })
                            "
                            customClass="btn-secondary btn-small buttons-cover"
                        ></Button>
                        <Button
                            v-else
                            bType="create"
                            :bCallback="
                                (callback = function () {
                                    $refs.cover_image.click()
                                })
                            "
                            bLabel="template.audits.cover.upload"
                            customClass="btn-small buttons-cover"
                        ></Button>
                        <Button
                            v-if="template && template.image"
                            bType="delete"
                            :bCallback="
                                (callback = function () {
                                    template.image = false
                                    template = template
                                })
                            "
                            bLabel="template.audits.cover.delete"
                            customClass="btn-secondary btn-small buttons-cover"
                        ></Button>
                    </div>
                </div>

                <!-- SHOW RESPONSES (ONLY SHOW IF IS AUDIT) -->
                <div class="config main active translate-hide" v-if="template.type == 2">
                    <div class="name" v-html="$t('template.audits.show_score.label')"></div>
                    <div class="description" v-html="$t('template.audits.show_score.description')"></div>
                    <Input
                        :key="template.config.show_score"
                        gType="select"
                        v-model="template.config.show_score"
                        :gOptions="{ multiple: false, returnObject: false, removable: false, mandatory: false, itemText: 'name', itemValue: 'value' }"
                        :gItems="optionsShowResponses"
                        @change="template = template"
                    ></Input>
                </div>

                <!-- TEMPLATE DEPENDENCY (only show for TASK and not temporary) -->
                <div class="config main translate-hide inline" v-if="template.type == 1 && !templateIsTemporal">
                    <div class="name main check" @click="dependency ? collapseSection('dependency') : true">
                        <div class="collapse" :class="{ collapsed: collapse.indexOf('dependency') == -1 }"></div>
                        {{ $t('template.checklists.dependency.title') }}
                        <input class="switch-input" type="checkbox" v-model="dependency" :value="true" @click.stop @change="dependencySelector()" />
                    </div>
                    <div class="config-content" :class="{ collapsed: !dependency || (dependency && collapse.indexOf('dependency') > -1) }">
                        <div class="description" v-html="$t('template.checklists.dependency.description')"></div>
                        <v-autocomplete
                            :key="template.config.dependency_tpl"
                            hide-details
                            class="select"
                            v-model="template.config.dependency_tpl"
                            :items="Object.values(templates)"
                            item-text="name"
                            item-value="id"
                            clearable
                            attach
                            dense
                            :no-data-text="$t('supervise.filter.no_results')"
                            @change="template = template"
                        >
                        </v-autocomplete>
                        <div
                            v-if="templates && template.config.dependency_tpl && templates[template.config.dependency_tpl]"
                            class="resume main"
                            v-html="$t('template.checklists.dependency.resume', { s: templates[template.config.dependency_tpl].name })"
                        ></div>
                    </div>
                </div>

                <!-- CATEGORY OF REGISTER (ONLY SHOW IF IS REGISTER OR TASK OR ISSUES) -->
                <div
                    class="config main inline translate-hide"
                    :class="{ active: category }"
                    v-if="template.type === 1 || template.type === 3"
                >
                    <div class="name main check">
                        <div class="collapse" :class="{ collapsed: collapse.indexOf('category') == -1 }"></div>
                        {{ $t('template.records.category.title') }}
                        <input class="switch-input" type="checkbox" v-model="category" :value="true" @click.stop @change="categorySelector()" />
                    </div>
                    <div class="description" v-html="$t('template.records.category.description')"></div>
                    <v-autocomplete
                        v-if="category"
                        :key="template.category_id"
                        hide-details
                        class="select"
                        v-model="template.category_id"
                        :items="Object.values(categories)"
                        item-text="name"
                        item-value="id"
                        clearable
                        attach
                        dense
                        :no-data-text="$t('supervise.filter.no_results')"
                        @change="template = template"
                    >
                    </v-autocomplete>
                </div>

                <!-- ROLES (PERMISOS DE GESTIÓN)-->
                <div class="config main inline translate-hide" :class="{ active: sectionRoles }" v-if="[1, 2, 3, 4].includes(template.type)">
                    <div class="name main check">
                        <div class="collapse" :class="{ collapsed: collapse.indexOf('sectionRoles') == -1 }"></div>
                        {{ $t('template.general.role_powers') }}
                        <input class="switch-input" type="checkbox" v-model="sectionRoles" :value="true" @click.stop @change="sectionRolesSelector()" />
                    </div>

                    <div class="description" v-html="$t('template.general.role_powers_description')"></div>
                    <div class="roles-description" :class="{ audit: [2].includes(template.type) }" v-if="sectionRoles">
                        <span>{{ $t('template.general.role_read') }}</span>
                        <span>{{ $t('template.general.role_write') }}</span>
                        <span v-if="[3].includes(template.type)">{{ $t('template.general.role_manage') }}</span>
                        <span v-if="[2].includes(template.type)">{{ $t('template.general.role_validate') }}</span>
                    </div>
                    <div class="role" v-if="sectionRoles">
                        <template v-for="(role, index) in rolesMap">
                            <roleField
                                :roles="roles"
                                :template="template"
                                @deleteRole="deleteRole"
                                :role="role"
                                :index="index"
                                :otherInputsValuesValidation="isRolRepeated()"
                            ></roleField>
                        </template>

                        <Button
                            bType="create"
                            bLabel="template.general.types.selector.add_option"
                            customClass="white"
                            :bCallback="addNewInputRol"
                            v-if="hasAllRolValues()"
                        ></Button>
                    </div>
                </div>

                <!-- (ISSUES ONLY) NOTIFY EMAIL WHEN CREATE/CLOSE STATUS -->
                <template v-if="[4].includes(template.type)">
                    <NotifyEmail :context="{ template_id: context.template_id }"></NotifyEmail>
                </template>

                <!-- SITE/GROUP LIMIT ( UBICACIONES )-->
                <div class="config main inline sitelimit translate-hide" :class="{ active: sitelimit }">
                    <div class="name main check">
                        <div class="collapse" :class="{ collapsed: collapse.indexOf('sitelimit') == -1 }"></div>
                        {{ $t('template.general.site_limit.title') }}
                        <input class="switch-input" type="checkbox" v-model="sitelimit" :value="true" @click.stop @change="siteLimitSelector()" />
                    </div>
                    <div class="config-content" :class="{ collapsed: collapse.indexOf('sitelimit') > -1 }">
                        <div class="description" v-if="sitelimit" v-html="$t('template.general.site_limit.description_template')"></div>
                        <div class="description" v-else v-html="$t('template.general.site_limit.description_template_unlimited')"></div>
                        <div class="error-msg" v-if="template.errors && template.errors.codes && template.errors.codes.includes('empty_sitelimit')">
                            {{ $t('template.general.site_limit.error') }}
                        </div>
                        <SiteLimit
                            v-if="sitelimit"
                            @setSiteLimit="setSiteLimit"
                            :locations="template.locations"
                            :groups="template.groups"
                            :definedMode="limitmode ? limitmode : ''"
                        ></SiteLimit>
                        <div
                            class="must"
                            :class="{ error: template.errors && template.errors.codes && template.errors.codes.includes('empty_sitelimit') }"
                            v-if="sitelimit"
                            v-html="$t('template.general.site_limit.must_description')"
                        ></div>
                    </div>
                </div>

                <!-- TAGS -->
                <Tags :context="{ template_id: context.template_id }"></Tags>
            </div>
        </div>
    </div>
</template>

<script>
import SiteLimit from '@/components/ui/SiteLimit'
import NotifyEmail from '@/components/domain/templates/notifyEmail/NotifyEmail'
import Tags from '@/components/domain/templates/tags/Tags'
import FinalScores from '@/components/domain/templates/finalScores/FinalScores'
import roleField from './config/roleField.vue'

export default {
    components: {
        SiteLimit,
        NotifyEmail,
        Tags,
        FinalScores,
        roleField
    },
    name: 'TemplateConfig',
    props: {
        context: { type: Object, default: {} },
        templateIsTemporal: { type: Boolean, default: false }
    },
    data() {
        return {
            collapse: [],
            sitelimit: false,
            availability: false,
            category: false,
            sectionRoles: false,
            screenlockMessage: '<p></p>',
            screenlock: {
                section: false,
                selectionStart: 0,
                selectionEnd: 0,
            },
            dependency: false,
            limitmode: '',
            oldStatus: '',
            position: false,
            dateMax: moment().add(30, 'days').format('YYYY-MM-DD'), //30 days after
            dateMin: moment().add(1, 'days').format('YYYY-MM-DD'), //tomorrow,
            dateCount: 0,
            oldDate: false,
            errorDate: false,

            from_formatted: '',
            from_mode: 0,
            to_formatted: '',
            to_mode: 0,optionsAvailabilityModes: {
                0: { name: this.$t('template.checklists.timeframe.availability_mode.same_day'), value: 0 },
                '-24': { name: this.$t('template.checklists.timeframe.availability_mode.yesterday'), value: -24 },
                24: { name: this.$t('template.checklists.timeframe.availability_mode.next_day'), value: 24 },
                '-48': { name: this.$t('template.checklists.timeframe.availability_mode.2_before_day'), value: -48 },
                48: { name: this.$t('template.checklists.timeframe.availability_mode.2_next_day'), value: 48 },
                '-72': { name: this.$t('template.checklists.timeframe.availability_mode.3_before_day'), value: -72 },
                72: { name: this.$t('template.checklists.timeframe.availability_mode.3_next_day'), value: 72 },
                '-168': { name: this.$t('template.checklists.timeframe.availability_mode.last_week'), value: -168 },
                168: { name: this.$t('template.checklists.timeframe.availability_mode.next_week'), value: 168 },
                '-720': { name: this.$t('template.checklists.timeframe.availability_mode.last_month'), value: -720 },
                720: { name: this.$t('template.checklists.timeframe.availability_mode.next_month'), value: 720 },
                before: { name: this.$t('template.checklists.timeframe.availability_mode.personalized_before_day'), value: false },
                after: { name: this.$t('template.checklists.timeframe.availability_mode.personalized_next_day'), value: false },
            },
            availablesHours: Array.from({ length: 24 }, (v, k) => k)
                .map((item) => {
                    if (item == 24) return '00'

                    return item < 10 ? '0' + item : item
                })
                .sort(),
            availablesMinutes: ['00', '15', '30', '45'],
            startHours: '00',
            startMinutes: '00',
            endHours: '00',
            endMinutes: '00',
            // showFinalScoresSection: true
            rolesMap: [{ roleId: '', permissions: 'r' }],

            temporaryTemplateType: 1, // 1 = one day / 2 = several days,
            temporaryTemplateMountedStartDate: null,
            temporaryTemplateMountedEndDate: null
        }
    },
    computed: {
        template: {
            get() {
                var template = { ...this.$store.getters['template/getTemplate'](this.context.template_id) }
                if (
                    (template && ((template.groups && template.groups.length > 0) || (template.locations && template.locations.length > 0))) ||
                    this.sitelimit
                ) {
                    this.sitelimit = true
                } else this.sitelimit = false

                return template
            },
            set(value) {
                this.$store.commit('template/updateTemplate', { context: this.context, template: value })
            }
        },

        timeframes() {
            return this.$store.getters['template/getFilterTimeframe']()
        },

        roles() {
            return this.$store.getters['employee/getSameLowerLevelRoleUser']
        },

        selectedContext() {
            // GET ACTIVE SELECTED ITEM
            return this.$store.getters['template/getSelectedContext']
        },

        optionsStatus() {
            const status = [
                { name: this.$t('template.general.status.template_published'), value: '1' },
                { name: this.$t('template.general.status.template_draft'), value: '-2' }
            ]

            if (this.$route.params.id) {
                status.push({ name: this.$t('template.general.status.template_ended'), value: '-3' })
            }

            return status
        },

        templates() {
            var oTemplates = { ...this.$store.getters['tasks/getFilterTemplate']() }
            if (oTemplates) {
                delete oTemplates[this.template.id]
                return oTemplates
            } else return {}
        },

        optionsShowResponses() {
            return [
                { name: this.$t('template.audits.show_score.at_the_moment'), value: '1' },
                { name: this.$t('template.audits.show_score.at_finish'), value: '0' }
            ]
        },

        categories() {
            return this.$store.getters['template/getCategories']()
        },

        noSingleScoreError() {
            return this.template.errors && this.template.errors.codes && this.template.errors.codes.includes('no-single-final-score')
        },

        showTemporalTemplateError() {
            if (!this.template || !this.template.config.temporary) {
                return false
            }

            const startDateIsBeforeCurrentDate = this.dateIsBeforeCurrentDate(this.template.config.temporary.start_date)

            const startDateIsInvalid = !this.template.config.temporary.start_date || startDateIsBeforeCurrentDate
            const endDateIsInvalid = this.endDateIsBeforeCurrentDate || this.endDateIsLaterThanMaxDate

            // template.status -3 = Finalizada (mostrar errores solo en Publicadas y Borrador)
            if (!this.startDateIsDisabled && this.template.status != -3 && startDateIsInvalid) {
                return true
            } else if (this.temporaryTemplateType == 2) {
                if (!this.endDateIsDisabled && endDateIsInvalid) {
                    return true
                } else if (
                    this.template.errors &&
                    this.template.errors.codes &&
                    this.template.errors.codes.includes('no-end-date') &&
                    !this.template.config.temporary.end_date
                ) {
                    return true
                }
            } else return false
        },

        // for temporal templates with a range of days:
        endDateMaxDate() {
            if (this.template.config && this.template.config.temporary && this.template.config.temporary.start_date) {
                const startDate = new Date(this.template.config.temporary.start_date)

                let year = startDate.getFullYear()
                let nextMonth = startDate.getMonth() + 1

                if (nextMonth > 11) {
                    year++
                    nextMonth -= 12
                }

                const lastDayOfNextMonth = new Date(year, nextMonth + 1, 0)
                const formattedDate = lastDayOfNextMonth.toISOString().split('T')[0]
                let formattedDateObj = new Date(formattedDate)
                formattedDateObj.setDate(formattedDateObj.getDate() + 1)

                const maxDate = formattedDateObj.toISOString().split('T')[0]

                return maxDate
            } else return ''
        },

        currentDate() {
            const today = new Date()
            const year = today.getFullYear()
            const month = (today.getMonth() + 1).toString().padStart(2, '0')
            const day = today.getDate().toString().padStart(2, '0')

            return `${year}-${month}-${day}`
        },

        endDateIsLaterThanMaxDate() {
            if (this.template.config.temporary && this.template.config.temporary.end_date) {
                const endDate = new Date(this.template.config.temporary.end_date)
                const maxDate = new Date(this.endDateMaxDate)

                if (endDate > maxDate) {
                    return true
                } else return false
            }
        },
        endDateIsBeforeCurrentDate() {
            if (this.template.config.temporary.end_date) {
                const endDateIsBeforeCurrentDate = this.dateIsBeforeCurrentDate(this.template.config.temporary.end_date)
                if (endDateIsBeforeCurrentDate) {
                    return true
                } else return false
            }
        },
        endDateIsDisabled() {
            if (this.startDateIsDisabled && !this.template.config.temporary.end_date) {
                return true
            } else if (this.startDateIsDisabled && this.endDateIsBeforeCurrentDate) {
                return true
            } else return false
        },
        temporaryTemplatesRadioButtonsAreDisabled() {
            // template.status -2 = Borrador
            if (!this.temporaryTemplateMountedStartDate || this.template.status == -2) {
                return false
            }

            const startDate = this.temporaryTemplateMountedStartDate
            const startDateIsBeforeCurrentDate = this.dateIsBeforeCurrentDate(startDate)

            if (this.temporaryTemplateType == 1) {
                // 1 = one specific day
                if (startDateIsBeforeCurrentDate && !this.temporaryTemplateMountedEndDate) {
                    return true
                } else return false
            }

            if (this.temporaryTemplateType == 2) {
                // 2 = range of days
                const endDate = this.temporaryTemplateMountedEndDate

                if (endDate && this.dateIsBeforeCurrentDate(endDate)) {
                    return true
                } else return false
            }
        },
        startDateIsDisabled() {
            if (!this.temporaryTemplateMountedStartDate) {
                return false
            }

            const startDateIsBeforeCurrentDate = this.dateIsBeforeCurrentDate(this.temporaryTemplateMountedStartDate)

            if (this.template.status !== '-2') {
                if (this.template.id !== '-1' && startDateIsBeforeCurrentDate) {
                    return true
                } else {
                    return false
                }
            }
        },
        screenlockFlag() {
            const flag = parseInt(this.$store.getters['account/getScreenlockFlag'])

            if (flag === 1) {
                return true
            } else return false
        }
    },
    methods: {
        addNewInputRol() {
            this.rolesMap = [...this.rolesMap, { roleId: '', permissions: 'r' }]
        },
        deleteRole(rol) {
            //rol = {index: this.index, role: this.role}
            this.rolesMap.splice(rol.index, 1)
        },
        isRolRepeated() {
            if (this.rolesMap.length > 1) {
                const isRolRepeated = this.rolesMap.some((rol, index) => {
                    const rolId = rol.roleId
                    const rolIndex = this.rolesMap.findIndex((rol, index) => rol.roleId === rolId)
                    return rolIndex !== index
                })

                if (isRolRepeated) {
                    return false
                }
            }

            return true
        },
        hasAllRolValues() {
            const hasAllRolValues = Object.values(this.rolesMap).every((rol) => {
                return rol.roleId !== ''
            })

            return hasAllRolValues
        },
        load() {
            var roles = this.roles
            var self = this

            if (Object.values(roles).length == 0) {
                this.$store.dispatch('employee/loadLevelTwoOrMoreRoles', {})
            }

            if (this.template) {
                this.$store.dispatch('template/loadCategories', { type: self.template.type })
                if (this.template.type == 2) {
                    this.template.config.show_score = this.template.config && this.template.config.show_score ? this.template.config.show_score : '1'
                }
            }

            if (this.template && (this.template.start_hour || this.template.end_hour)) {
                this.formatHours(this.template)
                this.availability = true
            }

            if (this.template && this.template.category_id) {
                this.category = true
            }

            if (this.template && this.template.config && this.template.config.nRoles) {
                this.sectionRoles = true
            } else if (this.template && this.template.config && this.template.config.roles) {
                this.sectionRoles = true
            }

            if (this.template.type == 1 && Object.values(this.templates).length == 0) {
                this.$store.dispatch('tasks/loadFilters', {})
            }

            if (this.template && this.template.config && this.template.config.dependency_tpl) {
                this.dependency = true
            }

            if (this.template && this.template.scheduled_date) {
                this.position = 2
            } else {
                this.position = 1
            }

            //Load roles
            if (this.template && this.template.config && this.template.config.nRoles && this.template.config.nRoles.length > 0) {
                this.rolesMap = this.template.config.nRoles.map((role) => {
                    return { roleId: role.roleId, permissions: role.permissions }
                })
            } else if (
                this.template &&
                this.template.config &&
                this.template.config.roles &&
                !this.template.config.nRoles &&
                this.template.config.roles.length > 0
            ) {
                this.rolesMap = this.template.config.roles.map((role) => {
                    return { roleId: role, permissions: 'rw' }
                })
            }
        },
        descriptionOfStatus() {
            const status = {
                1: this.$t('template.general.status.note_published'),
                '-2': this.$t('template.general.status.note_draft'),
                '-3': this.$t('template.general.status.note_ended')
            }

            return status[this.template.status]
        },
        titleOfActualStatus() {
            const status = {
                1: this.$t('template.general.status.template_published'),
                '-2': this.$t('template.general.status.template_draft'),
                '-3': this.$t('template.general.status.template_ended')
            }

            return status[this.oldStatus]
        },
        setTimeFrame(timeframe_id) {
            var template = { ...this.template }
            template.timeframe_id = timeframe_id

            return this.$store.commit('template/setTemplate', { template: template })
        },

        // GENERAL FORM METHODS
        unselect() {
            this.$store.commit('template/setSelectedContext', false)
        },

        collapseSection(section) {
            var aCollapse = this.collapse
            if (aCollapse.indexOf(section) > -1) {
                aCollapse.splice(aCollapse.indexOf(section), 1)
            } else {
                aCollapse.push(section)
            }
        },

        // TIMEFRAMES METHODS
        formatRangeHour(item) {
            if (item.start_hour !== false && item.end_hour !== false) {
                return timeFormat(item.start_hour) + ' ' + this.$tc('assets.timing.to') + ' ' + timeFormat(item.end_hour)
            } else return '-'
        },

        // AVAILABILITY METHODS
        /* timeframeHoursFormat(type, event = false) {
            var value = event ? event.target.value : type == 'start' ? this.from_formatted : this.to_formatted
            var hour = parseInt(value.split(':')[0])
            var minutes = value.split(':')[1] ? parseFloat(value.split(':')[1]) / 60 : 0

            if (minutes > 0.1 || value.split(':')[1] == '00') {
                if (minutes > 0.25 && minutes < 0.7) {
                    var minutesParsed = 0.5
                    var minutesFormatted = '30'
                } else if (minutes >= 0.7 && minutes < 0.9) {
                    var minutesParsed = 0.75
                    var minutesFormatted = '45'
                } else if (minutes >= 0.9) {
                    var minutesParsed = 0.0
                    var minutesFormatted = '00'
                    var hour = parseInt(hour) + 1
                } else if (minutes > 0.1) {
                    var minutesParsed = 0.25
                    var minutesFormatted = '15'
                } else {
                    var minutesParsed = 0.0
                    var minutesFormatted = '00'
                }

                if (type == 'start') {
                    this.from_formatted = (hour.toString().length < 2 ? '0' + hour : hour) + ':' + minutesFormatted
                    this.template.start_hour = hour + minutesParsed
                } else {
                    this.to_formatted = (hour.toString().length < 2 ? '0' + hour : hour) + ':' + minutesFormatted
                    this.template.end_hour = hour + minutesParsed
                }

                if (this.from_mode == 24 || (parseInt(this.from_formatted.split(':')[0]) >= parseInt(this.to_formatted.split(':')[0]) && parseInt(this.to_formatted.split(':')[0])) >= 24) {
                    this.to_mode = 24
                }

                if (type == 'start') {
                    this.template.start_hour = this.from_mode < 0 && this.template.start_hour < 24 ? parseInt(this.from_mode) + parseInt(this.template.start_hour) : this.template.start_hour
                } else {
                    this.template.end_hour = this.to_mode > 0 && this.template.end_hour < 24 ? parseInt(this.to_mode) + parseInt(this.template.end_hour) : this.template.end_hour
                }
            }

            this.template = this.template
        }, */
        timeframeHoursFormat(type) {
            const timeToParse = type == 'start' ? { hour: this.startHours, minutes: this.startMinutes } : { hour: this.endHours, minutes: this.endMinutes }

            const hour = parseInt(timeToParse.hour)
            const minutes = parseFloat(timeToParse.minutes) / 60 || 0

            if (minutes > 0.1 || timeToParse.minutes == '00') {
                let minutesParsed = 0.0
                if (minutes > 0.25 && minutes < 0.7) {
                    minutesParsed = 0.5
                } else if (minutes >= 0.7 && minutes < 0.9) {
                    minutesParsed = 0.75
                } else if (minutes >= 0.9) {
                    minutesParsed = 0.0
                    hourParsed = parseInt(hour) + 1
                } else if (minutes > 0.1) {
                    minutesParsed = 0.25
                } else {
                    minutesParsed = 0.0
                }
                const finalHour = hour + minutesParsed

                if (type == 'start') {
                    this.template.start_hour = parseFloat(this.from_mode) < 0 ? parseFloat(this.from_mode) - parseFloat(finalHour) : finalHour
                } else {
                    this.template.end_hour = parseFloat(this.to_mode) > 0 ? parseFloat(this.to_mode) + parseFloat(finalHour) : finalHour
                }
            }

            this.template = this.template
        },

        availabilitySelector() {
            var template = { ...this.template }
            var aCollapse = this.collapse
            aCollapse.splice(aCollapse.indexOf('availability'), 1)

            if (this.availability == false) {
                template.start_hour = null
                template.end_hour = null
                this.template = template
            } else {
                this.formatHours(template)
            }
        },

        categorySelector() {
            var template = { ...this.template }
            var aCollapse = this.collapse
            aCollapse.splice(aCollapse.indexOf('category'), 1)

            if (this.category == false) {
                template.category_id = false
                this.template = template
            }
        },

        sectionRolesSelector() {
            var template = { ...this.template }
            var aCollapse = this.collapse
            aCollapse.splice(aCollapse.indexOf('sectionRoles'), 1)

            if (this.sectionRoles == false) {
                template.config.roles = false
                template.config.nRoles = false
                // this.rolesMap = false
                this.template = template
            }
        },

        sectionScreenlockSelector() {
            var template = { ...this.template }
            var aCollapse = this.collapse
            aCollapse.splice(aCollapse.indexOf('sectionSceenLock'), 1)

            if (this.screenlock.section == false) {
                template.config.screenlock = false
                template.config.screenlockMessage = undefined
                this.template = template
            }else{
                template.config.screenlock = true
                template.config.screenlockMessage = this.screenlockMessage
            }
        },

        dependencySelector() {
            var template = { ...this.template }
            var aCollapse = this.collapse

            if (this.dependency == false) {
                template.config.dependency_tpl = false
                aCollapse.splice(aCollapse.indexOf('dependency'), 1)
                this.template = template
            }
        },

        formatHours(tpl) {
            var template = { ...tpl }
            template.start_hour = tpl.start_hour && tpl.start_hour != null ? tpl.start_hour : this.timeframes[tpl.timeframe_id].start_hour
            template.end_hour = tpl.end_hour && tpl.end_hour != null ? tpl.end_hour : this.timeframes[tpl.timeframe_id].end_hour

            this.from_formatted = moment()
                .startOf('day')
                .add(parseFloat(template.start_hour % 24) * 60, 'minutes')
                .format('HH:mm')

            this.to_formatted = moment()
                .startOf('day')
                .add(parseFloat(template.end_hour % 24) * 60, 'minutes')
                .format('HH:mm')

            this.from_mode = parseInt('-' + Math.floor(Math.abs(template.start_hour) / 24) * 24)
            this.to_mode = Math.floor(Math.abs(template.end_hour) / 24) * 24

            if (!this.optionsAvailabilityModes[this.from_mode]) {
                this.optionsAvailabilityModes['before'].value = this.from_mode
                const days = Math.abs(this.from_mode / 24)
                this.optionsAvailabilityModes['before'].name = this.$t('template.checklists.timeframe.availability_mode.personalized_before_day', {
                    numberDays: days
                })
            }
            if (!this.optionsAvailabilityModes[this.to_mode]) {
                this.optionsAvailabilityModes['after'].value = this.to_mode
                this.optionsAvailabilityModes['after'].name = this.$t('template.checklists.timeframe.availability_mode.personalized_next_day', {
                    numberDays: this.to_mode / 24
                })
            }

            // if (this.from_mode == 24 || parseInt(this.from_formatted.split(':')[0]) >= parseInt(this.to_formatted.split(':')[0]) || parseInt(this.to_formatted.split(':')[0]) >= 24) {
            //     this.to_mode = 24
            // } else if (!tpl.end_hour) {
            //     this.to_mode = 0
            // }

            // if (!tpl.start_hour && this.timeframes[tpl.timeframe_id].start_hour < 24) {
            //     this.from_mode = 0
            // } else if (!tpl.start_hour) {
            //     this.from_mode = 24
            // }

            this.template = template

            // return this.$store.commit('template/setTemplate', { template: template })
        },

        // COVER IMAGE METHODS
        previewFiles(e, name) {
            e.srcElement.nextSibling.src = '/img/file.svg'
            this.template.image = this.$refs[name].files[0].name
            // this.$overlay.loading();
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            this.createImage(files[0], name)
        },
        createImage(file, name) {
            // CREATION THUMB
            var image = new Image()
            var reader = new FileReader()
            var self = this

            reader.onload = (e) => {
                self.template.image = e.target.result
                self.template.file = e.target.result
                self.template = { ...self.template }
            }
            reader.readAsDataURL(file)
        },

        // SITE/GROUP LIMIT
        siteLimitSelector() {
            var template = { ...this.template }
            var aCollapse = this.collapse
            aCollapse.splice(aCollapse.indexOf('sitelimit'), 1)

            if (this.sitelimit == false) {
                template.groups = ''
                template.locations = ''
                this.limitmode = ''
            }

            this.template = template
        },
        setSiteLimit(data) {
            var template = { ...this.template }

            template.groups = data.groups
            template.locations = data.locations
            this.limitmode = data.mode
            if (data.mode) {
                this.sitelimit = true
            }

            this.template = template
        },
        parseDate(date) {
            return moment(date).format('DD/MM/YYYY')
        },
        checkLimitsDate() {
            if (this.template.scheduled_date) {
                if (this.template.scheduled_date < this.dateMin) {
                    this.template.scheduled_date = moment(this.dateMin).format('YYYY-MM-DD')
                    this.dateCount++
                } else if (this.template.scheduled_date > this.dateMax) {
                    this.template.scheduled_date = moment(this.dateMax).format('YYYY-MM-DD')
                    this.dateCount++
                }
            }

            this.$store.commit('template/setTemplate', { template: this.template })
        },

        startValuesOfDate() {
            this.template.scheduled_date = this.template.scheduled_date ? this.template.scheduled_date : this.dateMin
            this.$store.commit('template/setTemplate', { template: this.template })
        },
        resetValuesOfDate() {
            this.template.scheduled_date = null
            this.$store.commit('template/setTemplate', { template: this.template })
        },
        loadLimitHourValues() {
            if (this.template.start_hour) {
                // 00, -24, -48, -72
                if (this.template.start_hour <= 0 && this.template.start_hour > -24) {
                    this.from_mode = this.optionsAvailabilityModes[0].value
                } else if (this.template.start_hour <= -24 && this.template.start_hour > -48) {
                    this.from_mode = this.optionsAvailabilityModes[-24].value
                } else if (this.template.start_hour <= -48 && this.template.start_hour > -72) {
                    this.from_mode = this.optionsAvailabilityModes[-48].value
                } else if (this.template.start_hour <= -72 && this.template.start_hour > -168) {
                    this.from_mode = this.optionsAvailabilityModes[-72].value
                } else if (this.template.start_hour <= -168 && this.template.start_hour > -720) {
                    this.from_mode = this.optionsAvailabilityModes[-168].value
                } else if (this.template.start_hour <= -720) {
                    this.from_mode = this.optionsAvailabilityModes[-720].value
                }

                if (this.template.start_hour == 0 || this.template.start_hour == -24 || this.template.start_hour == -48 || this.template.start_hour == -72) {
                    this.startHours = '00'
                    this.startMinutes = '00'
                } else {
                    this.startHours = parseInt(this.template.start_hour - this.from_mode)
                    this.startHours = this.startHours > 0 ? this.startHours : this.startHours * -1
                    this.startHours = this.startHours < 10 ? '0' + this.startHours : this.startHours

                    this.startMinutes = ((this.template.start_hour - this.from_mode) % 1) * 60
                    this.startMinutes = this.startMinutes > 0 ? this.startMinutes : this.startMinutes * -1
                    this.startMinutes = this.startMinutes < 10 ? '0' + this.startMinutes : this.startMinutes
                }
            }

            if (this.template.end_hour) {
                // 00, 24, 48, 72
                if (this.template.end_hour >= 0 && this.template.end_hour < 24) {
                    this.to_mode = this.optionsAvailabilityModes[0].value
                } else if (this.template.end_hour >= 24 && this.template.end_hour < 48) {
                    this.to_mode = this.optionsAvailabilityModes[24].value
                } else if (this.template.end_hour >= 48 && this.template.end_hour < 72) {
                    this.to_mode = this.optionsAvailabilityModes[48].value
                } else if (this.template.end_hour >= 72 && this.template.end_hour < 168) {
                    this.to_mode = this.optionsAvailabilityModes[72].value
                } else if (this.template.end_hour >= 168 && this.template.end_hour < 720) {
                    this.to_mode = this.optionsAvailabilityModes[168].value
                } else if (this.template.end_hour >= 720) {
                    this.to_mode = this.optionsAvailabilityModes[720].value
                }

                if (this.template.end_hour == 0 || this.template.end_hour == 24 || this.template.end_hour == 48 || this.template.end_hour == 72) {
                    this.endHours = '00'
                    this.endMinutes = '00'
                } else {
                    this.endHours = parseInt(this.to_mode - this.template.end_hour)
                    this.endHours = this.endHours > 0 ? this.endHours : this.endHours * -1
                    this.endHours = this.endHours < 10 ? '0' + this.endHours : this.endHours

                    this.endMinutes = ((this.to_mode - this.template.end_hour) % 1) * 60
                    this.endMinutes = this.endMinutes > 0 ? this.endMinutes : this.endMinutes * -1
                    this.endMinutes = this.endMinutes < 10 ? '0' + this.endMinutes : this.endMinutes
                }
            }
        },

        saveFinalScores(finalScores) {
            this.template.config.eval = finalScores

            this.$store.commit('template/setTemplate', { template: this.template })
        },

        setTemporaryTemplateType() {
            if (this.template.config && this.template.config.hasOwnProperty('temporary')) {
                if (this.template.config.temporary.end_date) {
                    this.temporaryTemplateType = 2
                } else this.temporaryTemplateType = 1
            }
        },

        dateIsBeforeCurrentDate(value) {
            if (!value || value.length === 0) {
                return false
            }

            const currentDate = new Date()
            const currentDateWithoutTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())

            const date = new Date(value)
            const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate())

            if (dateWithoutTime < currentDateWithoutTime) {
                return true
            } else {
                return false
            }
        },

        handleStartDateInput(value) {
            const dateIsBeforeCurrentDate = this.dateIsBeforeCurrentDate(value)

            if (value.length === 0 || dateIsBeforeCurrentDate) {
                this.template.config.temporary.start_date = value // to show error message
            } else {
                this.template.config.temporary.start_date = value
            }

            if (this.temporaryTemplateType == 1) {
                this.template.config.temporary.end_date = null
            }
        },

        handleEndDateInput(value) {
            this.template.config.temporary.end_date = value
        },

        handleTemporaryTemplateMountedDates() {
            if (!this.template.config.temporary) {
                return
            }

            if (this.template.config.temporary.start_date) {
                this.temporaryTemplateMountedStartDate = this.template.config.temporary.start_date
            }

            this.temporaryTemplateMountedEndDate = this.template.config.temporary.end_date
        }


    },
    watch: {
        rolesMap: {
            handler: function (val) {
                this.template.config.nRoles = val
            },
            deep: true
        },
        'screenlockMessage'(){
            var template = { ...this.template }
            template.config.screenlockMessage = this.screenlockMessage
        },
        temporaryTemplateType(value) {
            this.$store.commit('template/setTemporaryTemplateType', value)
        },

        startDateIsDisabled(value) {
            this.$store.commit('template/setTemporalTemplateStartDateIsDisabled', value)
        }
    },
    created() {
        this.load()
        this.oldStatus = this.template.status
        if (this.template.scheduled_date) {
            this.oldDate = this.parseDate(this.template.scheduled_date)
        }
        if (this.template.config && this.template.config.screenlock) {
            this.screenlock.section = true;
            this.screenlockMessage = this.template.config.screenlockMessage;
        }
        this.loadLimitHourValues()
        this.setTemporaryTemplateType()
    },
    mounted() {
        this.handleTemporaryTemplateMountedDates()
    },
    beforeDestroy() {
        this.$store.commit('template/setNewIsTemporal', false)
        this.$store.commit('template/setTemporaryTemplateType', 1)
        this.temporaryTemplateMountedStartDate = null
        this.$store.commit('template/setTemporalTemplateStartDateIsDisabled', false)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.v-input__icon--clear {
    button {
        background-color: transparent;
    }
}
.sidebar-right {
    $main-title-height: 30px;

    @include border-radius(4px);
    @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-200, 1));
    position: relative;
    overflow-y: scroll;
    width: 400px;
    height: calc(100% - #{$menu-top-height} - #{$menu-aux-height});
    padding: 15px 10px;
    transition: 0.2s;
    transition-timing-function: ease-in-out;

    > .name {
        // height: $main-title-height;
        padding: 10px;
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
        margin-top: -10px;
        text-transform: uppercase;
        color: #333;
        @include font-size(s, s, true);
    }

    .configs {
        overflow: auto;
        height: 100%;
        max-height: calc(100% - #{$main-title-height});
        padding-bottom: 105px;
        padding-right: $scroll-width;

        .error-msg {
            @include font-size(xs);
            @include background($image: img('error2_error_dark.svg'), $position: left 11px center, $size: 15px);
            background-color: $color-error-100;
            $color: $color-neutral-600;
            padding: 5px;
            padding-left: 35px;
            border-radius: 6px;
            width: 100%;
            margin: 3px auto;
        }
    }

    &.template-config {
        background-color: $color-neutral-50;
        background-color: #fff;
        position: fixed;
        right: 0;
        @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-300, 1));

        &.hide {
            right: -40px;
        }
    }

    .name {
        @include display-flex();
        @include justify-content();
        @include font-size(sm, $important: true);
        font-family: $text;
        color: $color-black;
        flex-direction: row;
        flex-wrap: wrap;

        .switch-input {
            right: 10px;
            top: 7.5px;
            position: absolute;
            background-color: $color-neutral-600;
            border-color: $color-neutral-600;
        }

        .switch-input:checked {
            background-color: $color-primary-500;
            border-color: $color-primary-500;
        }
    }

    .separator {
        width: 100%;
        border-bottom: 0px solid $color-secondary-100;
        height: 1px;
        display: block;
    }

    .config {
        @include display-flex();
        @include flex-direction(column);
        @include align-items('flex-start');
        margin: 0px 0;
        padding: 10px;
        padding-top: 0;

        // border: 1px solid #eee;
        border-radius: 3px;
        border-left: 5px solid transparent;
        padding-left: 6px;
        background: #fff;

        .config {
            border-left: none;
        }

        .select,
        .mode,
        .hour {
            background: #fff !important;
            border-color: #ddd !important;
        }
        &.active {
            border-left-color: $color-secondary-100;
        }

        &.main:last-child.sitelimit {
            margin-bottom: 100px !important;
        }

        .collapse {
            @include background($size: 10px, $image: img('up_main_s40.svg'), $position: center center);
            display: inline-block;
            width: 10px;
            height: 10px;
            transition: 0.3s;
            cursor: pointer;
            padding: 9px;
            margin: 0;
            display: none;

            &.collapsed {
                transform: rotate(-180deg);
            }
        }

        .config-content {
            height: fit-content;
            transition: 0.2s;
            transition-timing-function: ease-in-out;
            overflow: initial;

            &.collapsed {
                overflow: hidden;
                max-height: 0px;
            }
        }

        &.main {
            margin: 25px 0;
        }

        .resume {
            @include border-radius(4px);
            background-color: $color-neutral-200;
            padding: 8px 10px;
            @include font-size(xs);
            font-family: $text-medium;
            color: $color-black;

            &.main {
                margin: 10px;
            }

            b {
                @include font-size(xs);
                font-family: $text-medium;
                color: $color-black;
            }
        }

        .status {
            @include default-box-shadow($b: 6px, $o: 0.1);
            @include font-size(md);
            background-color: #fff;
            padding: 6px 9px;
            border-radius: 4px;
            color: $color-black;
            width: fit-content;
            display: inline-block;
            font-family: $text-bold;
        }

        .name {
            @include justify-content(flex-start);
            @include font-size(sm);
            font-family: $text-bold;
            color: $color-black;
            text-transform: uppercase;
            background-color: $color-secondary-100;
            color: $color-secondary-800;
            padding: 10px;
            border-radius: 3px;

            &.main {
                @include font-size(sm);
            }

            &.check {
                @include display-flex();
                padding-right: 60px;
                width: 100%;
                flex-flow: nowrap;
                overflow: hidden;
                position: relative;
            }
        }

        .description,
        .sub-description {
            @include font-size(xs);
            font-family: $text-medium;
            color: $color-neutral-600;
            margin-bottom: 10px;
            margin-top: 5px;
            a {
                @include font-size(xs);
                font-family: $text-medium;
            }
        }

        .roles-description {
            @include display-flex();
            @include justify-content(flex-end);
            gap: 17px;
            width: 60%;
            margin: 0 25px 0 auto;

            span {
                width: 50px;
                text-align: center;
            }

            &.audit {
                gap: 12px;

                span {
                    @include font-size(xs);
                }
            }
        }

        .actual-status,
        .actual-status strong {
            @include font-size(sm);
            font-family: $text-medium;
            color: $color-neutral-600;
            margin-bottom: 10px;
            margin-top: 5px;
        }

        .actual-status strong {
            color: $color-secondary-500;
        }

        .temporize-changes {
            margin-top: 10px;
            .temporize-changes-title {
                margin-bottom: 15px;

                .title-temporize {
                    @include font-size(sm);
                    font-family: $text-medium;
                    color: $color-black;
                    font-weight: bold;
                    margin-bottom: 5px;
                }

                .description-temporize {
                    @include font-size(xs);
                    font-family: $text-medium;
                    color: $color-black;

                    a {
                        @include font-size(xs);
                        color: $color-secondary-500;
                        text-decoration: underline;
                    }
                }
            }

            .option {
                @include display-flex();
                @include justify-content(flex-start);
                @include align-items();
                margin-bottom: 0px;
                color: $color-primary-500;

                .label {
                    @include font-size(sm);
                    font-family: $text-medium;
                    color: $color-black;
                    background: transparent;
                }
            }

            .date-selector {
                margin-left: auto;
                width: 90%;
            }

            .advise {
                @include justify-content(flex-start);
                @include font-size(sm);
                font-family: $text-medium;
                color: $color-black;
                background-color: $color-secondary-100;
                padding: 10px;
                border-radius: 3px;
            }

            // .radio {
            //     color: $color-primary-500;
            // }
        }

        .temporary-templates {
            margin-top: 0px;
            padding-top: 8px;

            .disabled {
                cursor: not-allowed !important;
            }

            p.warning-msg {
                font-size: 0.875rem;
                background-image: img('info_warning.svg');
                background-size: 15px;
                background-position: left 11px center;
                background-repeat: no-repeat;
                background-color: $color-warning-100;
                padding: 5px;
                padding-left: 35px;
                border-radius: 6px;
                width: 100%;
                margin: 3px auto;
            }

            .temporary-templates-title {
                padding-top: 8px;
                @include font-size(sm);
                font-family: $text-medium;
                color: $color-black;
                font-weight: bold;
                padding-bottom: 4px;
            }

            .opt-group {
                margin: 0px;
                padding: 8px;

                .option {
                    .label {
                        color: $color-neutral-600;
                        font-weight: 300;
                        padding-left: 0px;
                    }
                }
            }

            .date-input-container {
                display: flex;
                justify-content: flex-end;
                padding-bottom: 4px;

                .date-input {
                    width: 294px;
                }

                .disabled {
                    color: $color-neutral-300 !important;
                    border-color: $color-neutral-300;
                    cursor: not-allowed;
                }

                .input-error {
                    border-color: $color-error-500;
                }

                .disabled:hover {
                    border-color: $color-neutral-300;
                }

                .disabled:focus {
                    border-color: $color-neutral-300;
                }

                .enabled:hover {
                    border-color: $color-primary-500;
                }

                input[type='date']:disabled::-webkit-datetime-edit-year-field {
                    color: $color-neutral-300 !important;
                }

                input[type='date']:disabled::-webkit-datetime-edit-month-field {
                    color: $color-neutral-300 !important;
                }
            }

            .calendar-label {
                font-size: 1rem;
                font-family: 'SourceSans3-SemiBold';
                color: $color-primary-500;
                padding-bottom: 4px;
            }

            .date-input::-webkit-calendar-picker-indicator {
                color: transparent;
                opacity: 1;
                display: block;
                background-image: img('input_date_neutro_s90.svg');
                background-size: 15px;
                background-position: right 5px center;
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
                border-width: thin;
            }
        }

        .must {
            @include font-size(xs);
            font-family: $text;
            color: $color-neutral-600;

            b {
                @include font-size(xs);
                font-family: $text-bold;
                color: $color-neutral-600;
            }

            &.error {
                color: $color-error-800;

                b {
                    color: $color-error-800;
                }
            }
        }
        .sub-name {
            @include justify-content(flex-start);
            @include font-size(sm);
            margin-top: 5px;
            font-family: $text-bold;
            padding: 6px 0px;
            color: $color-black;
        }
        .sub-description {
            @include justify-content(flex-start);
            @include font-size(xs);
            margin-bottom: 5px;
        }

        .timeframes {
            display: block;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px;

            .timeframe {
                background-color: $color-neutral-200;
                width: 100%;
                padding: 8px 6px;
                border-radius: 3px;
                display: inline-block;
                cursor: pointer;
                overflow: hidden;
                min-height: 48px;
                display: flex;
                align-items: center;
                justify-content: left;
                color: $color-black !important;

                .name {
                    background: transparent;
                }

                &.selected {
                    background-color: $color-secondary-100;
                    cursor: default;

                    .hours {
                        color: $color-secondary-800;
                    }
                    .name {
                        color: $color-secondary-800;
                    }
                }

                .hours {
                    @include font-size(sm);
                    font-family: $text;
                    color: $color-black;
                }

                .name {
                    @include font-size(sm);
                    font-family: $text-bold;
                    color: $color-black;
                    padding: 0;
                    width: 100%;
                    text-transform: none;

                    &.allday {
                        @include display-flex();
                        @include align-items();
                        height: 38px;
                    }
                }
            }
        }

        .timeframes_hour {
            @include flex-direction(column);
            @include display-flex();
            margin: 0 auto;
            margin-top: 15px;
            width: calc(100% - 30px);

            .timeframe_hour {
                @include justify-content(space-between);
                @include flex-wrap(wrap);
                @include display-flex();
                @include align-items();
                margin-bottom: 15px;

                .label {
                    @include font-size('sm');
                    font-family: $text;
                    color: $color-black;
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 3px;
                }

                .hour-box {
                    width: fit-content;
                    @include display-inline-flex();
                    @include align-items();
                    min-width: 10%;

                    select {
                        padding: 0 5px !important;
                    }

                    &.custom-hours-selector {
                        gap: 2px;

                        .clock-icon {
                            padding: 12px;
                            background: img('clock_black.svg');
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }

                    .mode {
                        width: 100%;
                        @include font-size('sm', $important: true);
                        text-align: left !important;
                        -webkit-appearance: auto !important;
                        -moz-appearance: auto !important;
                        text-overflow: '';
                        padding: 0;
                        cursor: pointer;
                    }

                    .hour {
                        width: 100%;
                        display: inline-block;
                        // min-width: 85px;
                    }

                    // .clear {
                    //     @include background($size: 13px, $image: img('close_neutro_s90.svg'), $position: center center);
                    //     display: inline-block;
                    //     width: 25px;
                    //     height: 20px;
                    //     cursor: pointer;
                    // }
                }

                .hour-box-timeframe {
                    width: 43%;
                    @include display-inline-flex();
                    @include align-items();
                    min-width: 10%;

                    select {
                        padding: 0 5px !important;
                    }

                    .mode {
                        width: 100%;
                        @include font-size('sm', $important: true);
                        text-align: left !important;
                        -webkit-appearance: auto !important;
                        -moz-appearance: auto !important;
                        text-overflow: '';
                        padding: 0;
                        cursor: pointer;
                    }
                }

                .connector {
                    @include font-size(sm);
                    padding: 0 0px;
                    width: fit-content;
                    font-family: $text;
                    color: $color-black;
                    display: inline-block;
                    text-align: center;
                }

                &.error {
                    .hour,
                    .mode {
                        border-color: $color-error-500;
                    }
                }
            }
        }

        .config-cover {
            .cover {
                width: 100%;
                margin: 10px auto;
                position: relative;
                height: 80px;
                display: block;
                overflow: hidden;
                border-radius: 3px;
                cursor: pointer;

                input {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    opacity: 0;
                    display: block;
                    z-index: 2; //TODO Cambiar este z-index por variables porfa (doc. _variables.scss)
                    cursor: pointer;
                }

                .preview {
                    height: 100%;
                    width: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    cursor: pointer;

                    &.empty {
                        background-color: $color-neutral-200;

                        .empty-image {
                            position: absolute;
                            left: calc(50% - 20px);
                            top: calc(50% - 20px);
                            @include background($size: 40px, $image: img('empty_image_neutro_s10.svg'), $position: center center);
                            width: 40px;
                            height: 40px;
                        }

                        .empty-text {
                            @include font-size('s');
                            color: $color-neutral-600;
                            position: absolute;
                            width: 100%;
                            text-align: center;
                            bottom: 10px;
                            left: 0;
                        }
                    }
                }
            }
            .buttons-cover {
                margin: 0 10px 0 0;
            }
        }

        .types {
            .type {
                margin: 6px;

                .label {
                    color: $color-neutral-600;
                }

                &.selected {
                    .label {
                        color: #fff;
                    }
                }
            }
        }

        .periodicity {
            padding: 0 10px;
        }
    }

    .screenlock {
        .editor-toolbar {
            margin-bottom: 5px;
        }
        .editor-toolbar button {
            margin-right: 5px;
            padding: 2px 5px;
            background: #f0f0f0;
            border: 1px solid #ccc;
            cursor: pointer;
        }
        textarea {
            width: 100%;
            height: 100px;
            padding: 5px;
        }
    }
}
</style>
