<template>
    <div class="config translate-disable">
        <div class="name">{{ $t('template.general.types.title') }}</div>
        <div class="description" v-html="$t('template.general.types.temperature.description')"></div>
        <v-radio-group class="opt-group" v-model="changedConfig.units" @change="sendValue()" hide-details>
            <div class="option">
                <v-radio class="radio" color="#4D206B" :value="'ºC'"></v-radio>
                <div
                    @click="
                        changedConfig.units = 'ºC'
                        sendValue()
                    "
                    class="label"
                    :class="{ selected: changedConfig.units == 'ºC' }"
                >
                    {{ $t('template.general.types.temperature.celsius') }}
                </div>
            </div>
            <div class="option">
                <v-radio class="radio" color="#4D206B" :value="'ºF'"></v-radio>
                <div
                    @click="
                        changedConfig.units = 'ºF'
                        sendValue()
                    "
                    class="label"
                    :class="{ selected: changedConfig.units == 'ºF' }"
                >
                    {{ $t('template.general.types.temperature.fahrenheit') }}
                </div>
            </div>
        </v-radio-group>

        <!-- SENSOR -->
        <template v-if="bSensorLimitation">
            <div class="name">{{ $t('template.general.types.temperature.input.title') }}</div>
            <div class="description" v-html="$t('template.general.types.temperature.input.description')"></div>

            <div class="switch">
                <input class="switch-input" :key="changedConfig.input" type="checkbox" v-model="inputManual" :value="true" @click.stop @change="inputMode('manual')" />
                <div
                    @click="
                        inputManual = !inputManual
                        inputMode('manual')
                    "
                    class="label"
                >
                    {{ $t('template.general.types.temperature.input.manual') }}
                </div>
            </div>
            <div class="switch">
                <input class="switch-input" :key="changedConfig.input" type="checkbox" v-model="inputSensor" :value="true" @click.stop @change="inputMode('sensor')" />
                <div
                    @click="
                        inputSensor = !inputSensor
                        inputMode('sensor')
                    "
                    class="label"
                >
                    {{ $t('template.general.types.temperature.input.sensor') }}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'

export default {
    components: {},
    props: {
        item: { type: Object },
        config: { type: [Object, Boolean], default: { units: 'ºC' } }
    },
    data() {
        return {
            default: { units: 'ºC', input: 'manual' },
            FORMAT: FORMAT,
            changedType: false,
            changedConfig: false,

            inputManual: true,
            inputSensor: false
        }
    },
    computed: {
        bSensorLimitation() {
            var config = this.$store.getters['login/getConfig']
            return config && config.sensorLimitation && config.sensorLimitation == 1
        }
    },
    methods: {
        load() {
            this.changedType = parseInt(this.item.type)

            if (this.config && this.config.units) {
                this.changedConfig = this.config

                // MANUAL
                if (!this.changedConfig.input || this.changedConfig.input == 'manual') {
                    this.inputManual = true
                    this.inputSensor = false
                }
                // SENSOR
                else if (this.changedConfig.input == 'sensor') {
                    this.inputManual = false
                    this.inputSensor = true
                }
                // ALL
                else {
                    this.inputManual = true
                    this.inputSensor = true
                }
            } else {
                this.changedConfig = this.default
                this.sendValue()
            }
        },

        sendValue() {
            this.$emit('sendItemType', { type: this.changedType, config: this.changedConfig })
        },

        inputMode(value) {
            // MANUAL
            if (this.inputManual && !this.inputSensor) {
                this.changedConfig.input = 'manual'
            }
            // SENSOR
            else if (!this.inputManual && this.inputSensor) {
                this.changedConfig.input = 'sensor'
            }
            // ALL
            else if (this.inputManual && this.inputSensor) {
                this.changedConfig.input = 'all'
            }

            // SPECIAL CASE BOTH EMPTY
            else {
                if (value == 'manual') {
                    this.inputManual = false
                    this.inputSensor = true
                    this.changedConfig.input = 'sensor'
                } else {
                    this.inputManual = true
                    this.inputSensor = false
                    this.changedConfig.input = 'manual'
                }
            }

            this.sendValue()
        }
    },
    created() {
        this.load()
    },
    watch: {
        item: {
            handler: function (val, oldVal) {
                if (oldVal.id != val.id) {
                    this.load()
                }
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.config {
    .opt-group {
        margin-bottom: 0px;
        margin-top: 5px;

        .option {
            @include display-flex();
            @include justify-content(flex-start);
            width: 100%;
            overflow: hidden;
            margin-bottom: 5px;

            .radio {
                display: inline-block;
                width: 30px;
                margin-bottom: 0 !important;
                margin-top: 6px;

                .v-input--selection-controls__input {
                    overflow: hidden;
                    border-radius: 100%;
                }
            }

            .label {
                display: inline-block;
                padding: 6px;
                width: calc(100% - 30px);
                height: 30px;
                border-radius: 3px;
                font-family: $text-medium;
                color: $color-black;
                cursor: pointer;

                &.selected {
                    background-color: $color-neutral-200;
                }
            }
        }
    }

    .setter {
        @include display-flex();
        @include justify-content(flex-start);
        @include align-items();
        margin-top: 5px;

        .degrees {
            @include font-size(md);
            display: inline-block;
            width: 100px;
            padding: 0;
            height: 30px;
            text-align: center;
        }

        .icon {
            @include background($size: 15px, $image: img('right_arrow_dark.svg'), $position: center);
            display: inline-block;
            width: 20px;
            height: 30px;
            margin: 0 10px;
        }
    }

    .switch {
        @include display-flex();
        @include align-items();
        margin-top: 10px;

        .switch-input {
            margin-right: 5px;
        }

        .label {
            @include font-size(sm);
            font-family: $text;
        }
    }
}
</style>
