<template>
    <div class="config translate-disable">
        <div class="name">{{ $t('template.general.types.title') }}</div>
        <div class="description" v-html="$t('template.general.types.options.description')"></div>
        <v-radio-group hide-details v-model="changedType" @change="sendValue()">
            <div class="option">
                <v-radio class="radio" color="#4D206B" :value="FORMAT.YESNO"></v-radio>
                <div
                    @click="
                        changedType = FORMAT.YESNO
                        sendValue()
                    "
                    class="label"
                    :class="{ selected: changedType == FORMAT.YESNO }"
                >
                    <span class="yes">{{ $t('nvgt.report.answer_yes') }}</span
                    ><span class="no">{{ $t('nvgt.report.answer_no') }}</span>
                </div>
            </div>
            <div class="option">
                <v-radio class="radio" color="#4D206B" :value="FORMAT.YESNONC"></v-radio>
                <div
                    @click="
                        changedType = FORMAT.YESNONC
                        sendValue()
                    "
                    class="label"
                    :class="{ selected: changedType == FORMAT.YESNONC }"
                >
                    <span class="yes">{{ $t('nvgt.report.answer_yes') }}</span
                    ><span class="no">{{ $t('nvgt.report.answer_no') }}</span
                    ><span class="nc">{{ $t('nvgt.report.answer_nsnc') }}</span>
                </div>
            </div>
        </v-radio-group>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'

export default {
    components: {},
    props: {
        item: { type: Object },
        config: { default: false }
    },
    data() {
        return {
            FORMAT: FORMAT,
            changedType: false,
            changedConfig: false
        }
    },
    computed: {},
    methods: {
        load() {
            this.changedType = parseInt(this.item.type)
            this.changedConfig = this.config
        },

        sendValue() {
            this.$emit('sendItemType', { type: this.changedType, config: this.changedConfig })
        }
    },
    created() {
        this.load()
    },
    watch: {
        item: {
            handler: function (val, oldVal) {
                if (oldVal.id != val.id) {
                    this.load()
                }
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.config {
    .option {
        @include display-flex();
        @include justify-content(flex-start);
        width: 100%;
        overflow: hidden;
        margin-bottom: 9px;

        .radio {
            display: inline-block;
            width: 30px;
            margin-bottom: 0 !important;
            margin-top: 6px;

            .v-input--selection-controls__input {
                overflow: hidden;
                border-radius: 100%;
            }
        }

        .label {
            display: inline-block;
            padding: 6px;
            width: calc(100% - 30px);
            min-height: 30px;
            border-radius: 3px;
            color: $color-neutral-600;
            cursor: pointer;

            &.selected {
                background-color: $color-neutral-200;
            }

            span {
                display: inline-block;
                border-radius: 3px;
                padding: 1px 15px;
                text-align: center;
                margin-right: 3px;
                color: #fff;
                // overflow: hidden;
                line-height: 20px;

                &.yes {
                    background-color: $color-success-500;
                }

                &.no {
                    background-color: $color-error-500;
                }

                &.nc {
                    background-color: $color-neutral-600;
                }
            }
        }
    }
}
</style>
