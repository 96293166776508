var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-template",class:{ 'with-trial': _vm.trialEndDate },attrs:{"id":"detail"}},[_c('div',{staticClass:"bar-actions"},[(_vm.template)?_c('TranslateButton',{attrs:{"entityId":'tpl.' + _vm.template.id,"translationLangs":_vm.template.translations},on:{"newTranslation":_vm.newTranslation,"deleteTranslation":_vm.deleteTranslation}}):_vm._e(),_c('Button',{attrs:{"bType":"cancel_changes","bCallback":_vm.goBack}}),(_vm.template)?_c('Button',{attrs:{"bType":"save","bCallback":_vm.save,"customClass":"btn-action","bCallbackProps":_vm.template.status,"bLabel":"template.general.button_save"}}):_vm._e(),(_vm.id)?[_c('Button',{attrs:{"bType":"more","bCallback":_vm.showMoreOptions}}),(_vm.moreOptions)?_c('div',{staticClass:"more-options"},[_c('Button',{attrs:{"bType":"convert","bCallback":_vm.convert,"customClass":"btn-convert","bLabel":"template.general.button_convert"}}),_c('Button',{attrs:{"bType":"duplicate","bCallback":_vm.clone,"customClass":"btn-duplicate","bLabel":"template.general.button_clone"}}),_c('Button',{attrs:{"bType":"delete","bCallback":_vm.showConfirmTemplateDeleteModal,"bLabel":"menu_aux.action.delete","customClass":"btn-secondary"}})],1):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"detail-container"},[_c('div',{staticClass:"main-content scrollbar scrollbar-transparent"},[(_vm.template)?[_c('div',{staticClass:"template-info"},[_c('TranslateInput',{attrs:{"translation_keyname":'tpl.' + _vm.template.id + '.title',"custom_config":['get_$event'],"_type":"textarea","maxLength":"253","value":_vm.template.name,"_placeholder":_vm.$t('template.general.title_empty'),"_class":[
                            'integrated',
                            'name',
                            { 'error-field': _vm.template.errors && _vm.template.errors.codes && _vm.template.errors.codes.includes('empty_name') }
                        ],"rows":"1"},on:{"input":[function($event){_vm.resizeTextarea($event)
                            _vm.template.name = $event.target.value},function($event){return _vm.updateTemplate()}],"click":function($event){return _vm.unselectItem()}}}),_c('TranslateInput',{attrs:{"translation_keyname":'tpl.' + _vm.template.id + '.description',"custom_config":['get_$event'],"_type":"textarea","value":_vm.template.description,"_placeholder":_vm.$t('template.general.item_description_empty'),"_class":[
                            'integrated',
                            'description',
                            { 'error-field': _vm.template.errors && _vm.template.errors.codes && _vm.template.errors.codes.includes('empty_name') }
                        ],"rows":"1"},on:{"input":[function($event){_vm.resizeTextarea($event)
                            _vm.template.description = $event.target.value},function($event){return _vm.updateTemplate()}],"click":function($event){_vm.unselectItem()
                            _vm.resizeTextarea($event)}}})],1),(_vm.template && _vm.template.sections && Object.keys(_vm.template.sections).length > 0)?[_c('draggable',_vm._b({staticClass:"content-draggable",attrs:{"group":{ name: 'sections' }},on:{"end":function($event){return _vm.dragMove()}},model:{value:(_vm.sortedHash),callback:function ($$v) {_vm.sortedHash=$$v},expression:"sortedHash"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{staticClass:"content-helper"},[_vm._l((_vm.orderSections()),function(section,section_index){return [(typeof section.status == 'undefined' || section.status > 0)?_c('Section',{key:'s_' + section.id,ref:"sections",refInFor:true,staticClass:"custom-section",attrs:{"context":{
                                        template_id: _vm.template.id,
                                        section_id: section.id,
                                        item_id: false,
                                        condition_id: false
                                    },"section":section,"isNoSection":_vm.activeSections.length == 1 && section.id == '-1'}}):_vm._e()]})],2)],1)]:_vm._e(),_c('addEntity',{attrs:{"context":{
                        template_id: _vm.template.id,
                        section_id: false,
                        item_id: false,
                        condition_id: false
                    },"emptyNoSection":_vm.template.type == 2 ? false : _vm.emptyNoSection,"emptySections":_vm.emptySections,"isNoSection":_vm.activeSections.length == 1 && _vm.activeSections[0].id == '-1'},on:{"newItem":_vm.updateSectionSortedHash}})]:_vm._e()],2),(_vm.template)?[_c('TemplateConfig',{attrs:{"context":{
                    template_id: _vm.template.id,
                    section_id: false,
                    item_id: false,
                    condition_id: false
                },"template":_vm.template,"templateIsTemporal":_vm.templateIsTemporal}}),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.selectedContext)?[_c('ItemConfig',{attrs:{"context":_vm.selectedContext,"templateStatus":_vm.template.status,"periodicity":[_vm.TYPE.TASK].includes(_vm.template.type),"score":_vm.template.type == 2,"review":[_vm.TYPE.TASK].includes(_vm.template.type),"roleLimit":_vm.roleLimit,"templateType":_vm.template.type,"locationLimit":_vm.sitesLimit.locationLimit,"groupLimit":_vm.sitesLimit.groupLimit,"templateIsTemporal":_vm.templateIsTemporal,"templateName":_vm.template.name}})]:_vm._e()],2)]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }