<template>
    <div v-if="condition" class="template_condition">
        <div class="sentence">
            <span>{{ $t('template.general.condition_text_start') }}</span>

            <select :value="condition.operator" class="integrated-input translate-disable" v-model.lazy="condition.operator" @change="changeOperator(condition.operator)">
                <option v-for="condition in options(type)" :value="condition.value" :key="condition.id + condition.value">{{ condition.text }}</option>
            </select>

            <template v-if="condition.operator == '<>' || condition.operator == '!<>'">
                <input class="integrated-input text" @input="formatValue($event.target.value, 0)" :value="condition.value.split(',')[0]" type="number" />

                <span>{{ $t('template.general.condition_text_connector') }}</span>

                <input class="integrated-input text" @input="formatValue($event.target.value, 1)" :value="condition.value.split(',')[1]" type="number" />
            </template>
            <template v-else-if="condition.operator != '!empty'">
                <select v-if="type == FORMAT.MULTIPLE" :value="condition.value" class="integrated-input translate-disable" v-model.lazy="condition.value" @change="formatConditionsValues('selector')">
                    <option v-for="option in config.values" :value="option.v" :key="option.v">
                        <span>{{ option.v }}</span>
                    </option>
                </select>

                <select v-else-if="type == FORMAT.YESNO || type == FORMAT.YESNONC" :value="condition.value" class="integrated-input translate-disable" v-model.lazy="condition.value" @change="formatConditionsValues()">
                    <option
                        v-for="option in [
                            { text: $t('nvgt.report.answer_yes'), value: 1 },
                            { text: $t('nvgt.report.answer_no'), value: 0 }
                        ]"
                        :value="option.value"
                        :key="option.value"
                    >
                        {{ option.text }}
                    </option>
                    <option v-if="type == FORMAT.YESNONC" :value="2">{{ $t('nvgt.report.answer_nsnc') }}</option>
                </select>
                <input v-else-if="condition.operator" class="integrated-input text" v-model="condition.value" @change="formatConditionsValues(context.item_id, context.condition_id)" :type="[FORMAT.NUMBER, FORMAT.TEMPERATURE].includes(parseInt(type)) ? 'number' : 'string'" />
            </template>
            <span>{{ $t('template.general.condition_text_end') }}</span>
            <div @click="deleteCondition(context.condition_id)" v-show="context.item_id == selectedContext.item_id" class="delete translate-hide" data-type="delete"></div>
        </div>

        <draggable handle=".handle-item" v-model="sortedHash" v-bind="dragOptions" @end="dragMove()" :group="{ name: 'subitem' }">
            <template v-if="condition.items" v-for="(item, item_index) in orderItems()">
                <Item v-if="item.status > 0" :item="item" :key="'i_' + item.id" :context="{ template_id: context.template_id, section_id: context.section_id, item_id: item.id, condition_id: condition.id, item_parent_id: context.item_id }"></Item>
            </template>
        </draggable>

        <Action v-if="condition.actions" v-for="(action, action_index) in condition.actions" :context="{ template_id: context.template_id, section_id: context.section_id, item_id: context.item_id, condition_id: condition.id, action_id: action_index }" :action_index="action_index"></Action>

        <addEntity v-if="context.item_id == selectedContext.item_id" :context="{ template_id: context.template_id, section_id: context.section_id, item_id: context.item_id, condition_id: condition.id }" :hasNewCondition="condition.id == 'conditionitw'"></addEntity>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'
import i18n from '@/i18n'
import addEntity from '@/components/domain/templates/template/addEntity.vue'
import draggable from 'vuedraggable'

export default {
    components: {
        Item: () => import('@/components/domain/templates/item.vue'),
        Action: () => import('@/components/domain/templates/item/action.vue'),
        addEntity,
        draggable
    },
    name: 'Condition',
    props: {
        context: { type: Object, default: {} },
        config: { type: Object, default: {} },
        condition: { type: Object, default: {} },
        type: { type: Number, default: 1 },
        canDelete: { type: Boolean, default: true }
    },
    data() {
        return {
            FORMAT: FORMAT,
            sortedHash: []
        }
    },
    computed: {
        // condition: {
        //     get() {
        //         var condition = { ...this.$store.getters['template/getCondition'](this.context) }
        //         this.sortedHash = Object.keys(condition.items)
        //         return condition
        //     },
        //     set(value) {
        //         this.$store.commit('template/updateCondition', { context: this.context, condition: value })
        //     }
        // },

        selectedContext() {
            // GET ACTIVE SELECTED ITEM
            return this.$store.getters['template/getSelectedContext']
        },

        dragOptions() {
            // DRAG ITEM OPTIONS
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'moving'
            }
        }
    },
    methods: {
        // CONDITION METHODS
        options(type) {
            var options = []

            options.push({ text: i18n.t('template.general.condition_options.has_value'), value: '!empty' })

            if ([FORMAT.NUMBER, FORMAT.TEMPERATURE, FORMAT.YESNO, FORMAT.YESNONC, FORMAT.MULTIPLE].includes(parseInt(type))) {
                options.push({ text: i18n.t('template.general.condition_options.same'), value: '=' }, { text: i18n.t('template.general.condition_options.different'), value: '!=' })
            }

            if ([FORMAT.NUMBER, FORMAT.TEMPERATURE].includes(parseInt(type))) {
                options.push({ text: i18n.t('template.general.condition_options.more_than'), value: '>' }, { text: i18n.t('template.general.condition_options.more_equal_than'), value: '>=' }, { text: i18n.t('template.general.condition_options.less_than'), value: '<' }, { text: i18n.t('template.general.condition_options.less_equal_than'), value: '<=' }, { text: i18n.t('template.general.condition_options.between'), value: '<>' }, { text: i18n.t('template.general.condition_options.not_between'), value: '!<>' })
            }

            return options
        },

        validateConditionOperator() {
            var condition = { ...this.condition }
            var options = this.options()

            if (
                !options
                    .map((operator) => {
                        return operator.value
                    })
                    .includes(this.condition.operator)
            ) {
                condition.operator = '!empty'
                condition.value = ''
                this.condition = condition
            }
        },

        changeOperator(operator) {
            console.warn('condition operator', operator)
            this.condition.operator = operator
            this.$forceUpdate()
        },

        deleteCondition() {
            if (this.canDelete) this.$store.commit('template/deleteCondition', this.context)
            else {
                var self = this
                this.$popup.confirm({
                    message: this.$t('template.general.popup_delete_condition_msg'),
                    textSave: this.$t('template.general.popup_delete_condition_confirm'),
                    callSave: function () {
                        self.$store.commit('template/deleteCondition', self.context)
                    },
                    callCancel: function () {
                        this.$popup.close()
                    }
                })
            }
        },

        orderItems() {
            // ORDER ITEMS BY THE ORDER PROP
            var items = Object.values(this.condition.items)
            items.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))
            return items
        },

        formatValue(value, position) {
            var condition = { ...this.condition }
            var currentValue = condition.value
            value = value.replace(/,/g, '.')
            if (position == 0) {
                currentValue = value + ',' + currentValue.split(',')[1]
            } else {
                currentValue = currentValue.split(',')[0] + ',' + value
            }
            condition.value = currentValue
            this.condition.value = currentValue
        },

        formatConditionsValues(mode = 'default') {
            var condition = { ...this.condition }
            // if (mode == 'selector') {
            //     condition.value = condition.value.replace(/,/g, '.')
            // }

            this.condition = condition
            // if (this.template.schema[item_id].renderconfig[index].operator == '<>' || this.template.schema[item_id].renderconfig[index].operator == '!<>') {
            //   // this.template.schema[item_id].renderconfig[index].value = (""+this.template.schema[item_id].renderconfig[index].value).includes(',')  ? this.template.schema[item_id].renderconfig[index].value : this.template.schema[item_id].renderconfig[index].value
            //   this.template.schema[item_id].renderconfig[index].value = this.template.schema[item_id].renderconfig[index].value.includes(',') != -1 ? this.template.schema[item_id].renderconfig[index].value : this.template.schema[item_id].renderconfig[index].value + ','
            // } else
            // this.template.schema[item_id].renderconfig[index].value = (""+this.template.schema[item_id].renderconfig[index].value).includes(',')  ? (""+this.template.schema[item_id].renderconfig[index].value).split(',')[0] : this.template.schema[item_id].renderconfig[index].value;
            // for ( var itemindex in this.template.schema[item_id].renderconfig[index].items ) {
            //   var item = this.template.schema[item_id].renderconfig[index].items[itemindex];
            //   item.operator = this.template.schema[item_id].renderconfig[index].operator;
            //   item.value = this.template.schema[item_id].renderconfig[index].value;
            //   this.template.schema[item.item_id].conditions.values[item_id].operator = this.template.schema[item_id].renderconfig[index].operator
            //   this.template.schema[item.item_id].conditions.values[item_id].value = this.template.schema[item_id].renderconfig[index].value
            // }
        },

        // ITEM METHODS
        addItem() {
            this.$store.commit('template/addItem', { context: this.context })
        },

        addAction(type) {
            this.$store.commit('template/addAction', { context: this.context, type: type })
        },

        dragMove() {
            console.warn('dragMove condition')
            this.$store.commit('template/sort', { context: this.context, items: this.sortedHash })
        }
    },
    created() {
        console.warn('CREATED CONDITION')
        this.sortedHash = Object.keys(this.condition.items)
    },
    watch: {
        condition: function (value) {
            console.warn('WATCH CONDITION')
            this.sortedHash = Object.keys(value.items)
        },
        type: {
            handler: function (val, oldVal) {
                // this.validateConditionOperator();
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.template_condition {
    // padding-left: 15px;
    position: relative;
    // right: -30px;
    right: -20px;
    margin: 9px 0;

    .sentence {
        @include background($size: 20px, $image: img('condition_inactive_s70.svg'), $position: left 10px center);
        position: relative;
        padding-left: 35px;
        padding-right: 30px;
        width: fit-content;

        .integrated-input {
            border-color: $color-neutral-300;
            // background-color: #fff !important;
            color: $color-neutral-600 !important;
            font-family: $text-medium !important;
            width: fit-content;
            min-height: 25px;
            margin: 0 4px;
        }

        input[type='number'] {
            width: 65px;
            text-align: center;
            min-height: 25px;
        }

        span {
            @include font-size('sm');
            color: $color-neutral-600;
            font-family: $text-medium;
            display: inline-block;
            margin: 0 5px;
        }

        &.add {
            cursor: pointer;
            margin-bottom: 5px;
        }
    }
}

.delete {
    right: 0;
    top: 0;
    margin: 0;
}
</style>
