<template>
    <div class="config translate-disable">
        <div class="name">{{ $t('template.general.types.title') }}</div>
        <div class="description" v-html="$t('template.general.types.text.description')"></div>
        <v-radio-group hide-details class="opt-group" v-model="changedType" @change="sendValue()">
            <div class="option">
                <v-radio class="radio" color="#4D206B" :value="FORMAT.TEXT"></v-radio>
                <div
                    @click="
                        changedType = FORMAT.TEXT
                        sendValue()
                    "
                    class="label"
                    :class="{ selected: changedType == FORMAT.TEXT }"
                >
                    {{ $t('template.general.types.text.short_text') }}
                </div>
            </div>
            <div class="option">
                <v-radio class="radio" color="#4D206B" :value="FORMAT.TEXTAREA"></v-radio>
                <div
                    @click="
                        changedType = FORMAT.TEXTAREA
                        sendValue()
                    "
                    class="label"
                    :class="{ selected: changedType == FORMAT.TEXTAREA }"
                >
                    {{ $t('template.general.types.text.long_text') }}
                </div>
            </div>
        </v-radio-group>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'

export default {
    components: {},
    props: {
        item: { type: Object },
        config: { default: false }
    },
    data() {
        return {
            FORMAT: FORMAT,
            changedType: false,
            changedConfig: false
        }
    },
    computed: {},
    methods: {
        load() {
            this.changedType = parseInt(this.item.type)
            this.changedConfig = this.config
        },

        sendValue() {
            this.$emit('sendItemType', { type: this.changedType, config: this.changedConfig })
        }
    },
    created() {
        this.load()
    },
    watch: {
        item: {
            handler: function (val, oldVal) {
                if (oldVal.id != val.id) {
                    this.load()
                }
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.config {
    .opt-group {
        margin-bottom: 0px;
        margin-top: 5px;

        .option {
            @include display-flex();
            @include justify-content(flex-start);
            width: 100%;
            overflow: hidden;
            margin-bottom: 5px;

            .radio {
                display: inline-block;
                width: 30px;
                margin-bottom: 0 !important;
                margin-top: 6px;

                .v-input--selection-controls__input {
                    overflow: hidden;
                    border-radius: 100%;
                }
            }

            .label {
                display: inline-block;
                padding: 6px;
                width: calc(100% - 30px);
                height: 30px;
                border-radius: 3px;
                color: $color-black;
                cursor: pointer;

                &.selected {
                    background-color: $color-neutral-200;
                }
            }
        }
    }
}
</style>
