var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('div',{ref:Object.values(_vm.context),staticClass:"template-item",class:{ selected: _vm.isSelected, subitem: _vm.context.item_parent_id },on:{"click":function($event){return _vm.select($event, _vm.context)}}},[_c('div',{staticClass:"handle-item translate-hide",class:{ visible: _vm.item.id == _vm.selectedContext.item_id }}),(!_vm.context.item_parent_id)?_c('div',{staticClass:"collapse",on:{"click":function($event){$event.stopPropagation();_vm.collapse = !_vm.collapse}}},[_c('div',{staticClass:"arrow",class:{ active: _vm.collapse }}),(_vm.collapse)?[_vm._v(_vm._s(_vm.$t('template.general.display_content')))]:[_vm._v(_vm._s(_vm.$t('template.general.collapse_content')))]],2):_vm._e(),(!_vm.context.item_parent_id)?_c('Summary',{attrs:{"email":_vm.summary.num_emails,"alert":_vm.summary.num_alerts,"subitem":_vm.summary.num_subitems,"periodicity":_vm.summary.periodicity_type}}):_vm._e(),_c('div',{staticClass:"item-body",class:{ 'error-field': _vm.item.errors && _vm.item.errors.codes && Object.keys(_vm.item.errors.codes).length > 0 }},[_c('div',{staticClass:"info"},[_c('TranslateInput',{attrs:{"translation_keyname":'tpl.' + _vm.context.template_id + '.item.' + _vm.item.id + '.title',"custom_config":['get_$event'],"_type":"textarea","value":_vm.item.title,"_placeholder":_vm.$t('template.general.item_title_empty'),"_class":[
                    'name',
                    {
                        'error-field':
                            _vm.item.errors &&
                            _vm.item.errors.codes &&
                            (_vm.item.errors.codes.includes('empty_name') ||
                                _vm.item.errors.codes.includes('empty_points') ||
                                _vm.item.errors.codes.includes('empty_operator'))
                    }
                ],"rows":"1"},on:{"input":function($event){_vm.resizeTextarea($event)
                    _vm.changeItemValue('title', $event.target.value)},"click":function($event){return _vm.resizeTextarea($event)},"change":function($event){_vm.item = _vm.item}}}),_c('TranslateInput',{attrs:{"translation_keyname":'tpl.' + _vm.context.template_id + '.item.' + _vm.item.id + '.description',"custom_config":['get_$event'],"_type":"textarea","value":_vm.parseBreakLines(_vm.item.description),"_placeholder":_vm.$t('template.general.item_description_empty'),"_class":['description', { 'error-field': _vm.item.errors && _vm.item.errors.codes && _vm.item.errors.codes.includes('empty_description') }],"maxLength":"1000"},on:{"input":function($event){_vm.resizeDescriptionTextarea($event)
                    _vm.changeItemValue('description', $event.target.value)},"change":function($event){_vm.item = _vm.item},"click":function($event){return _vm.resizeDescriptionTextarea($event)}}})],1),_c('div',{staticClass:"meta"},[(_vm.templateType == _vm.TYPE.TASK)?_c('div',{class:['element', 'periodicity', _vm.periodicityTimes[_vm.item.periodicity]]},[_vm._v("\n                "+_vm._s(_vm.$t('template.general.periodicity_times_label.' + _vm.periodicityTimes[_vm.item.periodicity]))+"\n            ")]):_vm._e(),_c('div',{staticClass:"type",class:{ 'error-field': _vm.item.errors && _vm.item.errors.codes && _vm.item.errors.codes.includes('empty_config') },attrs:{"data-type":_vm.item.type}},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('template.general.item_type.' + _vm.getToolTypeName(_vm.item.type, 'FORMAT'))))])])])]),(_vm.item.id == _vm.selectedContext.item_id || (_vm.selectedContext && _vm.context.item_parent_id == _vm.selectedContext.item_id))?_c('Button',{staticClass:"more-button translate-hide",class:{
            active: _vm.moreItemOptions == _vm.item.id,
            visible: _vm.item.id == _vm.selectedContext.item_id || (_vm.selectedContext && _vm.context.item_parent_id == _vm.selectedContext.item_id)
        },attrs:{"bType":"more","bCallback":_vm.showMoreItemOptions}}):_vm._e(),((_vm.item.id == _vm.selectedContext.item_id || (_vm.selectedContext && _vm.context.item_parent_id == _vm.selectedContext.item_id)) && _vm.moreItemOptions == _vm.item.id)?_c('div',{staticClass:"more-options translate-hide"},[_c('Button',{attrs:{"bType":"duplicate","bCallback":_vm.clone,"customClass":"btn-duplicate","bLabel":"template.general.button_clone"}}),_c('Button',{attrs:{"bType":"delete","bCallback":_vm.deleteItem,"customClass":"btn-delete","bLabel":"button.delete"}})],1):_vm._e(),(!_vm.collapse)?_c('div',{staticClass:"conditions",class:{ collapsed: _vm.collapse }},[(_vm.item.conditions && !_vm.emptyConditions())?[_vm._l((_vm.orderConditions()),function(condition,condition_index){return [(condition.status != -1)?_c('Condition',{key:_vm.item.id + '_c' + condition_index,attrs:{"condition":condition,"context":{ template_id: _vm.context.template_id, section_id: _vm.context.section_id, item_id: _vm.context.item_id, condition_id: condition.id },"config":_vm.item.config ? _vm.item.config : {},"type":_vm.item.type,"canDelete":_vm.summary.num_alerts == 0 && _vm.summary.num_emails == 0 && _vm.summary.num_subitems == 0}}):_vm._e()]})]:(_vm.item.id == _vm.selectedContext.item_id && !_vm.context.item_parent_id)?[_c('addEntity',{attrs:{"context":{
                    template_id: _vm.context.template_id,
                    section_id: _vm.context.section_id,
                    item_id: _vm.context.item_id,
                    condition_id: _vm.context.condition_id
                }},on:{"refresh":function($event){return _vm.fnRefreshEntity()}}})]:_vm._e()],2):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }