<template>
    <div class="config translate-disable">
        <div class="name">{{ $t('template.general.types.title') }}</div>
        <div class="description" v-html="$t('template.general.types.date.description')"></div>
        <div class="checkbox">
            <v-checkbox v-model="date" @change="sendValue('DATE')" color="#4D206B" class="checkbutton" dense hide-details></v-checkbox>
            <div class="checkbox-label" :class="{ selected: date }">{{ $t('template.general.types.date.date') }}</div>
            <v-checkbox v-model="time" @change="sendValue('TIME')" color="#4D206B" class="checkbutton" dense hide-details></v-checkbox>
            <div class="checkbox-label" :class="{ selected: time }">{{ $t('template.general.types.date.time') }}</div>
        </div>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'

export default {
    components: {},
    props: {
        item: { type: Object },
        config: { type: [Object, Boolean], default: false }
    },
    data() {
        return {
            default: false,
            FORMAT: FORMAT,
            changedType: false,
            changedConfig: false,

            // FORM VARIABLES
            date: false,
            time: false
        }
    },
    computed: {},
    methods: {
        load() {
            this.changedType = parseInt(this.item.type)
            if (parseInt(this.item.type) == FORMAT.DATETIME) {
                this.date = true
                this.time = true
            } else if (parseInt(this.item.type) == FORMAT.DATE) {
                this.date = true
            } else if (parseInt(this.item.type) == FORMAT.TIME) {
                this.time = true
            }

            this.changedConfig = this.config
        },

        sendValue(type) {
            if (!this.date && !this.time) {
                if (type == 'DATE') {
                    this.time = true
                } else this.date = true
            }
            if (this.date && this.time) {
                this.changedType = FORMAT.DATETIME
            } else if (this.date) {
                this.changedType = FORMAT.DATE
            } else if (this.time) {
                this.changedType = FORMAT.TIME
            }

            this.$emit('sendItemType', { type: this.changedType, config: this.changedConfig })
        }
    },
    created() {
        this.load()
    },
    watch: {
        item: {
            handler: function (val, oldVal) {
                if (oldVal.id != val.id) {
                    this.load()
                }
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.config {
    .checkbox {
        @include display-flex();
        @include justify-content(flex-start);
        @include align-items();
        margin-top: 9px;

        .checkbutton {
            display: inline-block;
            margin-top: 0;

            .v-input--selection-controls__input {
                overflow: hidden !important;
                border-radius: 100%;
            }
        }

        .checkbox-label {
            display: inline-block;
            @include font-size(sm);
            font-family: $text-medium;
            color: $color-black;
            width: calc(50% - 50px);
            margin-right: 10px;
            padding: 9px;

            &.selected {
                background-color: $color-neutral-200;
            }
        }
    }
}
</style>
