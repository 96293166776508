<template>
    <div class="section-item" v-if="section" :class="{ normal: section.id == -1 }">
        <div class="collapse" @click="collapse = !collapse">
            <div class="arrow" :class="{ active: collapse }"></div>
            <template v-if="!collapse">{{ $t('template.general.collapse_content') }}</template>
            <template v-else>{{ $t('template.general.display_content') }}</template>
        </div>

        <div class="info">
            <div class="handle-section"></div>
            <TranslateInput
                :translation_keyname="'tpl.' + context.template_id + '.item.' + section.id + '.title'"
                :custom_config="['get_$event']"
                :value="section.name"
                v-on:input="section.name = $event.target.value"
                :_placeholder="$t('template.general.item_title_empty')"
                @click="resizeTextarea($event)"
                @change="section = section"
                :_class="[
                    'integrated',
                    'w100',
                    'name',
                    { 'error-field': section.errors && section.errors.codes && section.errors.codes.includes('empty_name') },
                    { error: section.errors && section.errors.codes && section.errors.codes.includes('empty_name') }
                ]"
                rows="1"
            ></TranslateInput>
            <!-- <v-textarea
                class="integrated name"
                :value="section.name"
                @input="
                    (v) => {
                        section.name = v
                    }
                "
                @change="section = section"
                auto-grow
                solo
                flat
                dense
                rows="1"
                hide-details
                :placeholder="$t('template.general.item_title_empty')"
                :class="{ error: section.errors && section.errors.codes && section.errors.codes.includes('empty_name') }"
            >
            </v-textarea> -->
        </div>
        <Button
            bType="more"
            v-if="section.id != -1"
            :class="{ active: moreItemOptions == section.id }"
            :bCallback="showMoreItemOptions"
            class="more-button"
        ></Button>
        <div class="more-options" v-if="moreItemOptions == section.id && section.id != -1">
            <Button bType="duplicate" :bCallback="clone" customClass="btn-duplicate" bLabel="template.general.button_clone"></Button>
            <Button bType="delete" :bCallback="deleteSection" customClass="btn-delete" bLabel="button.delete"></Button>
        </div>

        <div class="list" :class="{ collapsed: collapse }">
            <draggable
                v-if="sortedHash && !collapse"
                class="list-draggable"
                handle=".handle-item"
                v-model="sortedHash"
                v-bind="dragOptions"
                @end="dragMove()"
                :group="{ name: 'item' + section.id }"
            >
                <template v-for="(item, item_index) in orderItems()">
                    <transition-group class="list-helper" :key="'ti_' + item.id" v-if="item.status > 0">
                        <Item
                            :context="{ template_id: context.template_id, section_id: context.section_id, item_id: item.id, condition_id: false }"
                            :item="item"
                            :key="'i_' + item.id"
                        ></Item>
                    </transition-group>
                </template>
                <addEntity
                    @newItem="updateSortedHash"
                    v-if="!isNoSection && (section.id != '-1' || (section.id == -1 && activeItems))"
                    :context="{ template_id: context.template_id, section_id: context.section_id, item_id: false, condition_id: false }"
                ></addEntity>
            </draggable>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
// PRINCIPAL COMPONENTS
import Item from '@/components/domain/templates/item.vue'
import addEntity from '@/components/domain/templates/template/addEntity.vue'

export default {
    components: {
        draggable,
        Item,
        addEntity
    },
    name: 'Section',
    props: {
        context: { type: Object, default: {} },
        section: { type: Object, default: {} },
        isNoSection: { type: Boolean, default: false }
    },
    data() {
        return {
            // GENERAL VARIABLES OF CONTROL
            collapse: false,
            // DRAG MOVE SECTIONS
            sortedHash: false
        }
    },
    computed: {
        // section: {
        //     get() {
        //         var section = { ...this.$store.getters['template/getSection'](this.context) }
        //         if (!this.sortedHash) {
        //             var items = Object.values(Object.assign({}, section.items))
        //             items.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))
        //             this.sortedHash = items.map((item) => {
        //                 return item.id
        //             })
        //             // TO REVIEW ------------------------------------------------------------------------------
        //             // this.$store.commit('template/sort', { context: this.context, items: [...this.sortedHash] })
        //         }
        //         return section
        //     },
        //     set(value) {
        //         this.$store.commit('template/updateSection', { context: this.context, section: value })
        //     }
        // },

        dragOptions() {
            // DRAG ITEM OPTIONS
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'moving'
            }
        },

        activeItems() {
            if (this.section) {
                var itemsActive = Object.values(this.section.items).filter((item) => {
                    return item.status > -1
                })
                return itemsActive.length > 0
            } else return false
        },

        moreItemOptions: {
            get() {
                var moreItem = this.$store.getters['template/getMoreItemOptions']
                return moreItem && moreItem.type == 'section' ? moreItem.id : false
            },
            set(value) {
                this.$store.commit('template/setMoreItemOptions', { type: 'section', id: value })
            }
        }
    },
    methods: {
        showMoreItemOptions() {
            if (this.moreItemOptions == this.section.id) {
                this.moreItemOptions = false
            } else {
                this.moreItemOptions = this.section.id
            }
        },

        // CLONE SECTION
        clone() {
            var self = this
            this.$overlay.loading()
            this.$store.dispatch('template/cloneSection', { context: this.context, section: { ...this.section } }).then(function () {
                self.$overlay.hide()
                self.moreItemOptions = false
            })
        },

        updateSortedHash() {
            console.warn('updateSortedHash Section')
            this.sortedHash = false
            if (this.section && this.section.items) {
                var items = Object.values(Object.assign({}, this.section.items))
                items.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))
                this.sortedHash = items.map((item) => {
                    return item.id
                })
            }
        },

        // SECTION METHODS
        deleteSection() {
            var self = this
            this.$popup.confirm({
                message: this.$t('template.general.popup_delete_item_msg'),
                textSave: this.$t('user.popup.delete_text_save'),
                callSave: function () {
                    self.$store.commit('template/deleteSection', { context: self.context })
                },
                callCancel: function () {
                    this.$popup.close()
                }
            })
        },

        // ITEM METHODS
        orderItems() {
            // ORDER ITEMS BY THE ORDER PROP
            if (this.section.items) {
                var items = Object.values(this.section.items)
                items.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))
                return items
            }
            return []
        },

        dragMove() {
            console.warn('dragMove Section')
            this.$store.commit('template/sort', { context: this.context, items: [...this.sortedHash] })
        }
    },
    created() {
        if (this.section && this.section.items) {
            var items = Object.values(Object.assign({}, this.section.items))
            items.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))
            this.sortedHash = items.map((item) => {
                return item.id
            })
        }
    },
    watch: {
        section: function (value) {
            if (value && value.items) {
                var items = Object.values(Object.assign({}, value.items))
                items.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))
                return items.map((item) => {
                    return item.id
                })
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.section-item:not(.normal) {
    > .info {
        .name {
            @include font-size(lg);
            color: $color-black;
            font-family: $text-bold;
            border: none !important;

            &.error {
                .v-input__slot {
                    textarea {
                        @include background($image: img('warning_error.svg'), $size: 15px, $position: right 12px center);
                        padding-right: 40px;

                        @include placeholder {
                            color: $color-error-500 !important;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
.section-item {
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(flex-start);
    margin-bottom: 30px;
    position: relative;

    .list-draggable,
    .list-helper {
        display: block;
        width: 100%;
        height: auto;
        position: relative;
        z-index: 1;

        .moving {
            cursor: grabbing !important;
        }
    }

    .moving {
        cursor: grabbing !important;
    }

    .info {
        .name {
            &.error::placeholder {
                color: $color-error-500 !important;
            }
        }
    }
}

.section-item:not(.normal) {
    .collapse {
        @include font-size(xs);
        display: inline-flex;
        @include align-items();
        top: 0px;
        margin-left: 35px;
        font-family: $text-bold;
        color: $color-black;
        padding: 2px;
        padding-left: 0;
        cursor: pointer;

        .arrow {
            display: inline-flex;
            @include background($size: 9px, $image: img('up_dark.svg'), $position: center center);
            width: 15px;
            height: 10px;
            transition-duration: $transition-duration;

            &.active {
                transform: rotate(-180deg);
            }
        }
    }

    .info {
        @include display-flex();
        @include justify-content(flex-start);
        @include align-items();
        padding-right: 20px;

        // .handle-section {
        //     width: 20px;
        //     height: 20px;
        //     @include background($size: 20px, $image: img('grab_neutro_s50.svg'), $position: left center);
        //     // @include background($size: 20px, $image: img('grab_arrows.svg'), $position: left center);
        // }
    }

    .list {
        @include justify-content();
        overflow: auto;
        position: relative;
        height: auto;
        width: calc(100% - 30px);
        margin-left: 30px;
        margin-top: 3px;
        padding: 30px 0px 30px 15px;
        border-radius: 4px;
        transition-duration: $transition-duration;
        border-left: 6px solid $color-neutral-600;

        &:after {
            @include border-radius(4px);
            content: '';
            width: calc(100% + 18px);
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba($color-neutral-300, 0.3);
            transition-duration: $transition-duration;
        }

        &:hover:after {
            // background-color: rgba($color-neutral-300, 0.6);
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &.collapsed {
            height: 0px;
            padding: 0;
        }
    }
}

.section-item.normal {
    .collapse {
        display: none;
    }

    > .info {
        display: none;
    }

    .list {
        // width: 100%;
        // height: auto;
        // margin: 0;
        // padding: 0;
    }
}

.more-button {
    margin: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 0;
    transition-duration: $transition-duration;
    background-color: transparent !important;
}

.more-options {
    @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-600, 0.3));
    @include border-radius(4px);
    @include display-flex();
    @include flex-direction(column);
    @include align-items(flex-end);
    position: absolute;
    right: 30px;
    top: 0px;
    z-index: $z-barActionMenus;
    padding: 3px 3px 0 3px;
    background-color: $color-neutral-200;

    .btn {
        box-shadow: unset;
        width: 100%;
        margin: 0 0 3px 0;
    }
}

.delete {
    right: 0px;
    top: 20px;
    margin: 0;
}
</style>
