<template>
    <div id="detail" class="report-template" :class="{ 'with-trial': trialEndDate }">
        <div class="bar-actions">
            <TranslateButton
                v-if="template"
                :entityId="'tpl.' + template.id"
                :translationLangs="template.translations"
                @newTranslation="newTranslation"
                @deleteTranslation="deleteTranslation"
            ></TranslateButton>
            <Button bType="cancel_changes" :bCallback="goBack"></Button>
            <Button
                v-if="template"
                bType="save"
                :bCallback="save"
                customClass="btn-action"
                :bCallbackProps="template.status"
                bLabel="template.general.button_save"
            ></Button>

            <template v-if="id">
                <Button bType="more" :bCallback="showMoreOptions"></Button>
                <div class="more-options" v-if="moreOptions">
                    <Button bType="convert" :bCallback="convert" customClass="btn-convert" bLabel="template.general.button_convert"></Button>
                    <Button bType="duplicate" :bCallback="clone" customClass="btn-duplicate" bLabel="template.general.button_clone"></Button>
                    <Button bType="delete" :bCallback="showConfirmTemplateDeleteModal" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
                </div>
            </template>
        </div>

        <div class="detail-container">
            <div class="main-content scrollbar scrollbar-transparent">
                <template v-if="template">
                    <div class="template-info">
                        <!-- TEMPLATE TITLE -->
                        <TranslateInput
                            :translation_keyname="'tpl.' + template.id + '.title'"
                            :custom_config="['get_$event']"
                            _type="textarea"
                            maxLength="253"
                            :value="template.name"
                            v-on:input="
                                resizeTextarea($event)
                                template.name = $event.target.value
                            "
                            :_placeholder="$t('template.general.title_empty')"
                            @click="unselectItem()"
                            :_class="[
                                'integrated',
                                'name',
                                { 'error-field': template.errors && template.errors.codes && template.errors.codes.includes('empty_name') }
                            ]"
                            rows="1"
                            @input="updateTemplate()"
                        ></TranslateInput>
                        <!-- <textarea
                            class="integrated name"
                            :value="template.name"
                            v-on:input="
                                resizeTextarea($event)
                                template.name = $event.target.value
                            "
                            :placeholder="$t('template.general.title_empty')"
                            @click="
                                unselectItem()
                                resizeTextarea($event)
                            "
                            :class="{ 'error-field': template.errors && template.errors.codes && template.errors.codes.includes('empty_name') }"
                            rows="1"
                            @input="updateTemplate()"
                        >
                        </textarea> -->
                        <TranslateInput
                            :translation_keyname="'tpl.' + template.id + '.description'"
                            :custom_config="['get_$event']"
                            _type="textarea"
                            :value="template.description"
                            v-on:input="
                                resizeTextarea($event)
                                template.description = $event.target.value
                            "
                            :_placeholder="$t('template.general.item_description_empty')"
                            @click="
                                unselectItem()
                                resizeTextarea($event)
                            "
                            :_class="[
                                'integrated',
                                'description',
                                { 'error-field': template.errors && template.errors.codes && template.errors.codes.includes('empty_name') }
                            ]"
                            rows="1"
                            @input="updateTemplate()"
                        ></TranslateInput>
                        <!-- <textarea
                            class="integrated description"
                            :class="{ 'error-field': template.errors && template.errors.codes && template.errors.codes.includes('empty_description') }"
                            :value="template.description"
                            v-on:input="
                                resizeTextarea($event)
                                template.description = $event.target.value
                            "
                            @click="
                                unselectItem()
                                resizeTextarea($event)
                            "
                            @input="updateTemplate()"
                            auto-grow
                            solo
                            flat
                            dense
                            rows="1"
                            hide-details
                            :placeholder="$t('template.general.item_description_empty')"
                        >
                        </textarea> -->
                    </div>

                    <template v-if="template && template.sections && Object.keys(template.sections).length > 0">
                        <draggable class="content-draggable" v-model="sortedHash" v-bind="dragOptions" @end="dragMove()" :group="{ name: 'sections' }">
                            <transition-group class="content-helper">
                                <!-- QUITAR "typeof section.status == 'undefined'" CUANDO SE DEVUELVA DE API EL STATUS DE LAS SECTIONS -->
                                <template v-for="(section, section_index) in orderSections()">
                                    <Section
                                        ref="sections"
                                        v-if="typeof section.status == 'undefined' || section.status > 0"
                                        :context="{
                                            template_id: template.id,
                                            section_id: section.id,
                                            item_id: false,
                                            condition_id: false
                                        }"
                                        :section="section"
                                        :key="'s_' + section.id"
                                        :isNoSection="activeSections.length == 1 && section.id == '-1'"
                                        class="custom-section"
                                    ></Section>
                                </template>
                            </transition-group>
                        </draggable>
                    </template>
                    <addEntity
                        :context="{
                            template_id: template.id,
                            section_id: false,
                            item_id: false,
                            condition_id: false
                        }"
                        :emptyNoSection="template.type == 2 ? false : emptyNoSection"
                        :emptySections="emptySections"
                        :isNoSection="activeSections.length == 1 && activeSections[0].id == '-1'"
                        @newItem="updateSectionSortedHash"
                    ></addEntity>
                </template>
            </div>

            <template v-if="template">
                <TemplateConfig
                    :context="{
                        template_id: template.id,
                        section_id: false,
                        item_id: false,
                        condition_id: false
                    }"
                    :template="template"
                    :templateIsTemporal="templateIsTemporal"
                />

                <transition name="slide-fade">
                    <template v-if="selectedContext">
                        <ItemConfig
                            :context="selectedContext"
                            :templateStatus="template.status"
                            :periodicity="[TYPE.TASK].includes(template.type)"
                            :score="template.type == 2"
                            :review="[TYPE.TASK].includes(template.type)"
                            :roleLimit="roleLimit"
                            :templateType="template.type"
                            :locationLimit="sitesLimit.locationLimit"
                            :groupLimit="sitesLimit.groupLimit"
                            :templateIsTemporal="templateIsTemporal"
                            :templateName="template.name"
                        />
                    </template>
                </transition>
            </template>
        </div>
    </div>
</template>

<script>
import { TYPE } from '@/constants'
import draggable from 'vuedraggable'
// PRINCIPAL COMPONENTS
import Section from '@/components/domain/templates/section.vue'
import TemplateConfig from '@/components/domain/templates/template/config.vue'
import ItemConfig from '@/components/domain/templates/item/config.vue'
import addEntity from '@/components/domain/templates/template/addEntity.vue'

export default {
    components: {
        draggable,
        Section,
        TemplateConfig,
        ItemConfig,
        addEntity
    },
    name: 'NewDetail',
    props: {
        id: { default: false }
    },
    data() {
        return {
            // VIEW VARIABLES OF CONTROL
            moreOptions: false,
            TYPE: TYPE,
            sortedHash: [],
            loaded: false,
            emptySections: true,
            emptyNoSection: false,
            activeSections: [],

            oldTemplate: {},
            exitSaving: false,
            hasChanges: false
        }
    },
    computed: {
        dragOptions() {
            // DRAG SECTION OPTIONS
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'moving'
            }
        },

        template() {
            // GET TEMPLATE DATA
            if (this.$route.params.id) {
                var template = {
                    ...this.$store.getters['template/getTemplate'](this.$route.params.id)
                }
            } else {
                var template = { ...this.$store.getters['template/getTemplate'](-1) }
            }
            if (Object.keys(template).length > 0) {
                if (template.sections && (this.sortedHash.length == 0 || this.sortedHash.length != Object.keys(template.sections).length)) {
                    this.sortedHash = Object.keys({ ...template.sections }).sort(function (a, b) {
                        return a.order < b.order ? -1 : 1
                    })
                }

                if (!this.loaded && template && template.sections) {
                    this.loaded = true
                    this.oldTemplate = JSON.parse(JSON.stringify({ ...template }))
                }

                // SEARCH IF HAS A SECTION ACTIVE
                if (template.sections) {
                    this.emptyNoSection = true
                    var result = Object.values(template.sections).filter((section) => {
                        if (section.id == -1) {
                            var itemsActive = Object.values(section.items).filter((item) => {
                                return item.status > -1
                            })
                            if (itemsActive.length > 0) this.emptyNoSection = false
                            return itemsActive.length > 0
                        } else {
                            return section.status > -1
                        }
                    })
                    // if (template.sections[-1]) {
                    //     typeof template.sections[-1] == undefined || template.sections[-1].items.length > 0 ? (this.emptyNoSection = true) : (this.emptyNoSection = false)
                    // } else {
                    //     this.emptyNoSection = true
                    // }
                    this.activeSections = result
                    result.length > 0 ? (this.emptySections = false) : (this.emptySections = true)
                }

                return template
            }
        },

        selectedContext() {
            // GET ACTIVE SELECTED ITEM
            return this.$store.getters['template/getSelectedContext']
        },

        sitesLimit() {
            // DEFINE LA RESTRICCION DE LOCATIONS Y GROUPS DE SU ENTIDAD SUPERIOR
            var result = { locationLimit: [], groupLimit: [] }

            // ENTITY es la entidad que dice la limitacion de locations/groups que tiene sus hijos. En los subitems es el item padre y en los items padres es el template
            var entity = this.template

            if (this.selectedContext) {
                // Si el item seleccionado es un subitem:
                if (this.selectedContext.item_parent_id) {
                    var parentItemContext = { ...this.selectedContext }
                    parentItemContext.item_id = parentItemContext.item_parent_id
                    parentItemContext.item_parent_id = false
                    var parentItem = { ...this.$store.getters['template/getItem'](parentItemContext) }

                    if (!(typeof parentItem.locations == 'object' && typeof parentItem.groups == 'object') && (parentItem.locations || parentItem.groups))
                        entity = parentItem
                }
            }
            // ASIGNATION
            typeof entity.locations == 'string' && entity.locations != '' ? (result.locationLimit = entity.locations.split(',')) : true
            typeof entity.groups == 'string' && entity.groups != '' ? (result.groupLimit = entity.groups.split(',')) : true
            return result
        },

        roleLimit() {
            // DEFINE LA RESTRICCION DE LOCATIONS Y GROUPS DE SU ENTIDAD SUPERIOR
            // ENTITY es la entidad que dice la limitacion de roles que tiene sus hijos. En los subitems es el item padre y en los items padres es el template
            var entity = this.template

            if (this.selectedContext) {
                // Si el item seleccionado es un subitem:
                if (this.selectedContext.item_parent_id) {
                    var parentItemContext = { ...this.selectedContext }
                    parentItemContext.item_id = parentItemContext.item_parent_id
                    parentItemContext.item_parent_id = false
                    entity = { ...this.$store.getters['template/getItem'](parentItemContext) }
                }
            }
            return entity.config && entity.config.roles && entity.config.roles.length > 0 ? entity.config.roles : []
        },

        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },

        // Temporal Templates Flag:
        temporalTemplatesIsAvailable() {
            const flag = parseInt(this.$store.getters['account/getTemporalTemplatesFlag'])

            if (flag === 1) {
                return true
            } else return false
        },

        templateIsTemporal() {
            if (!this.temporalTemplatesIsAvailable) {
                return false
            }

            const newTemplateIsTemporal = this.$store.getters['template/getNewIsTemporal']
            const currentTemplateIsTemporal = this.template.config.hasOwnProperty('temporary')

            return newTemplateIsTemporal || currentTemplateIsTemporal
        }
    },
    methods: {
        newTranslation(code) {
            this.$set(this.template.translations, code, { missing: 0, translated: 0 })
        },

        deleteTranslation(code) {
            this.$delete(this.template.translations, code)
        },

        updateSectionSortedHash() {
            if (this.activeSections.length == 1 && this.activeSections[0].id == '-1') {
                this.$refs['sections'][0].updateSortedHash()
            }
        },

        // GENERAL FORM FUNCTIONS METHODS
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },

        updateTemplate() {
            this.$store.commit('template/updateTemplate', { context: { template_id: this.template.id }, template: { ...this.template } })
        },

        resizeTextarea(event) {
            // event.target.style.height = 'auto'
            // event.target.style.height = event.target.scrollHeight + 5 + 'px'

            // para que solo se agrande la caja cuando el texto continúa en una segunda línea:
            if (event.target.scrollHeight > event.target.clientHeight) {
                event.target.style.height = event.target.scrollHeight + 5 + 'px'
            }
        },

        unselectItem() {
            this.$store.commit('template/setSelectedContext', false)
        },

        goBack(isSave = !this.hasChanges) {
            // EMIT GO BACK
            this.$overlay.show()
            this.$emit('back', isSave)
        },

        orderSections() {
            // ORDER ITEMS BY THE ORDER PROP
            var sections = Object.values(this.template.sections)
            sections.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))
            return sections
        },

        clone() {
            let self = this
            this.$store.dispatch('template/validateTemplate', { context: { template_id: self.template.id } }).then(function (response) {
                if (response == false) {
                    self.$popup.alert({
                        title: self.$t('template.general.popup_validation_error_title'),
                        textSave: self.$t('template.general.review'),
                        message: self.$t('template.general.popup_validation_error_msg')
                    })
                } else {
                    self.$popup.confirm({
                        message: self.$t('template.general.popup_clone'),
                        textSave: self.$t('template.general.popup_button_clone'),
                        callSave: function () {
                            self.$store.dispatch('template/save', { template: self.template }).then(() => {
                                var sTemplateName = self.template.name
                                self.$store.dispatch('template/clone', { template_id: self.$route.params.id }).then(function (template_id) {
                                    if (template_id) {
                                        self.$store.dispatch('template/loadTemplates', { type: 1, data: {} }).then(function (response) {
                                            self.$router.push({
                                                name: self.$route.name,
                                                params: { id: template_id, save: true }
                                            })
                                            self.moreOptions = false
                                            self.$snackbar.open({
                                                message: self.$t('template.clone.view_clone') + ' ' + sTemplateName,
                                                customClass: 'success',
                                                duration: 5000
                                            })
                                        })
                                    }
                                })
                            })
                        },
                        callCancel: function () {
                            self.$popup.close()
                        }
                    })
                }
            })
        },

        convert() {
            if (this.template.status !== '-2') {
                this.$popup.messageWithButton({
                    title: this.$t('popup.templateConvertTo.title'),
                    message: this.$t('popup.templateConvertTo.error_message')
                })
                return
            }

            const self = this
            const tools = {
                1: this.$t('tools.template_sub.checklist_title'),
                3: this.$t('tools.template_sub.records_title'),
                4: this.$t('tools.template_sub.issue_title'),
                2: this.$t('tools.template_sub.audits_title')
            }
            this.$popup.templateConvertTo({
                tool: this.template.type,
                callSave: function (e) {
                    const toolSelected = e.$children[0].selectedTool
                    if (!toolSelected) {
                        return
                    } else {
                        self.$popup.close()

                        self.$popup.confirm({
                            title: self.$t('popup.templateConvertTo.ok_question'),
                            message: self.$t('popup.templateConvertTo.ok_message', { type: tools[toolSelected], name: tools[self.template.type] }),
                            textSave: self.$t('popup.templateConvertTo.button_save'),
                            textCancel: self.$t('popup.templateConvertTo.button_cancel'),
                            customClass: 'convert-to',

                            callSave: function () {
                                self.$store.dispatch('template/covertTemplateTo', { template_id: self.template.id, tool: toolSelected }).then((response) => {
                                    const toolPath = {
                                        1: 'Checklist',
                                        3: 'Record',
                                        4: 'Issue',
                                        2: 'Audit'
                                    }

                                    self.$popup.close()
                                    self.$router.push({
                                        name: toolPath[toolSelected] + 'Detail',
                                        params: { id: response.template, save: true }
                                    })
                                })
                            }
                        })
                    }
                }
            })
        },

        // TEMPLATE METHODS
        load() {
            this.$overlay.show()
            const self = this
            let templateType

            if (this.$route.name == 'TemplateChecklist') {
                if (this.id) {
                    this.$router.push({
                        name: 'ChecklistDetail',
                        params: { id: this.id, save: true }
                    })
                } else {
                    this.$router.push({
                        name: 'AddChecklistDetail',
                        params: { save: true }
                    })
                    templateType = 1
                }
            } else if (this.$route.name == 'TemplateAudit') {
                if (this.id) {
                    this.$router.push({
                        name: 'AuditDetail',
                        params: { id: this.id, save: true }
                    })
                } else {
                    this.$router.push({ name: 'AddAuditDetail', params: { save: true } })
                    templateType = 2
                }
            } else if (this.$route.name == 'TemplateRecord') {
                if (this.id) {
                    this.$router.push({
                        name: 'RecordDetail',
                        params: { id: this.id, save: true }
                    })
                } else {
                    this.$router.push({
                        name: 'AddRecordDetail',
                        params: { save: true }
                    })
                    templateType = 3
                }
            } else if (this.$route.name == 'TemplateIssue') {
                if (this.id) {
                    this.$router.push({
                        name: 'IssueDetail',
                        params: { id: this.id, save: true }
                    })
                } else {
                    this.$router.push({ name: 'AddIssueDetail', params: { save: true } })
                    templateType = 4
                }
            } else if (this.$route.name == 'TemplateActionplan') {
                if (this.id) {
                    this.$router.push({
                        name: 'ActionplanDetail',
                        params: { id: this.id, save: true }
                    })
                } else {
                    this.$router.push({ name: 'AddActionplanDetail', params: { save: true } })
                    templateType = 5
                }
            } else {
                templateType = this.$route.meta.templateType
            }

            //Check if the template is from Audit
            let isAudit = null
            if (this.$route.name === 'ActionplanDetail') {
                isAudit = 'ActionplanDetail'
            }else if (this.$route.name === 'AddActionplanDetail') {
                isAudit = 'AddActionplanDetail'
            } else if (this.$route.name === 'AuditDetail') {
                isAudit = 'AuditDetail'
            } else if (this.$route.name === 'AddAuditDetail') {
                isAudit = 'AddAuditDetail'
            }

            // EDIT TEMPLATE
            if (this.$route.params.id) {
                this.$store
                    .dispatch('template/loadTemplate', {
                        template_id: this.$route.params.id
                    })
                    .then(function (response) {
                        var idx = self

                        if(isAudit == 'ActionplanDetail' || isAudit == 'AddActionplanDetail' || isAudit == 'AuditDetail' || isAudit == 'AddAuditDetail'){
                            self.$store.dispatch('template/loadActionplanTemplates', { isAudit: false }).then(function () {
                                idx.$overlay.hide()
                            })
                        }else{
                            self.$store.dispatch('template/loadIssueTemplates', { isAudit: isAudit }).then(function () {
                                idx.$overlay.hide()
                            })
                        }
                    })
            }
            // NEW TEMPLATE
            else {
                this.$store.dispatch('template/loadIssueTemplates', { isAudit: isAudit }).then(function () {
                    self.$store.commit('template/createNewTemplate', {
                        type: templateType
                    })
                    self.$overlay.hide()
                })
            }
        },

        save(mode) {
            this.$overlay.loading()
            // this.updateTemplate()
            var self = this
            var emptyItems = true

            if (Object.keys(this.template.sections).length > 0) {
                var n = 0
                while (emptyItems && n < Object.keys(this.template.sections).length) {
                    var section = { ...this.template.sections[Object.keys(this.template.sections)[n]] }
                    if (section.status > -1) {
                        var itemsActive = Object.values(section.items).filter((item) => {
                            return item.status > -1
                        })
                        emptyItems = itemsActive.length == 0
                    }
                    n++
                }
            }

            if (emptyItems) {
                self.$popup.alert({
                    title: self.$t('template.general.popup_empty_template_title'),
                    textSave: self.$t('template.general.review'),
                    message: self.$t('template.general.popup_empty_template_msg'),
                    callSave: () => {
                        self.$overlay.hide()
                    }
                })
            } else {
                this.$store.dispatch('template/validateTemplate', { context: { template_id: self.template.id } }).then(function (response) {
                    self.$overlay.hide()
                    if (response == false) {
                        self.$store.commit('template/setSelectedContext', false)
                        self.$popup.alert({
                            title: self.$t('template.general.popup_validation_error_title'),
                            textSave: self.$t('template.general.review'),
                            message: self.$t('template.general.popup_validation_error_msg')
                        })
                        scrollError()
                    } else {
                        var msg = ''
                        var bttn = ''

                        if (mode == 1) {
                            msg = self.$t('template.general.popup_new_to_published')
                            bttn = self.$t('template.general.button_save_publish')
                            self.template.status = 1
                        } else if (mode == -2) {
                            msg = self.$t('template.general.popup_published_to_draft')
                            bttn = self.$t('template.general.button_save_draft')
                            self.template.status = -2
                        } else if (mode == -3) {
                            msg = self.$t('template.general.popup_ended')
                            bttn = self.$t('template.general.button_save_ended')
                            self.template.status = -3
                        }
                        self.$popup.confirm({
                            message: msg,
                            textSave: bttn,
                            callSave: function () {
                                self.$snackbar.close()
                                self.$overlay.loading()
                                self.$store.dispatch('template/save', { template: self.template }).then(function (response) {
                                    self.$overlay.hide()
                                    if (response) {
                                        const type = {
                                            3: 'Records',
                                            2: 'Audits',
                                            1: 'Checklists',
                                            4: 'Issues'
                                        }
                                        self.$amplitude.track('Admin template creation', { tool: type[self.template.type] })
                                        setTimeout(() => {
                                            self.exitSaving = true
                                            self.$snackbar.open({
                                                message: self.$t('settings.edit_success'),
                                                customClass: 'success',
                                                duration: 5000
                                            })
                                        }, 500)
                                        self.$emit('back', true)
                                        // CLOSE UNSAVED CHANGES TOOLTIP
                                        if (self.$snackbar) {
                                            self.$snackbar.close()
                                        }
                                        self.$store.commit('template/setHasLoadFilters', true)
                                    }
                                })
                            },
                            callCancel: function () {
                                self.$popup.close()
                            }
                        })
                    }
                })
            }
        },

        deleteTemplate() {
            var self = this

            let snackbarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.template.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store
                .dispatch('template/removeTemplate', {
                    template_id: self.template.id
                })
                .then(function () {
                    setTimeout(() => {
                        self.$snackbar.open(snackbarData)
                    }, 500)
                    self.$store.commit('template/setHasLoadFilters', true)
                    self.goBack(true)
                })
        },

        showConfirmTemplateDeleteModal() {
            this.$popup.delete({ message: this.$t('template.general.popup_delete_template_msg'), callSave: this.deleteTemplate, requireTyping: true })
        },

        dragMove() {
            this.$store.commit('template/sort', {
                context: { template_id: this.template.id },
                items: this.sortedHash
            })
        }
    },
    created() {
        this.load()
    },

    beforeMount() {
        window.addEventListener('beforeunload', (event) => {
            if (!this.hasChanges) return
            event.preventDefault()
            event.returnValue = ''
        })
    },

    beforeDestroy: function () {
        this.oldTemplate = {}
        this.loaded = false
        this.hasChanges = false
        window.removeEventListener('beforeunload', (event) => {})
        if (!this.exitSaving) {
            this.$snackbar.close()
        }
        this.exitSaving = false

        // UNSELECT SELECTED CONTEXT
        if (this.selectedContext) {
            this.$store.commit('template/setSelectedContext', false)
        }
        this.$overlay.hide()
    },

    watch: {
        '$route.params.id': function () {
            this.$store.commit('template/setSelectedContext', false)
            this.load()
        },
        template: {
            handler(template) {
                if (this.loaded && this.template && JSON.stringify(template) != JSON.stringify({ ...this.oldTemplate })) {
                    this.hasChanges = true
                    this.$snackbar.open({
                        message: this.$t('snackbar.changes_unsaved'),
                        customClass: 'info',
                        duration: 5000,
                        closeable: false
                    })
                } else {
                    this.hasChanges = false
                    this.$snackbar.close()
                }
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
// ANIMATIONS
.slide-fade-enter-active {
    transition: all 3s ease;
}
.slide-fade-leave-active {
    transition: all 8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(500px);
    opacity: 0;
}

// GENERAL TEMPLATE STYLES
#detail.report-template {
    overflow: hidden;
    height: 100%;

    // VUETIFY PREVENT DEFAULT STYLES
    select {
        @include font-size('sm');
        color: $color-neutral-600;
        font-family: $text;
        padding: 0 6px;
        text-align-last: center;
        background-color: #fff;
    }

    .v-textarea {
        border: none;
        min-height: unset;
        .v-input__control {
            min-height: unset !important;
            .v-input__slot {
                min-height: unset;
            }

            textarea {
                min-height: unset;
                // line-height: 20px;
                padding-top: 2px;
                border: none;
                margin-top: 0px !important;

                &::placeholder {
                    color: $color-neutral-300;
                }
            }
        }
    }

    .v-input__slot {
        background-color: transparent !important;
        font-family: $text;
        color: $color-neutral-600;
    }

    input.integrated,
    .v-textarea.integrated {
        background-color: transparent !important;
        border-bottom: none;
        display: block;
        width: 100%;
    }

    // GENERAL TYPE STYLES
    .type {
        @include background($size: 25px, $position: top 5px center);
        overflow: hidden;
        display: inline-block;
        background-color: $color-neutral-200;
        width: 21.5%;
        height: 60px;
        border-radius: 6px;
        cursor: pointer;
        position: relative;

        &.error {
            background-color: $color-error-100;

            &:after {
                @include border-radius(100%);
                @include background($color: $color-error-500, $image: img('warning_error.svg'));
                content: '';
                position: absolute;
                top: 3px;
                right: 3px;
                display: block;
                width: 15px;
                height: 15px;
            }
        }

        .label {
            @include display-flex();
            @include justify-content();
            @include align-items();
            @include font-size(xs);
            font-family: $text-bold;
            color: $color-neutral-600;
            position: absolute;
            bottom: 2px;
            width: 100%;
            height: 28px;
            text-align: center;
        }

        &.no-selected {
            background-color: $color-neutral-600 !important;
        }

        &[data-type='1'] {
            @include background-image($image: img('format_check_inactive_s50.svg'));
        }
        &[data-type='2'],
        &[data-type='3'] {
            @include background-image($image: img('format_multiple_inactive_s50.svg'));
            background-size: 40px !important;
            background-position: top 8px center !important;
        }
        &[data-type='4'],
        &[data-type='10'],
        &[data-type='17'] {
            @include background-image($image: img('format_text_inactive_s50.svg'));
        }
        &[data-type='5'] {
            @include background-image($image: img('format_image_inactive_s50.svg'));
        }
        &[data-type='6'] {
            @include background-image($image: img('format_number_inactive_s50.svg'));
        }
        &[data-type='7'],
        &[data-type='12'],
        &[data-type='13'] {
            @include background-image($image: img('format_datetime_inactive_s50.svg'));
            background-size: 23px !important;
            background-position: top 6px center !important;
        }
        &[data-type='11'] {
            @include background-image($image: img('format_select_inactive_s50.svg'));
            background-size: 40px !important;
            background-position: top 10px center !important;
        }
        &[data-type='14'] {
            @include background-image($image: img('format_sign_inactive_s50.svg'));
        }
        &[data-type='15'] {
            @include background-image($image: img('format_temperature_inactive_s50.svg'));
            background-size: 22px !important;
            background-position: top 6px center !important;
        }
        &[data-type='16'] {
            @include background-image($image: img('format_mail_inactive_s50.svg'));
            background-position: top 8px center !important;
        }
        &[data-type='19'] {
            @include background-image($image: img('format_link_inactive_s50.svg'));
            background-size: 22px !important;
            background-position: top 8px center !important;
        }
        &[data-type='20'] {
            @include background-image($image: img('library_second_inactive_s50.svg'));
            background-size: 22px !important;
            background-position: top 8px center !important;
        }
        &[data-type='more'] {
            background-color: $color-neutral-300;
            @include background-image($image: img('more_hor_neutro_s70.svg'));

            .label {
                color: $color-black;
            }
        }
    }
    .item.selected > .item-body > .type,
    .type.selected {
        background-color: $color-primary-100;

        .label {
            color: $color-primary-500;
        }

        &[data-type='1'] {
            @include background-image($image: img('format_check_second_s30.svg'));
        }
        &[data-type='2'],
        &[data-type='3'] {
            @include background-image($image: img('format_multiple_second_s30.svg'));
        }
        &[data-type='4'],
        &[data-type='10'],
        &[data-type='17'] {
            @include background-image($image: img('format_text_second_s30.svg'));
        }
        &[data-type='5'] {
            @include background-image($image: img('format_image_second_s30.svg'));
        }
        &[data-type='6'] {
            @include background-image($image: img('format_number_second_s30.svg'));
        }
        &[data-type='7'],
        &[data-type='12'],
        &[data-type='13'] {
            @include background-image($image: img('format_datetime_second_s30.svg'));
        }
        &[data-type='11'] {
            @include background-image($image: img('format_select_second_s30.svg'));
        }
        &[data-type='14'] {
            @include background-image($image: img('format_sign_second_s30.svg'));
        }
        &[data-type='15'] {
            @include background-image($image: img('format_temperature_second_s30.svg'));
        }
        &[data-type='16'] {
            @include background-image($image: img('format_mail_second_s30.svg'));
        }
        &[data-type='19'] {
            @include background-image($image: img('format_link_second_s30.svg'));
        }
        &[data-type='20'] {
            @include background-image($image: img('library_second_s30.svg'));
        }
    }

    .delete {
        @include background($size: 14px, $image: img('delete_light.svg'), $position: center);
        @include display-flex();
        background-color: $color-error-500;
        width: 20px;
        height: 20px;
        float: right;
        position: absolute;
        cursor: pointer;
    }

    &.with-trial {
        height: calc(100% - 50px);
    }
}
</style>

<style lang="scss">
.detail-container {
    @include display-flex();
    @include align-items(stretch);
    padding-top: 76px;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: $z-top-content;

    // MAIN CONTENT STYLES
    .main-content {
        @include display-flex();
        @include flex-direction(column);
        // width: 75%;
        // max-width: calc(100% - 500px);
        // min-width: calc(100% - 400px);
        margin-right: 6px;
        width: calc(100% - 400px - 6px);
        padding: 0px 32px 50px 32px;
        overflow-x: hidden;

        input.integrated {
            background-color: transparent !important;
            border-bottom: none;
            display: block;
            width: 100%;
        }

        .template-info {
            width: 100%;
            margin-bottom: 1em;

            input.integrated::placeholder {
                color: $color-neutral-300;
            }

            .name {
                @include font-size('xl');
                font-family: $text-bold;
                color: $color-black;
                width: 100%;
                border: none !important;
                height: 60px;
                padding-left: 0px !important;

                &.error-field {
                    @include background($image: img('warning_error.svg'), $size: 15px, $position: right 12px center);
                    padding-right: 30px;

                    @include placeholder {
                        color: $color-error-500;
                    }
                }
            }

            .description {
                @include font-size('ml');
                background-color: transparent !important;
                padding-left: 10px;
                font-family: $text;
                border: none;
                max-width: 100%;
                width: 100%;
                padding-left: 0px !important;

                max-height: 90px;

                .v-input__slot {
                    background-color: transparent !important;
                    font-family: $text;
                    color: $color-neutral-600;

                    textarea {
                        @include font-size('ml');
                        font-family: $text;
                        color: $color-neutral-600;
                    }
                }

                &.error-field {
                    @include background($image: img('warning_error.svg'), $size: 15px, $position: right 12px center);
                    padding-right: 30px;

                    @include placeholder {
                        color: $color-error-500;
                    }
                }
            }
        }

        .content-draggable,
        .content-helper {
            display: block;
            width: 100%;
            height: auto;
            .translateInput {
                overflow: hidden;
            }
            .custom-section {
                .template-action .email .content .info .row {
                    padding-top: 10px;
                    padding-bottom: 20px;
                    .v-select.v-select--chips.v-select--chips--small .v-select__selections {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
</style>
