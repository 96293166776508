<template>
    <div class="sidebar-right item-config">
        <div class="name">{{ $t('template.general.item_properties') }}</div>
        <div class="configs">
            <div class="config main active format translate-disable">
                <Format :context="context" :templateStatus="templateStatus"></Format>
                <Options v-if="FORMAT_CAT.OPTIONS.children.includes(parseInt(item.type))" :item="item" :config="false" @sendItemType="setItemType"></Options>
                <Texts v-if="FORMAT_CAT.TEXT.children.includes(parseInt(item.type))" :item="item" :config="false" @sendItemType="setItemType"></Texts>
                <Selector
                    v-if="FORMAT_CAT.SELECTOR.children.includes(parseInt(item.type))"
                    :context="{
                        template_id: context.template_id,
                        section_id: context.section_id,
                        item_id: context.item_id,
                        condition_id: context.condition_id,
                        item_parent_id: context.item_parent_id
                    }"
                    :item="item"
                    :config="item.config"
                    @sendItemType="setItemType"
                ></Selector>
                <Photo v-if="FORMAT_CAT.PHOTO.children.includes(parseInt(item.type))" :item="item" :config="item.config" @sendItemType="setItemType"></Photo>
                <Numeric
                    v-if="FORMAT_CAT.NUMBER.children.includes(parseInt(item.type))"
                    :item="item"
                    :config="item.config"
                    @sendItemType="setItemType"
                ></Numeric>
                <Signature
                    v-if="FORMAT_CAT.SIGNATURE.children.includes(parseInt(item.type))"
                    :item="item"
                    :config="item.config"
                    @sendItemType="setItemType"
                ></Signature>
                <Temperature
                    v-if="FORMAT_CAT.TEMPERATURE.children.includes(parseInt(item.type))"
                    :item="item"
                    :config="item.config"
                    @sendItemType="setItemType"
                ></Temperature>
                <Date v-if="FORMAT_CAT.DATE.children.includes(parseInt(item.type))" :item="item" :config="item.config" @sendItemType="setItemType"></Date>
                <Email v-if="FORMAT_CAT.EMAIL.children.includes(parseInt(item.type))" :item="item" :config="item.config" @sendItemType="setItemType"></Email>
                <Link v-if="FORMAT_CAT.LINK.children.includes(parseInt(item.type))" :item="item" :config="item.config" @sendItemType="setItemType"> </Link>
                <!-- LIBRARY RESOURCE | COMENTED FOR RELEASE. See constants.js too  !!!!!!!!!!!!!!!! SEARCH FOR RESOURCE.children !!!!!!!!!!!!!! -->
                <Resource
                    v-if="FORMAT_CAT.RESOURCE.children.includes(parseInt(item.type))"
                    :hiddable="false"
                    :item="item"
                    :config="item.config"
                    :resource_id="item.resource_id"
                    @setResource="setResource"
                >
                </Resource>
            </div>

            <template v-if="!FORMAT_CAT.RESOURCE.children.includes(parseInt(item.type))">
                <!-- PERIODICITY (ONLY CHECKLISTS) -->
                <Periodicity v-if="periodicity && !templateIsTemporal" :item="item" @sendItemPeriodicity="setItemPeriodicity" />

                <!-- REVIEW -->
                <Review
                    v-if="review"
                    :review_roles="item.config && item.config.review_roles ? item.config.review_roles : false"
                    @sendItemReviewRoles="setItemReviewRoles"
                    :context="{
                        template_id: context.template_id,
                        section_id: context.section_id,
                        item_id: context.item_id,
                        condition_id: context.condition_id,
                        item_parent_id: context.item_parent_id
                    }"
                />

                <!-- SCORE (ONLY AUDITS) -->
                <!-- LIBRARY RESOURCE | COMENTED FOR RELEASE. See constants.js too  !!!!!!!!!!!!!!!! SEARCH FOR RESOURCE.children !!!!!!!!!!!!!! -->
                <!-- v-if="score" -->
                <Score v-if="score" :score="scoreItem" />
            </template>

            <!-- ROLES -->
            <div class="config main inline translate-hide" :class="{ active: sectionRoles }">
                <div class="name main check">
                    <div class="collapse" :class="{ collapsed: collapse.indexOf('sectionRoles') == -1 }"></div>
                    {{ $t('template.general.role_powers') }}
                    <input class="switch-input" type="checkbox" v-model="sectionRoles" :value="true" @click.stop @change="sectionRolesSelector()" />
                </div>
                <div class="description" v-html="$t('template.general.role_powers_description_item')"></div>

                <div class="role" v-if="sectionRoles">
                    <div class="error-msg" v-if="item.errors && item.errors.codes && item.errors.codes.includes('roles_no_match')">
                        {{ $t('template.general.roles.error_notmatch') }}
                    </div>

                    <v-autocomplete
                        hide-details
                        class="select"
                        v-model="item.config.roles"
                        :items="Object.values(availableRoles)"
                        item-text="name"
                        item-value="id"
                        multiple
                        attach
                        dense
                        :class="{ error: item.errors && item.errors.codes && item.errors.codes.includes('empty_roles') }"
                        :no-data-text="$t('supervise.filter.no_results')"
                        @change="item = item"
                    >
                        <template v-slot:selection="{ item, index }">
                            {{ index == 0 ? '' : '' }}
                        </template>
                    </v-autocomplete>
                    <Tags
                        v-if="item.config.roles"
                        :tags="item.config.roles"
                        :availableEntities="Object.keys(availableRoles)"
                        :referenceObject="roles"
                        :nameTag="'name'"
                        value="id"
                        :removable="true"
                    ></Tags>
                </div>
            </div>

            <!-- SITE/GROUP LIMIT -->
            <div class="config main inline sitelimit translate-hide" :class="{ active: sitelimit }">
                <div class="name main check">
                    <div class="collapse" :class="{ collapsed: collapse.indexOf('sitelimit') == -1 }"></div>
                    {{ $t('template.general.site_limit.title') }}
                    <input class="switch-input" type="checkbox" v-model="sitelimit" :value="true" @click.stop @change="siteLimitSelector()" />
                </div>
                <div class="config-content" :class="{ collapsed: collapse.indexOf('sitelimit') > -1 }">
                    <div class="error-msg" v-if="item.errors && item.errors.codes && item.errors.codes.includes('empty_sitelimit')">
                        {{ $t('template.general.site_limit.error') }}
                    </div>
                    <template v-if="sitelimit">
                        <div
                            class="must"
                            :class="{ error: item.errors && item.errors.codes && item.errors.codes.includes('empty_sitelimit') }"
                            v-html="$t('template.general.site_limit.must_description')"
                        ></div>
                        <div class="description" v-html="$t('template.general.site_limit.description_item')"></div>
                        <div class="error-msg" v-if="item.errors && item.errors.codes && item.errors.codes.includes('locations_no_match')">
                            {{ $t('template.general.site_limit.error_notmatch') }}
                        </div>
                        <SiteLimit
                            v-if="sitelimit"
                            @setSiteLimit="setSiteLimit"
                            :locations="item.locations"
                            :groups="item.groups"
                            :locationLimit="locationLimit.length > 0 ? locationLimit : false"
                            :groupLimit="groupLimit.length > 0 ? groupLimit : false"
                            :definedMode="limitmode ? limitmode : ''"
                        ></SiteLimit>
                    </template>
                    <template v-else-if="!sitelimit && entitiesLimit.length > 0">
                        <div class="description" v-html="$t('template.general.site_limit.heredated_locations')"></div>
                        <Tags
                            :tags="entitiesLimit"
                            :nameTag="'name'"
                            value="id"
                            :removable="false"
                            :limit="-1"
                            :moreliteral="'template.general.site_limit.view_locations'"
                            :lessliteral="'template.general.site_limit.hide_locations'"
                        ></Tags>
                    </template>
                    <template v-else>
                        <div class="description" v-html="$t('template.general.site_limit.description_item_unlimited')"></div>
                    </template>
                </div>
            </div>

            <!-- OPTIONAL (NO APLICA) -->
            <Optional
                :context="{
                    template_id: context.template_id,
                    section_id: context.section_id,
                    item_id: context.item_id,
                    condition_id: context.condition_id,
                    item_parent_id: context.item_parent_id
                }"
                :templateType="templateType"
            ></Optional>

            <!-- LIBRARY RESOURCE | COMENTED FOR RELEASE. See constants.js too  !!!!!!!!!!!!!!!! SEARCH FOR RESOURCE.children !!!!!!!!!!!!!! -->
            <Resource
                v-if="!FORMAT_CAT.RESOURCE.children.includes(parseInt(item.type))"
                :hiddable="true"
                :item="item"
                :config="item.config"
                :resource_id="item.resource_id"
                @setResource="setResource"
            >
            </Resource>

            <!-- ISSUE TITLE OPTION -->
            <Title
                v-if="templateType === 4 && [4, 15, 6, 11].includes(item.type)"
                :context="{
                    template_id: context.template_id,
                    section_id: context.section_id,
                    item_id: context.item_id,
                    condition_id: context.condition_id,
                    item_parent_id: context.item_parent_id
                }"
                :templateName="templateName"
            ></Title>
        </div>
    </div>
</template>

<script>
import { FORMAT_CAT } from '@/constants'

import Format from '@/components/domain/templates/item/format.vue'
import SiteLimit from '@/components/ui/SiteLimit'
import Tags from '@/components/ui/Tags'

// ITEM TYPE CONFIGURATIONS
import Options from '@/components/domain/templates/types/Options.vue'
import Texts from '@/components/domain/templates/types/Text.vue'
import Selector from '@/components/domain/templates/types/Selector.vue'
import Photo from '@/components/domain/templates/types/Photo.vue'
import Numeric from '@/components/domain/templates/types/Numeric.vue'
import Signature from '@/components/domain/templates/types/Signature.vue'
import Temperature from '@/components/domain/templates/types/Temperature.vue'
import Date from '@/components/domain/templates/types/Date.vue'
import Email from '@/components/domain/templates/types/Email.vue'
import Link from '@/components/domain/templates/types/Link.vue'
import Resource from '@/components/domain/templates/types/Resource.vue'
import Periodicity from '@/components/domain/templates/periodicity/Periodicity.vue'
import Review from '@/components/domain/templates/review/Review.vue'
import Optional from '@/components/domain/templates/optional/Optional.vue'
import Score from '@/components/domain/templates/score/Score.vue'
import Title from '@/components/domain/templates/title/Title.vue'

export default {
    components: {
        SiteLimit,
        Tags,
        Format,
        Options,
        Texts,
        Selector,
        Photo,
        Numeric,
        Signature,
        Temperature,
        Date,
        Email,
        Link,
        Resource,
        Periodicity,
        Review,
        Optional,
        Score,
        Title
    },
    name: 'ItemConfig',
    props: {
        context: { type: Object, default: {} },
        templateStatus: {},
        periodicity: {},
        review: {},
        score: {},
        locationLimit: { type: Array, default: [] },
        groupLimit: { type: Array, default: [] },
        roleLimit: { type: Array, default: [] },
        templateType: { type: Number, default: false },
        templateIsTemporal: { type: Boolean, default: false },
        templateName: { type: String, default: '' }
    },
    data() {
        return {
            // GENERAL VARIABLES
            collapse: [],
            FORMAT_CAT: FORMAT_CAT,
            sitelimit: false,
            sectionRoles: false,
            limitmode: ''
        }
    },
    computed: {
        item: {
            get() {
                var item = { ...this.$store.getters['template/getItem'](this.context) }
                return item
            },
            set(value) {
                this.$store.commit('template/updateItem', { context: this.context, item: value })
            }
        },

        scoreItem() {
            return this.item.points ? this.item.points : { total: 0, conditions: [] }
        },

        entitiesLimit() {
            var result = []
            if (this.locationLimit.length > 0) {
                for (var location_index in this.locationLimit) {
                    var location = { ...this.$store.getters['location/getListTable'](this.locationLimit[location_index]) }
                    result.push(location)
                }
            } else if (this.groupLimit.length > 0) {
                for (var group_index in this.groupLimit) {
                    var group = { ...this.$store.getters['groups/getList'](this.groupLimit[group_index]) }
                    result.push(group)
                }
            }
            return result
        },

        roles() {
            return this.$store.getters['employee/getSameLowerLevelRoleUser']
        },

        availableRoles() {
            if (this.roleLimit.length > 0) {
                var result = {}
                this.roleLimit.forEach((element) => {
                    result[element] = this.roles[element]
                })

                return result
            }
            return this.roles
        }
    },
    methods: {
        collapseSection(section) {
            var aCollapse = this.collapse
            if (aCollapse.indexOf(section) > -1) {
                aCollapse.splice(aCollapse.indexOf(section), 1)
            } else {
                aCollapse.push(section)
            }
        },
        sectionRolesSelector() {
            var item = { ...this.item }
            var aCollapse = this.collapse
            aCollapse.splice(aCollapse.indexOf('sectionRoles'), 1)

            if (this.sectionRoles == false) {
                item.config.roles = false
                this.item = item
            }
        },

        setItemType(data) {
            var item = { ...this.item }

            var review_roles = item.config && item.config.review_roles ? item.config.review_roles : false

            item.type = data.type
            // this.template.schema[this.itemSelected.id].type = data.type
            if (data.config) {
                item.config = data.config
            }

            if (review_roles) {
                this.setItemReviewRoles(review_roles)
            } else this.item = item
        },

        setItemReviewRoles(data) {
            var item = { ...this.item }

            if (!item.config) item.config = {}

            item.config.review_roles = data

            this.item = item
        },

        setResource(data) {
            var item = { ...this.item }
            // item.type = data.type
            // this.template.schema[this.itemSelected.id].type = data.type
            if (FORMAT_CAT.RESOURCE.children.includes(parseInt(data.type))) {
                item.config = data.config
            }
            item.resource_id = data.resource_id

            this.item = item
        },

        setItemPeriodicity(data) {
            var item = { ...this.item }

            item.periodicity = data.periodicity
            if (data.planification) {
                item.planification = data.planification
            } else item.planification = {}

            this.item = item
        },

        // SITE/GROUP LIMIT
        siteLimitSelector() {
            var item = { ...this.item }
            var aCollapse = this.collapse
            aCollapse.splice(aCollapse.indexOf('sitelimit'), 1)

            if (this.sitelimit == false) {
                item.groups = ''
                item.locations = ''
                this.limitmode = ''
            }

            this.item = item
        },
        setSiteLimit(data) {
            var item = { ...this.item }

            item.groups = data.groups
            item.locations = data.locations
            this.limitmode = data.mode
            if (data.mode) {
                this.sitelimit = true
            }

            this.item = item
        },
        load() {
            this.item['config'] = this.item.config ? this.item.config : {}
            if (
                this.item &&
                ((this.item.groups && this.item.groups != '') || (this.item.locations && this.item.locations != '') || (this.limitmode && this.limitmode == ''))
            ) {
                this.sitelimit = true
            }

            if (this.item && this.item.config && this.item.config.roles) {
                this.sectionRoles = true
            }
        }
    },
    created() {
        this.load()
    },
    watch: {
        item: {
            handler: function (val, oldVal) {
                if (oldVal.id != val.id) {
                    this.sitelimit = false
                }
                this.load()
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.sidebar-right.item-config {
    $main-title-height: 30px;

    @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-300, 1));
    overflow: hidden;
    width: 25%;
    max-width: 500px;
    min-width: 400px;
    // padding: 12px 24px 0px 24px;
    border-radius: 4px;
    transition: 0.2s;
    transition-timing-function: ease-in-out;
    position: relative;
    top: 40px;
    right: -500px;
    height: calc(100% - 40px);
    background-color: #fff;

    opacity: 0;

    // ESTILOS TEMPORALES HASTA QUE NO ESTEN LAS ANIMACIONES
    right: 0;
    top: 40px;
    opacity: 1;

    &.show {
        right: 0;
        top: 40px;
        opacity: 1;
    }

    > .name {
        height: $main-title-height;
    }

    .configs {
        @extend .scrollbar;
        @extend .scrollbar-white;
        overflow: auto;
        height: 100%;
        max-height: calc(100% - #{$main-title-height});
        padding-bottom: 30px;
        padding-right: $scroll-width;
    }

    .name {
        // @include display-flex();
        // @include justify-content();
        // @include font-size(ml);
        // font-family: $text;
        // color: $color-black;
        // display: flex;
        // flex-direction: row;
        // flex-wrap: wrap;
        // .switch-input {
        //     right: 0;
        //     position: absolute;
        // }
    }

    .config {
        @include display-flex();
        @include flex-direction(column);
        @include align-items('flex-start');
        // padding: 12px 0;
        padding-left: 6px;
        margin: 0;

        .checkbox {
            background: transparent;
        }

        // &.main:last-child {
        //     padding-bottom: 100px !important;
        // }

        .config-content {
            height: auto;
            transition: 0.2s;
            transition-timing-function: ease-in-out;
            overflow: initial;

            &.collapsed {
                overflow: hidden;
                max-height: 0px;
            }
        }

        .descriptionissue {
            .moreinfooption {
                color: $color-primary-500;
            }
        }

        // .name {
        //     @include justify-content(flex-start);
        //     @include font-size(sm);
        //     font-family: $text-bold;
        //     padding: 6px 0px;
        //     color: $color-black;

        &.main {
            // padding-bottom: 15px;
            // padding-left: 0;
            margin-bottom: 25px;
            width: 100%;
        }

        //     &.main {
        //         @include font-size(md);
        //     }

        //     &.check {
        //         @include display-flex();
        //         padding-right: 60px;
        //         width: 100%;
        //         flex-flow: nowrap;
        //         overflow: hidden;
        //         position: relative;
        //     }
        // }

        // .description {
        //     @include font-size(xs);
        //     font-family: $text-medium;
        //     color: $color-black;
        // }
        .sub-name {
            @include justify-content(flex-start);
            @include font-size(sm);
            margin-top: 5px;
            font-family: $text-bold;
            padding: 6px 0px;
            color: $color-black;
        }

        // .sub-description {
        //     @include justify-content(flex-start);
        //     @include font-size(xs);
        //     margin-bottom: 5px;
        //     font-family: $text-medium;
        //     color: $color-black;
        // }

        .config-cover {
            .cover {
                width: 100%;
                margin: 10px auto;
                position: relative;
                height: 80px;
                display: block;
                overflow: hidden;
                border-radius: 3px;
                cursor: pointer;

                input {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    opacity: 0;
                    display: block;
                    z-index: 2; //TODO Cambiar este z-index por variables porfa (doc. _variables.scss)
                    cursor: pointer;
                }

                .preview {
                    height: 100%;
                    width: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    cursor: pointer;

                    &.empty {
                        background-color: $color-neutral-200;

                        .empty-image {
                            position: absolute;
                            left: calc(50% - 20px);
                            top: calc(50% - 20px);
                            @include background($size: 40px, $image: img('empty_image_neutro_s10.svg'), $position: center center);
                            width: 40px;
                            height: 40px;
                        }

                        .empty-text {
                            @include font-size('s');
                            color: $color-neutral-600;
                            position: absolute;
                            width: 100%;
                            text-align: center;
                            bottom: 10px;
                            left: 0;
                        }
                    }
                }
            }

            .buttons-cover {
                margin: 0 10px 0 0;
            }
        }

        .types {
            .type {
                margin: 6px;

                .label {
                    color: $color-neutral-600;
                }

                &.selected {
                    .label {
                        color: #fff;
                    }
                }
            }
        }

        .periodicity {
            padding: 0 10px;
        }
    }
}
</style>
