<template>
    <div class="config translate-disable">
        <div class="name">{{ $t('template.general.types.title') }}</div>
        <div class="description" v-html="$t('template.general.types.link.description')"></div>
        <input class="standard" :placeholder="$t('template.general.types.link.placeholder')" :class="{ error: item.errors && item.errors.codes && item.errors.codes.includes('empty_config_url') }" v-model="changedConfig.url" />
    </div>
</template>

<script>
import { FORMAT } from '@/constants'

export default {
    components: {},
    props: {
        item: { type: Object },
        config: { type: [Object, Boolean], default: { url: '' } }
    },
    data() {
        return {
            FORMAT: FORMAT,
            changedType: false,
            changedConfig: false
        }
    },
    computed: {},
    methods: {
        load() {
            this.changedType = parseInt(this.item.type)
            this.changedConfig = this.config
        },

        sendValue() {
            this.$emit('sendItemType', { type: this.changedType, config: this.changedConfig })
        }
    },
    created() {
        this.load()
    },
    watch: {
        item: {
            handler: function (val, oldVal) {
                if (oldVal.id != val.id) {
                    this.load()
                }
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.config {
    .standard {
        // @include background(
        //     $size: 18px,
        //     $image: img("copy_neutro_s60.svg"),
        //     $position: right 10px center
        //   );
        @include font-size('sm');
        // background-color: $color-neutral-200;
        height: 35px;
        border-radius: 3px;
        padding: 5px;
        margin-top: 5px;
        margin-bottom: 10px;
        border: none;
        padding-right: 35px;
        border: 1px solid $color-neutral-300;

        &.error {
            border-color: $color-error-500;
        }
    }
}
</style>
