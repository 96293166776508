<template>
    <div class="config translate-hide" :class="{ 'main inline': hiddable, active: showSelect }">
        <div class="name" :class="{ 'main check': hiddable }">
            <div v-if="hiddable" class="collapse" :class="{ collapsed: collapse.indexOf('resource') == -1 }"></div>
            {{ $t('template.general.types.resource.title') }}
            <input
                v-if="hiddable"
                v-bind:id="showSelect"
                class="switch-input"
                type="checkbox"
                :key="showSelect"
                :checked="showSelect !== false"
                :false-value="false"
                :value="showSelect"
                @click.stop
                @click="switchResource()"
            />
        </div>
        <div class="config-content" :class="{ collapsed: hiddable && collapse.indexOf('resource') > -1 }">
            <div class="description" v-html="$t('template.general.types.resource.description')"></div>
            <v-autocomplete
                v-if="!hiddable || showSelect"
                :key="`resource-folder-${changedResourceFolder}`"
                hide-details
                dense
                class="select"
                :class="{ error: item.type == FORMAT.RESOURCE && item.errors && item.errors.codes && item.errors.codes.includes('empty_resource_id') }"
                @change="loadSelectResource()"
                v-model="changedResourceFolder"
                :items="folderResources"
                :item-text="(item) => item.category"
                item-value="category"
                :no-data-text="$t('supervise.filter.no_results')"
            >
                <template slot="item" slot-scope="{ item, title }">
                    <div id="resource-autocomplete-options">
                        <p class="category">{{ item.category }}</p>
                    </div>
                </template>
            </v-autocomplete>
            <v-autocomplete
                v-if="!hiddable || showSelect"
                :key="`resource-${changedResource}`"
                chips
                hide-details
                dense
                class="select"
                :class="{ error: item.type == FORMAT.RESOURCE && item.errors && item.errors.codes && item.errors.codes.includes('empty_resource_id') }"
                @change="sendValue()"
                v-model="changedResource"
                :items="filteredResources"
                :item-text="(item) => item.category + '->' + item.name"
                item-value="id"
                :no-data-text="$t('supervise.filter.no_results')"
            >
                <template slot="selection" slot-scope="data">
                    <v-chip small hide-details :input-value="data.selected" close @click:close="clearSelection()" class="chip" @click="previewLibrary()">
                        {{ data.item.name }}
                    </v-chip>
                </template>

                <template slot="item" slot-scope="{ item, title }">
                    <div id="resource-autocomplete-options">
                        <p class="category">{{ item.category }}</p>
                        <p class="name">{{ item.name }}</p>
                    </div>
                </template>
            </v-autocomplete>
        </div>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'

export default {
    components: {},
    props: {
        item: { type: Object },
        config: { type: [Object, Boolean], default: false },
        resource_id: { type: [String, Boolean], default: false },
        hiddable: { type: [Boolean], default: false }
    },
    data() {
        return {
            FORMAT: FORMAT,
            changedType: false,
            changedConfig: false,
            changedResource: false,
            changedResourceFolder: false,
            collapse: [],
            showSelect: false,
            selectedCategory: null
        }
    },
    computed: {
        resources() {
            var data = this.$store.getters['library/getResources']()
            var aResources = []

            if (data && Object.keys(data).length > 0) {
                // SORT CATEGORIES
                var categories = Object.values(data)
                categories.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))

                for (var category of categories) {
                    var resources = Object.values(category.resources)
                    resources.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))
                    for (var resource of resources) {
                        resource.category = category.name
                        if (resource.status == 1) {
                        }
                        aResources.push(resource)
                    }
                }
            }
            return aResources
        },
        folderResources() {
            // Crear un nuevo array con solo las categorías
            const categories = this.resources.map((item) => item.category)

            // Usar un Set para eliminar duplicaciones
            const uniqueCategoriesSet = new Set(categories)

            // Convertir el Set de nuevo a un array de objetos con la estructura deseada
            const uniqueCategories = Array.from(uniqueCategoriesSet).map((category) => ({ category }))
            uniqueCategories.unshift({ category: this.$t('template.general.types.resource.all_folders') })
            return uniqueCategories
        },
        filteredResources() {
            // Filtrar recursos según la categoría seleccionada
            if (this.selectedCategory && this.selectedCategory !== this.$t('template.general.types.resource.all_folders')) {
                return this.resources.filter((resource) => resource.category === this.selectedCategory)
            }
            return this.resources
        }
    },
    methods: {
        load() {
            this.$store.dispatch('library/loadResources', {})
            this.changedType = parseInt(this.item.type)
            this.changedConfig = this.config
            const category = this.resource_id ? this.resources.filter((resource) => resource.id === this.resource_id)[0].category : this.$t('template.general.types.resource.all_folders')
            this.changedResourceFolder = category
            this.changedResource = this.resource_id
            this.showSelect = this.changedResource !== false
        },

        collapseSection(section) {
            if (this.hiddable) {
                var aCollapse = this.collapse
                if (aCollapse.indexOf(section) > -1) {
                    aCollapse.splice(aCollapse.indexOf(section), 1)
                } else {
                    aCollapse.push(section)
                }
            }
        },

        switchResource() {
            var aCollapse = this.collapse

            if (this.showSelect) {
                this.showSelect = false
                if (this.changedResource) {
                    this.clearSelection()
                }
            } else {
                this.showSelect = true
                aCollapse.splice(aCollapse.indexOf('resource'), 1)
            }
        },

        sendValue() {
            this.$emit('setResource', { config: this.changedConfig, resource_id: this.changedResource })
        },

        clearSelection() {
            this.changedResource = false
            this.sendValue()
        },

        previewLibrary() {
            var resource = this.resources.find((obj) => {
                return obj.id == this.changedResource
            })
            window.open(resource.file, '_blank')
        },
        loadSelectResource() {
            this.selectedCategory = this.changedResourceFolder
        }
    },
    created() {
        this.load()
    },
    watch: {
        item: {
            handler: function (val, oldVal) {
                if (oldVal.id != val.id || oldVal.resource_id != val.resource_id) {
                    this.load()
                }
            },
            deep: true
        },
        selectedCategory: {
            handler(newValue, oldValue) {
                // Llamar a loadSelectResource cada vez que cambie selectedCategory
                this.loadSelectResource()
            },
            immediate: true // Para que se ejecute la primera vez al cargar el componente
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.config {
    .select {
        &.error {
            border-color: $color-error-500;
        }

        .chip {
            @include text-ellipsis();
            display: contents;
            height: auto;
        }
    }
}
</style>

<style lang="scss">
#resource-autocomplete-options {
    padding: 10px 0;
    .category,
    .name {
        font-family: $text;
        line-height: 12px;
    }
    .category {
        color: #888;
        @include font-size(xs);
    }

    .name {
        @include font-size(sm);
    }
}

.v-select {
    margin-bottom: 5px;
}
</style>
