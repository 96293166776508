<template>
    <!-- :class="{'active': Object.keys(template.schema).length == 0}" -->
    <div class="template-add-entity translate-hide" :class="{ 'first-add': !emptySections && isNoSection, 'add-parent-item': emptyNoSection || (!context.condition_id && ((!context.section_id && emptySections) || context.section_id) && !context.item_id), 'add-entity-item': context ? context.item_id : false, 'add-condition': !context.condition_id }">
        <div class="label">{{ $t('template.general.add_new') }}...</div>

        <!-- SPECIAL CASES -->
        <!-- SHOWS ONLY WHEN IS ONLY SECTION -1 AND NO OTHERS SECTIONS ACTIVE -->
        <template v-if="!emptySections && isNoSection">
            <div class="add" :class="{ visible: templateType() == 5 }" data-type="item" @click="addItem()">{{ $t('template.general.item') }}</div>
            <div v-if="templateType() != 5" class="add" data-type="section" @click="addSection()">{{ $t('template.general.section') }}</div>
        </template>

        <!-- GENERAL CASES -->
        <template v-else>
            <div v-if="emptyNoSection || (!context.condition_id && ((!context.section_id && emptySections) || context.section_id) && !context.item_id)" class="add" :class="{ visible: templateType() == 5 }" data-type="item" @click="addItem(emptyNoSection)">{{ $t('template.general.item') }}</div>

            <div v-if="!context.condition_id && !context.section_id && templateType() != 5" class="add" data-type="section" @click="addSection()">{{ $t('template.general.section') }}</div>

            <!-- CONDITIONS OPTIONS -->
            <div v-if="context.item_id && context.section_id && !hasNewCondition" class="add" data-type="condition" @click="addCondition()">{{ $t('template.general.add_condition') }}</div>

            <template v-if="context.condition_id">
                <div class="add" data-type="item" @click="addItem()">{{ $t('template.general.generate_subitem') }}</div>

                <div class="add" data-type="alert" @click="addAction('alert')">{{ $t('template.general.show_alert') }}</div>

                <div v-if="templateType() != 5 && templateType() != 4" class="add" data-type="alert" @click="addAction('issue')">
                    <template v-if="templateType() == 2">{{ $t('template.general.suggest_plan_action') }}</template>
                    <template v-else>{{ $t('template.general.suggest_issue') }}</template>
                </div>

                <div class="add" data-type="email" @click="addAction('email')">{{ $t('template.general.send_notification') }}</div>
            </template>

            <!-- NEW SECTION AND NEW ITEMS OPTIONS -->
        </template>
    </div>
</template>

<script>
export default {
    components: {},
    name: 'addEntity',
    props: {
        context: { type: Object, default: {} },
        emptyNoSection: { type: Boolean, default: false },
        emptySections: { type: Boolean, default: false },
        isNoSection: { type: Boolean, default: false },
        hasNewCondition: { type: Boolean, default: false }
    },
    data() {
        return {}
    },
    computed: {},
    methods: {
        addItem(emptyNoSection = false) {
            // if (!this.context.condition_id && emptyNoSection) {
            //     document.getElementsByClassName('main-content scrollbar')[0].scrollTop = 0
            // }
            this.$store.commit('template/addItem', { context: this.context })
            this.$emit('newItem')
        },

        addSection() {
            this.$store.commit('template/addSection', { context: this.context })
        },

        addAction(type) {
            this.$store.commit('template/addAction', { context: this.context, type: type })
        },

        addCondition() {
            console.warn('addCondition')
            this.$store.commit('template/addCondition', { context: this.context })
        },
        templateType() {
            if (this.$route.params.id) {
                var template = {
                    ...this.$store.getters['template/getTemplate'](this.$route.params.id)
                }
            } else {
                var template = { ...this.$store.getters['template/getTemplate'](-1) }
            }
            return template.type
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// TODO PASAR A GLOBAL
$transition-duration: 0.2s;
.transition-smooth {
    transition-duration: $transition-duration;
}

.transition-hidden,
.transition-show {
    @extend .transition-smooth;
}

.transition-hidden {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear $transition-duration, opacity $transition-duration;
}
.transition-show {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s linear 0ms, opacity $transition-duration;
}
// ^ TODO PASAR A GLOBAL

// TODO Faltan los estilos del hover de los botones xD

.template-add-entity {
    @extend .transition-smooth;
    @include border-radius(4px);
    border: 2px dashed $color-neutral-500;
    padding: 3px 12px;
    position: static;
    display: inline-block;
    margin: 0px 30px;
    width: calc(100% - 60px);

    .label {
        @extend .transition-smooth;
        @include font-size(sm);
        @include background($size: 9px, $image: img('add_inactive_n600.svg'), $position: left 0px center);
        display: inline-block;
        color: $color-neutral-600;
        font-family: $text-bold;
        padding-left: 12px;
    }

    .add {
        @extend .transition-hidden;
        @include font-size(sm);
        margin: 3px 0;
        margin-left: 30px;
        font-family: $text-bold;
        color: $color-black;
        cursor: pointer;
        display: inline-block;
        border-radius: 2px;
        background-color: #fff;
        padding: 6px 10px;

        &[data-type='condition'] {
            @include background($size: 15px, $image: img('condition_inactive_s50.svg'), $position: right 7px center);
            padding-right: 30px;
        }

        &[data-type='item'] {
            // @include background($size: 15px, $image: img('menu_checklist_eeeeee.svg'), $position: right 7px center);
            @include background($size: 15px, $image: img('format_check_inactive_s50.svg'), $position: right 7px center);
            padding-right: 30px;
        }

        &[data-type='alert'] {
            @include background($size: 15px, $image: img('alert_neutro_s30.svg'), $position: right 7px center);
            padding-right: 30px;
        }

        &[data-type='email'] {
            @include background($size: 15px, $image: img('format_mail_neutro_s30.svg'), $position: right 7px center);
            padding-right: 30px;
        }

        // &[data-type='section'] {
        // }

        @include bgHover($color: #fff, $percentage: 3.1%);
    }

    .visible{
        opacity: 1;
        visibility: visible;
    }

    &:hover,
    &:active,
    &.active {
        border-color: $color-neutral-300;

        .label {
            background-image: img('add_inactive_s50.svg');
            color: $color-neutral-600;
        }
        .add {
            @extend .transition-show;
        }
    }

    &.add-entity-item {
        border-color: $color-neutral-300;
        margin: 8px;
        width: calc(100% - 60px);
    }

    &.add-condition {
        width: calc(100% - 30px);
    }

    &.add-parent-item {
        width: calc(100% - 60px);
    }

    &.first-add {
        width: calc(100% - 30px);
    }
}

.template-add-entity:hover {
    border: 2px dashed $color-neutral-600;
}
</style>
