<template>
    <div v-if="template">
        <div class="config main inline" v-if="oTag">
            <div class="name main check">
                {{ $t('template.general.tags.title') }}
            </div>
            <div class="config-content">
                <template>
                    <div class="description" v-html="$t('template.general.tags.description')"></div>
                    <v-combobox
                        v-model="oTag.tags"
                        class="combobox"
                        :placeholder="$t('template.general.types.tags.placeholder')"
                        hide-selected
                        multiple
                        hide-details
                        small-chips
                        solo
                        flat
                        deletable-chips
                        attach
                        append-icon=""
                        disable-lookup
                        @change="oTag = oTag"
                        :class="{ error: template.errors && template.errors.codes && (template.errors.codes.includes('empty_to_create') || template.errors.codes.includes('error_to_create_custom_fields'))}"
                    >
                    </v-combobox>

                </template>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    components: {},
    props: {
        context: { type: Object, default: {} }
    },
    data() {
        return {
            defaultObject: {
                tags: [],
            },
            error: {
                margin: false
            },
        }
    },
    computed: {
        template: {
            get() {
                var template = { ...this.$store.getters['template/getTemplate'](this.context.template_id) }
                return template
            },
            set(value) {
                this.$store.commit('template/updateTemplate', { context: this.context, template: value })
            }
        },

        oTag: {
            get() {
                if (this.template && this.template.config) {
                    if (this.template.config.Tag) {
                        return this.template.config.Tag
                    }
                }
                return { ...this.defaultObject }
            },
            set(value) {
                var oTemaplate = Object.assign({}, this.template)
                oTemaplate.config.Tag = value
                this.$store.commit('template/updateTemplate', { context: this.context, template: oTemaplate })
            }
        },
        oReopenTag: {
            get() {
                if (this.template && this.template.config) {
                    if (this.template.config.reopenTag) {
                        return this.template.config.reopenTag
                    }
                }
                return { ...this.defaultObject }
            },
            set(value) {
                var oTemaplate = Object.assign({}, this.template)
                oTemaplate.config.reopenTag = value
                this.$store.commit('template/updateTemplate', { context: this.context, template: oTemaplate })
            }
        },
        oCloseTag: {
            get() {
                if (this.template && this.template.config) {
                    if (this.template.config.closeTag) {
                        return this.template.config.closeTag
                    }
                }
                return { ...this.defaultObject }
            },
            set(value) {
                var oTemaplate = Object.assign({}, this.template)
                oTemaplate.config.closeTag = value
                this.$store.commit('template/updateTemplate', { context: this.context, template: oTemaplate })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.config {
    .combobox {
        overflow: hidden;
        height: auto;
        margin-bottom: 10px;

        .v-input__slot {
            padding: 0 !important;
        }

        .v-select__selections {
            padding-top: 50px !important;

            .v-chip {
                padding: 0 0px 0 9px;
            }

            input {
                height: 30px;
                left: 0;
                right: 0;
            }

            .v-chip--removable {
                height: fit-content;
            }
        }

        input {
            position: absolute;
            top: 10px;
            width: 100%;
            padding-right: 30px;
            padding-left: 5px;
        }

        &.error {
            @include background($image: img('warning_error.svg'), $size: 15px, $position: right 12px center);
            padding-right: 30px;
            border-color: $color-error-500;

            @include placeholder {
                color: $color-error-500;
            }
        }
    }
}
</style>
