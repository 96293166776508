<template>
    <div class="config main inline translate-hide" :key="item.id" v-if="item">
        <div class="name main check">
            <div class="collapse" :class="{ collapsed: collapse.indexOf('optional') == -1 }"></div>
            {{ templateType == 1 ? $t('template.general.optional_label') : $t('template.general.option_label_mandatory') }}
            <input class="switch-input" :true-value="0" :false-value="1" v-bind:value="0" type="checkbox" v-model="item.mandatory" @click.stop="updateItem()" />
        </div>
        <div class="config-content" :class="{ collapsed: collapse.indexOf('optional') > -1 }">
            <div class="sub-description" v-html="noAplica"></div>
        </div>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'
import { FORMAT_CAT } from '@/constants'

export default {
    components: {},
    name: 'Optional',
    props: {
        context: { type: Object, default: {} },
        templateStatus: {},
        templateType: { type: Number, default: false }
    },
    data() {
        return {
            // VIEW VARIABLES OF CONTROL
            viewMoreTypes: false,
            collapse: [],

            // GENERAL VARIABLES
            FORMAT: FORMAT,
            FORMAT_CAT: FORMAT_CAT
        }
    },
    computed: {
        item() {
            return this.$store.getters['template/getItem'](this.context)
        },
        noAplica() {
            if (this.templateType === 1) {
                if (this.item.mandatory === 0) {
                    return this.$t('template.general.optional_description_optional_apply', { link: 'https://help.andyapp.io/portal/es/kb/articles/mantener-el-no-aplica-permanente'})
                } else {
                    return this.$t('template.general.optional_description_mandatory_apply', { link: 'https://help.andyapp.io/portal/es/kb/articles/mantener-el-no-aplica-permanente'})
                }
            } else {
                if (this.item.mandatory === 0) {
                    return this.$t('template.general.optional_description_optional')
                } else {
                    return this.$t('template.general.optional_description_mandatory')
                }
            }
        }
    },
    methods: {
        collapseSection(section) {
            var aCollapse = this.collapse
            if (aCollapse.indexOf(section) > -1) {
                aCollapse.splice(aCollapse.indexOf(section), 1)
            } else {
                aCollapse.push(section)
            }
        },
        updateItem() {
            var aCollapse = this.collapse
            aCollapse.splice(aCollapse.indexOf('optional'), 1)
            this.$store.commit('template/updateItem', { context: this.context, item: this.item })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
