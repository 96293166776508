<template>
    <div class="config translate-disable">
        <div class="name">{{ $t('template.general.types.title') }}</div>
        <div class="description" v-html="$t('template.general.types.photo.description')"></div>
        <div class="quantity">
            <div
                class="number"
                @click="
                    changedConfig.limit = 1
                    sendValue()
                "
                :class="{ selected: limit == 1 }"
            >
                1
            </div>
            <div
                class="number"
                @click="
                    changedConfig.limit = 2
                    sendValue()
                "
                :class="{ selected: limit == 2 }"
            >
                2
            </div>
            <div
                class="number"
                @click="
                    changedConfig.limit = 3
                    sendValue()
                "
                :class="{ selected: limit == 3 }"
            >
                3
            </div>
            <div
                class="number"
                @click="
                    changedConfig.limit = 4
                    sendValue()
                "
                :class="{ selected: limit == 4 }"
            >
                4
            </div>
            <div
                class="number"
                @click="
                    changedConfig.limit = 5
                    sendValue()
                "
                :class="{ selected: limit == 5 }"
            >
                5
            </div>
            <div class="checkbox">
                <div class="description" v-html="$t('template.general.types.photo.description_mobile')"></div>
                <div class="checkbox-container">
                    <v-checkbox v-model="camera" @change="sendValue('camera')" color="#4D206B" class="checkbutton" dense hide-details></v-checkbox>
                    <div class="checkbox-label" :class="{ selected: camera }">{{ $t('template.general.types.photo.camera') }}</div>
                    <v-checkbox v-model="gallery" @change="sendValue('gallery')" color="#4D206B" class="checkbutton" dense hide-details></v-checkbox>
                    <div class="checkbox-label" :class="{ selected: gallery }">{{ $t('template.general.types.photo.gallery') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'

export default {
    components: {},
    props: {
        item: { type: Object },
        config: { type: [Object, Boolean], default: { limit: 1 } }
    },
    data() {
        return {
            default: { limit: 1 },
            FORMAT: FORMAT,
            changedType: false,
            changedConfig: false,
            limit: 0,
            // FORM VARIABLES
            camera: false,
            gallery: false
        }
    },
    computed: {
        configRender() {
            return this.changedConfig.limit
        }
    },
    methods: {
        load() {
            this.changedType = parseInt(this.item.type)

            if (this.config && this.config.limit) {
                this.changedConfig = this.config
            } else {
                this.changedConfig = this.default
                this.sendValue()
            }

            if (this.config && this.config.capture) {
                if (this.config.capture == 'all') {
                    this.camera = true
                    this.gallery = true
                } else if (this.config.capture == 'camera') {
                    this.camera = true
                    this.gallery = false
                } else if (this.config.capture == 'gallery') {
                    this.gallery = true
                    this.camera = false
                }
            } else {
                this.camera = true
                this.gallery = true
            }

            this.limit = this.config && this.config.limit ? this.config.limit : 1
        },

        sendValue(type) {
            if (!this.camera && !this.gallery) {
                if (type == 'camera') {
                    this.gallery = true
                } else {
                    this.camera = true
                }
            }
            if (this.camera && this.gallery) {
                this.changedConfig.capture = 'all'
            } else if (this.camera) {
                this.changedConfig.capture = 'camera'
            } else if (this.gallery) {
                this.changedConfig.capture = 'gallery'
            }
            this.limit = this.changedConfig.limit
            this.$emit('sendItemType', { type: this.changedType, config: this.changedConfig })
        }
    },
    created() {
        this.load()
    },
    watch: {
        item: {
            handler: function (val, oldVal) {
                if (oldVal.id != val.id) {
                    this.load()
                }
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.quantity {
    width: 100%;

    .number {
        margin-top: 10px;
        background-color: $color-neutral-200;
        padding: 1px 9px;
        width: fit-content;
        cursor: pointer;
        display: inline-block;
        margin-right: 10px;
        border-radius: 3px;
        color: $color-black;
        font-family: $text;
        @include font-size(ml);

        &.selected {
            color: #fff;
            background-color: $color-primary-500;
        }
    }
}
.config {
    .name {
        &.photo {
            margin-top: 20px;
        }
    }
    .checkbox {
        margin-top: 20px;
        .description {
            margin-bottom: 0px;
        }
        .checkbox-container {
            @include display-flex();
            @include justify-content(flex-start);
            @include align-items();

            .checkbutton {
                display: inline-block;
                margin-top: 0;

                .v-input--selection-controls__input {
                    overflow: hidden !important;
                    border-radius: 100%;
                }
            }

            .checkbox-label {
                display: inline-block;
                @include font-size(sm);
                font-family: $text-medium;
                color: $color-black;
                width: calc(50% - 50px);
                margin-right: 10px;
                padding: 9px;

                &.selected {
                    background-color: $color-neutral-200;
                }
            }
        }
    }
}
</style>
