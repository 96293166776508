<template>
    <div class="config translate-disable">
        <div class="name">{{ $t('template.general.types.title') }}</div>
        <div class="description" v-html="$t('template.general.types.email.description')"></div>
        <v-combobox v-model="changedConfig.to" class="combobox" :placeholder="$t('template.general.types.email.placeholder')" hide-selected multiple hide-details small-chips solo flat deletable-chips attach append-icon="" disable-lookup @change="sendValue()" :class="{ error: item.errors && item.errors.codes && item.errors.codes.includes('empty_config_email') }"> </v-combobox>

        <div class="description" v-html="$t('template.general.subject')"></div>
        <input class="standard" v-model="changedConfig.subject" :class="{ error: item.errors && item.errors.codes && item.errors.codes.includes('empty_config_subject') }" />

        <div class="description" v-html="$t('template.general.message')"></div>
        <textarea class="standard long" v-model="changedConfig.message" :class="{ error: item.errors && item.errors.codes && item.errors.codes.includes('empty_config_message') }"></textarea>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'

export default {
    components: {},
    props: {
        item: { type: Object },
        config: { type: [Object, Boolean], default: { to: [], subject: '', message: '' } }
    },
    data() {
        return {
            FORMAT: FORMAT,
            changedType: false,
            changedConfig: false
        }
    },
    computed: {},
    methods: {
        load() {
            this.changedType = parseInt(this.item.type)
            this.changedConfig = this.config
        },

        sendValue() {
            // CLEAN ',' AND WHITESPACES
            var mails = [...this.changedConfig.to].join(' ')
            mails = mails.replace(/[,]|\s+/g, ',').split(',')
            mails = mails.filter(function (email) {
                return email.trim() != ''
            })
            this.changedConfig.to = mails

            this.$emit('sendItemType', { type: this.changedType, config: this.changedConfig })
        }
    },
    created() {
        this.load()
    },
    watch: {
        item: {
            handler: function (val, oldVal) {
                if (oldVal.id != val.id) {
                    this.load()
                }
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.config {
    .combobox {
        overflow: hidden;
        height: auto;
        margin-bottom: 10px;

        .v-input__slot {
            padding: 0;
        }

        .v-select__selections {
            padding-top: 50px !important;

            .v-chip {
                padding: 0 0px 0 9px;
            }

            input {
                height: 30px;
                left: 0;
                right: 0;
                padding: 5px;
            }

            .v-chip--removable {
                height: fit-content;
            }
        }

        input {
            // @include background($size: 12px, $image: img('add_neutro_s60.svg'), $position: right 10px center);
            position: absolute;
            top: 10px;
            width: 100%;
            padding-right: 30px;
            padding-left: 5px;
        }

        &.error {
            @include background($image: img('warning_error.svg'), $size: 15px, $position: right 12px center);
            padding-right: 30px;
            border-color: $color-error-500;

            @include placeholder {
                color: $color-error-500;
            }
        }
    }

    .standard {
        @include font-size('sm');
        // background-color: $color-neutral-50 !important;
        height: 30px;
        border-radius: 3px;
        padding: 5px;
        margin-bottom: 10px;
        border: 1px solid $color-neutral-300;

        &.long {
            line-height: 1.4;
            height: calc(22px * 4);
        }

        &.error {
            @include background($image: img('warning_error.svg'), $size: 15px, $position: right 12px center);
            padding-right: 30px;
            border-color: $color-error-500;

            @include placeholder {
                color: $color-error-500;
            }
        }
    }
}
</style>
