<template>
    <div class="config main inline translate-hide" :class="{ active: item.review }">
        <div class="name main check">
            <div class="collapse" :class="{ collapsed: collapse.indexOf('review') == -1 }"></div>
            {{ $t('template.general.revision_label') }}
            <input class="switch-input" type="checkbox" v-model="item.review" @click.stop @change="switchReview()" />
        </div>
        <div class="config-content" :class="{ collapsed: collapse.indexOf('review') > -1 }">
            <div class="sub-description" v-html="$t('template.general.revision_description')"></div>

            <div class="role" :key="item.id" v-if="item.review">
                <div class="sub-name" v-html="$t('template.general.revision_only_roles')"></div>
                <v-autocomplete hide-details class="select" v-model="aReviewRoles" :placeholder="$t('template.general.revision_all_roles')" :items="Object.values(roles)" item-text="name" item-value="id" multiple attach dense :no-data-text="$t('supervise.filter.no_results')" @change="sendValue">
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags v-if="aReviewRoles && aReviewRoles.length > 0" @change="sendValue" :tags="aReviewRoles" :referenceObject="roles" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>
        </div>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'
import { FORMAT_CAT } from '@/constants'

export default {
    components: {},
    name: 'Review',
    props: {
        context: { type: Object, default: {} },
        review_roles: { type: [Boolean, Array, String], default: false },
        templateStatus: {}
    },
    data() {
        return {
            aReviewRoles: false,

            // VIEW VARIABLES OF CONTROL
            viewMoreTypes: false,
            collapse: [],

            // GENERAL VARIABLES
            FORMAT: FORMAT,
            FORMAT_CAT: FORMAT_CAT
        }
    },
    computed: {
        item: {
            get() {
                return this.$store.getters['template/getItem'](this.context)
            },
            set(value) {
                return value
            }
        },

        roles() {
            return this.$store.getters['employee/getSameLowerLevelRoleUser']
        }
    },
    methods: {
        load() {
            this.aReviewRoles = false
            if (this.review_roles) {
                this.aReviewRoles = typeof this.review_roles == 'string' ? this.review_roles.split(',') : this.review_roles
            }
        },

        collapseSection(section) {
            var aCollapse = this.collapse
            if (aCollapse.indexOf(section) > -1) {
                aCollapse.splice(aCollapse.indexOf(section), 1)
            } else {
                aCollapse.push(section)
            }
        },

        switchReview() {
            var aCollapse = this.collapse
            aCollapse.splice(aCollapse.indexOf('review'), 1)
            this.item.review = +this.item.review
        },

        sendValue() {
            var sReviewRoles = [...this.aReviewRoles].join()
            this.$emit('sendItemReviewRoles', sReviewRoles)
        }
    },
    created() {
        this.load()
    },
    watch: {
        review_roles: 'load',
        aReviewRoles: 'sendValue'
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.role {
    padding: 0 10px;
}
</style>
